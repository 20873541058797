import Papa from "papaparse";
import { utils, writeFile } from "sheetjs-style";

export const exportCsvData = (data: any, fileName: string) => {
  const fileExtension = ".csv";
  const csvData = Papa.unparse(data);
  const type = "text/csv;charset=utf-8;";
  const blob = new Blob([csvData], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName + fileExtension;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const exportExcelData = (excelData: any, fileName: string) => {
  /* generate worksheet from state */
  const fileExtension = ".xlsx";
  const ws = utils.json_to_sheet(excelData);
  /* create workbook and append worksheet */
  //utils.sheet_add_aoa(ws, [["Data 1", { f: "SUM(C2:C5)" }]], { origin: 1 });
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  /* export to XLSX */
  writeFile(wb, fileName + fileExtension);
};

export const exportReport = (excelData: Array<object>, fileName: string, t: (id: string) => void) => {
  const fileExtension = ".xlsx";
  const ws = utils.json_to_sheet(excelData);
  const endRow = utils.decode_range(ws["!ref"] as string);
  utils.sheet_add_aoa(ws, [[`${t("reports.totalHours")}`, { f: `SUM(D2:D${endRow.e.r + 1})` }]], {
    origin: endRow.e.r + 2
  });
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  writeFile(wb, fileName + fileExtension);
};
