import { forwardRef } from "react";

import { Password, PasswordProps } from "primereact/password";

import Label from "components/Label";

import { FieldError } from "types/FieldError";

import "./InputPassword.css";

interface InputPasswordProps extends PasswordProps {
  error?: FieldError;
  label: string;
  type?: string;
}

const header = <div className="font-bold mb-3">Pick a password</div>;
const footer = (
  <>
    <p className="mt-2">Suggestions</p>
    <ul className="pl-2 ml-2 mt-0 line-height-3">
      <li>At least one lowercase</li>
      <li>At least one uppercase</li>
      <li>At least one numeric</li>
      <li>Minimum 8 characters</li>
    </ul>
  </>
);

const InputPassword = forwardRef<Password, InputPasswordProps>((props, ref) => (
  <div className="password relative flex flex-column text-sm mt-4">
    <Label error={!!props.error}>{props.label}</Label>
    <Password ref={ref} aria-describedby={props.name} {...props} header={header} footer={footer} toggleMask />
    {props?.error && (
      <small className="absolute p-error" id={props.name}>
        {props?.error?.message}
      </small>
    )}
  </div>
));

InputPassword.displayName = "InputPassword";

export default InputPassword;
