import * as yum from "yup";

import { requiredValidator, requiredValidatorSelect } from "utils/validators";

export const schema = yum.object().shape({
  Name: requiredValidator,
  Description: requiredValidator,
  Web: requiredValidator,
  IdType: requiredValidatorSelect,
  StartDate: requiredValidator,
  EndDate: requiredValidator
});
