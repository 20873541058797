import { combineReducers } from "@reduxjs/toolkit";

import {
  CREATE_PROJECT,
  CREATE_PROJECT_TASK,
  DELETE_PROJECT_TASK,
  GET_MY_PROJECTS,
  GET_MY_TASKS,
  GET_MY_TASK_INFO,
  GET_PROJECTS,
  GET_PROJECT_CREATORS,
  GET_PROJECT_INFO,
  GET_PROJECT_POSITIONS,
  GET_PROJECT_TASKS,
  GET_PROJECT_TASK_INFO,
  UPDATE_PROJECT,
  UPDATE_PROJECT_TASK
} from "state/constants/projectsConstants";
import reducer from "state/utils/reducer";

export default combineReducers({
  getProjects: reducer(`projects/${GET_PROJECTS}`),
  updateProject: reducer(`projects/${UPDATE_PROJECT}`),
  createProject: reducer(`projects/${CREATE_PROJECT}`),
  getProjectInfo: reducer(`projects/${GET_PROJECT_INFO}`),
  getProjectTasks: reducer(`tasks/${GET_PROJECT_TASKS}`),
  updateProjectTask: reducer(`tasks/${UPDATE_PROJECT_TASK}`),
  createProjectTask: reducer(`tasks/${CREATE_PROJECT_TASK}`),
  deleteProjectTask: reducer(`tasks/${DELETE_PROJECT_TASK}`),
  getProjectTaskInfo: reducer(`tasks/${GET_PROJECT_TASK_INFO}`),
  getProjectPositions: reducer(`assignees/${GET_PROJECT_POSITIONS}`),
  getProjectCreators: reducer(`assignees/${GET_PROJECT_CREATORS}`),
  getMyTasks: reducer(`mytasks/${GET_MY_TASKS}`),
  getMyTaskInfo: reducer(`mytasks/${GET_MY_TASK_INFO}`),
  getMyProjects: reducer(`myprojects/${GET_MY_PROJECTS}`)
});
