import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { startDateOfCurrentMonth, startDateOfNextMonth, startDateOfPreviousMonth } from "utils/formatDate";

import { getMyTasks, getMyTasksInit } from "state/actions/projectsActions";
import {
  selectCreateProjectTaskFail,
  selectCreateProjectTaskSuccess,
  selectDeleteProjectTaskFail,
  selectDeleteProjectTaskSuccess,
  selectUpdateProjectTaskFail,
  selectUpdateProjectTaskSuccess
} from "state/selectors/projectsSelectors";

import Button from "components/Button";
import SectionTitle from "components/SectionTitle";
import { TabPanel, TabView } from "components/Tabs";

import ImportTasksModal from "../ImportTasks/components/ImportTasksModal/ImportTasksModal";
import RangeModal from "../RangeModal";
import MyTaskModal from "./components/MyTaskModal";
import MyTasksTable from "./components/MyTasksTable";

export default function MyTasks() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const [activeIndex, setActiveIndex] = useState(0);

  const updateTaskSuccess = useAppSelector(selectUpdateProjectTaskSuccess) as boolean;
  const createTaskSuccess = useAppSelector(selectCreateProjectTaskSuccess) as boolean;
  const deleteTaskSuccess = useAppSelector(selectDeleteProjectTaskSuccess) as boolean;
  const createTaskFail = useAppSelector(selectCreateProjectTaskFail) as boolean;
  const updateTaskFail = useAppSelector(selectUpdateProjectTaskFail) as boolean;
  const deleteTaskFail = useAppSelector(selectDeleteProjectTaskFail) as boolean;

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showImportModal, setShowImportModal] = useState<boolean>(false);

  const getData = ({
    startdate = startDateOfCurrentMonth(),
    enddate = startDateOfNextMonth()
  }: {
    startdate?: string;
    enddate?: string;
  }) => {
    const queryParams: Record<string, number | string> = {
      startdate,
      enddate,
      page: 1,
      perPage: 5
    };
    dispatch(getMyTasks({ params: queryParams }));
  };

  const updateRange = (s: string, e: string): void => {
    /* setShowModal(false);
    // ovdje ide logika za getanje
    getData(s, e); */
  };

  useEffect(() => {
    if (createTaskSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("task.created"),
        life: 3000
      });
    }
    if (updateTaskSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("task.updated"),
        life: 3000
      });
    } else if (deleteTaskSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("task.deleted"),
        life: 3000
      });
    }
    getData({});
  }, [updateTaskSuccess, createTaskSuccess, deleteTaskSuccess]);

  useEffect(() => {
    if (createTaskFail || updateTaskFail || deleteTaskFail) {
      toast?.current?.show({
        sticky: true,
        severity: "error",
        summary: t("common.error"),
        detail: t("errorBoundary.title")
      });
    }
  }, [createTaskFail, updateTaskFail, deleteTaskFail]);

  useEffect(() => {
    switch (activeIndex) {
      case 0:
        getData({ startdate: startDateOfCurrentMonth(), enddate: startDateOfNextMonth() });
        break;
      case 1:
        getData({ startdate: startDateOfPreviousMonth(), enddate: startDateOfCurrentMonth() });
        break;
      case 2:
        dispatch(getMyTasksInit());
    }
  }, [activeIndex]);

  return (
    <section className="container mx-auto">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full gap-0">
          {t("mytasks.myTasks")}
          <div className="flex">
            <Button
              icon="pi pi-upload"
              tooltip={t("tasks.importTasks")}
              text
              className="text-900"
              type="button"
              tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
              onClick={() => setShowImportModal(true)}
            ><span className="text-900 p-button-label hidden md:flex ml-2"> {t("tasks.importTasks")}</span></Button>
            <Button
              icon="pi pi-plus"
              text
              className="text-900"
              tooltip={t("task.addNewTask")}
              type="button"
              tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
              onClick={() => setShowEditModal(true)}
            ><span className="text-900 p-button-label hidden md:flex ml-2"> {t("task.addNewTask")}</span></Button>
          </div>
        </div>
      </SectionTitle>
      <div className="card">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} panelContainerClassName="px-0">
          <TabPanel header={t("common.thisMonth")}>
            <MyTasksTable />
          </TabPanel>
          <TabPanel header={t("common.previousMonth")}>
            <MyTasksTable />
          </TabPanel>
          {/* <TabPanel header={t("common.range")}>
            <Button label={t("common.addRange")} onClick={() => setShowModal(true)} />
            <MyTasksTable />
          </TabPanel> */}
        </TabView>
      </div>
      <MyTaskModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
        task={null}
      />
      <RangeModal
        show={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
        updateRange={updateRange}
      />
      <ImportTasksModal
        show={showImportModal}
        closeModal={() => {
          setShowImportModal(false);
        }}
        task={null}
      />
    </section>
  );
}
