import { useEffect, useState } from "react";

import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { formatDate } from "utils/formatDate";

import { getAllContractsClient } from "state/actions/jobsActions";
import { selectGetAllContractsClientRequest, selectGetAllContractsClientSuccess } from "state/selectors/jobsSelectors";

import DataField from "components/DataField";
import Divider from "components/Divider";
import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

export default function Contracts() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [first, setFirst] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const getAllContractsClientSuccess = useAppSelector(selectGetAllContractsClientSuccess) as any;
  const getAllContractsClientRequest = useAppSelector(selectGetAllContractsClientRequest) as boolean;

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };
    dispatch(getAllContractsClient({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setPage(event.page + 1);
    getData({ page: event.page + 1 });
  };

  useEffect(() => {
    getData({});
    setFirst(0);
  }, []);

  const contracts = getAllContractsClientSuccess?.contracts;

  if (getAllContractsClientRequest) {
    return (
      <div className="h-full">
        <Loading />
      </div>
    );
  }

  return (
    <section className="container mx-auto px-2 md:px-0">
      <SectionTitle>{t("menu.contracts")}</SectionTitle>
      <div className="card">
        {contracts?.map((contract: any, index: number) => (
          <div key={index} className="relative">
            {index > 0 && <Divider className="mb-4 mt-4" />}
            <div className="w-full">
              <div className="flex gap-4 mb-6">
                <div className="pt-4 w-6 flex flex-column gap-4">
                  <DataField label={t("job.title")} value={contract.Title} />
                  <DataField label={t("contractor")} value={contract.CreatorFullName} />
                  <DataField textField label={t("common.description")} value={contract.Description} />
                </div>
                <div className="pt-4 w-6 flex flex-column gap-4">
                  <div className="flex w-full mb-4 md:mb-0">
                    <DataField
                      className="break-text text-sm md:text-base"
                      label={t("contract.reporterEmails")}
                      value={contract.ReporterEmails}
                    />
                  </div>
                  <div className="flex w-full gap-3 md:gap-0 flex-column md:flex-row">
                    <DataField
                      label={t("assignee.hourlyRate")}
                      value={contract.HourlyRate + " " + contract.CurrencyShortName}
                    />
                    <DataField
                      status={contract.Status === 1 ? "active" : "inactive"}
                      label={t("common.status")}
                      value={contract.Status === 1 ? t("common.active") : t("common.notActive")}
                    />
                  </div>
                  <div className="flex w-full gap-3 md:gap-0">
                    <DataField
                      className="text-sm md:text-base"
                      label={t("common.startDate")}
                      value={formatDate(contract.StartDate)}
                    />
                    <DataField
                      className="text-sm md:text-base"
                      label={t("common.endDate")}
                      value={formatDate(contract.EndDate)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {!contracts && (
          <div className="w-full">
            <div className="relative flex align-items-center justify-between">
              <span className="text-purple">{t("contract.noContracts")}</span>
            </div>
          </div>
        )}
        {getAllContractsClientSuccess?.total > 5 && (
          <Paginator
            first={first}
            rows={5}
            totalRecords={getAllContractsClientSuccess.total}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </section>
  );
}
