import { useEffect, useState } from "react";

import { PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { getMyReports } from "state/actions/dashboardActions";
import {
  selectCreateReportDataFail,
  selectCreateReportDataSuccess,
  selectGetMyReportsDataSuccess,
  selectGetMyReportsRequest
} from "state/selectors/dashboardSelectors";

import SectionTitle from "components/SectionTitle";

import { IMyReports } from "types/report";

import ReportsList from "./components/ReportsList";

export default function Reports() {
  const { t } = useTranslation();
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const [first, setFirst] = useState<number>(0);

  const createReportSuccess = useAppSelector(selectCreateReportDataSuccess);
  const createReportFail = useAppSelector(selectCreateReportDataFail);

  const getMyReportsSuccess = useAppSelector(selectGetMyReportsDataSuccess) as IMyReports;
  const getMyReportsRequest = useAppSelector(selectGetMyReportsRequest) as boolean;

  useEffect(() => {
    if (createReportSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("myreports.created"),
        life: 3000
      });
      getData({});
    }
  }, [createReportSuccess]);

  useEffect(() => {
    if (createReportFail) {
      toast?.current?.show({
        severity: "error",
        summary: t("common.error"),
        detail: t("myreports.failedToCreateReport"),
        sticky: true
      });
    }
  }, [createReportFail]);

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };

    dispatch(getMyReports({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    getData({ page: page });
  };

  useEffect(() => {
    getData({});
  }, []);

  return (
    <div className="container mx-auto mt-6 px-2 md:px-0">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("myreports.myReports")}
        </div>
      </SectionTitle>
      <ReportsList
        reports={getMyReportsSuccess}
        reportsRequest={getMyReportsRequest}
        handlePageChange={handlePageChange}
        first={first}
      />
    </div>
  );
}
