import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

interface IFrom {
  from: Record<string, string>;
}

export default function RedirectToLocation() {
  const location = useLocation();
  const navigate = useNavigate();

  const paths = location?.state as IFrom;

  const loginPath = paths?.from?.pathname.toString();

  let redirectToLocation = (paths?.from?.state as unknown as IFrom)?.from?.state as unknown as IFrom;

  if (!redirectToLocation) {
    redirectToLocation = paths?.from?.state as unknown as IFrom;
  }

  useEffect(() => {
    if (loginPath && loginPath === "/login") {
      if (redirectToLocation) {
        const key = redirectToLocation.from?.key;
        const pathToRedirect = redirectToLocation.from?.pathname;
        if (key && key.toString() === "default" && pathToRedirect && pathToRedirect.toString() !== "/") {
          navigate(pathToRedirect.toString());
        }
      }
    }
  }, []);

  return <></>;
}
