import { useEffect, useState } from "react";

import { confirmDialog } from "primereact/confirmdialog";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { exportCsvData, exportExcelData } from "utils/exportFile";
import { formatDate2, getDayMonthYear } from "utils/formatDate";

import { deleteProjectTask, deleteProjectTaskInit, getProjectTasks } from "state/actions/projectsActions";
import {
  selectCreateProjectTaskFail,
  selectCreateProjectTaskSuccess,
  selectDeleteProjectTaskFail,
  selectDeleteProjectTaskSuccess,
  selectGetProjectTasksDataSuccess,
  selectUpdateProjectTaskFail,
  selectUpdateProjectTaskSuccess
} from "state/selectors/projectsSelectors";

import Button from "components/Button";
import { Column, DataTable } from "components/DataTable";
import { dateBodyTemplate, taskActionsBodyTemplate } from "components/DataTable/DataTableBodyTemplates";
import SectionTitle from "components/SectionTitle";

import { IColumn } from "types/column";
import { IProject } from "types/project";
import { ITask, ITasks } from "types/task";

import HoursModal from "../HoursModal";
import TaskModal from "../TaskModal";

export default function Tasks(project: IProject) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showHoursModal, setShowHoursModal] = useState<boolean>(false);
  const [taskToEdit, setTaskToEdit] = useState<null | ITask>(null);
  const [first, setFirst] = useState<number>(0);
  const params = useParams();
  const projectId = params.id as string;

  const tasks = useAppSelector(selectGetProjectTasksDataSuccess) as ITasks;

  const updateTaskSuccess = useAppSelector(selectUpdateProjectTaskSuccess) as boolean;
  const createTaskSuccess = useAppSelector(selectCreateProjectTaskSuccess) as boolean;
  const deleteTaskSuccess = useAppSelector(selectDeleteProjectTaskSuccess) as boolean;
  const createTaskFail = useAppSelector(selectCreateProjectTaskFail) as boolean;
  const updateTaskFail = useAppSelector(selectUpdateProjectTaskFail) as boolean;
  const deleteTaskFail = useAppSelector(selectDeleteProjectTaskFail) as boolean;

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 10
    };
    dispatch(getProjectTasks({ projectId, params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    getData({ page: page });
  };

  const deleteTask = (taskId: number) => {
    confirmDialog({
      acceptClassName: "p-button-danger",
      header: t("confirmation.areYouSure"),
      message: `${t("confirmation.doYouReallyWantToDeleteTheseRecords")} ${t(
        "confirmation.thisProcessCannotBeUndone"
      )}`,
      draggable: false,
      accept: () => {
        dispatch(deleteProjectTask(taskId)).then(() => dispatch(deleteProjectTaskInit()));
      }
    });
  };

  useEffect(() => {
    getData({});
    setFirst(0);
  }, []);

  useEffect(() => {
    if (updateTaskSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("task.updated"),
        life: 3000
      });
    } else if (createTaskSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("task.created"),
        life: 3000
      });
    } else if (deleteTaskSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("task.deleted"),
        life: 3000
      });
    }
    getData({});
  }, [updateTaskSuccess, createTaskSuccess, deleteTaskSuccess]);

  useEffect(() => {
    if (createTaskFail || updateTaskFail || deleteTaskFail) {
      toast?.current?.show({
        sticky: true,
        severity: "error",
        detail: t("errorBoundary.title")
      });
    }
  }, [createTaskFail, updateTaskFail, deleteTaskFail]);

  const ITasksColumns: IColumn[] = [
    //{ field: "Id", header: "Id" },
    { field: "Name", header: t("mytasks.taskName") },
    { field: "Description", header: t("common.description") },
    {
      field: "StartDate",
      header: t("common.startDate"),
      body: (item: ITask) => dateBodyTemplate(item?.StartDate)
    },
    {
      field: "EndDate",
      header: t("common.endDate"),
      body: (item: ITask) => dateBodyTemplate(item?.EndDate)
    },
    { field: "Duration", header: t("task.duration") },
    {
      field: "Actions",
      body: (item: ITask) =>
        taskActionsBodyTemplate({ item, setShowEditModal, setTaskToEdit, setShowHoursModal, deleteTask }),
      bodyStyle: { textAlign: "center" },
      style: { width: "10rem" }
    }
  ];

  const exportData = tasks?.tasks?.map((item: any) => ({
    ProjectName: project.Name,
    Name: item.Name,
    StartDate: getDayMonthYear(item.StartDate),
    Duration: item.Duration
  }));

  return (
    <section className="container mx-auto">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full pl-3 md:pl-0">
          {t("tasks.tasks")}
          {project.Status === 1 && (
            <Button
              icon="pi pi-plus"
              text
              className="text-900"
              tooltip={t("task.addNewTask")}
              type="button"
              tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
              onClick={() => setShowEditModal(true)}
            >
              <span className="text-900 p-button-label hidden md:flex ml-2">{t("task.addNewTask")}</span>
            </Button>
          )}
        </div>
      </SectionTitle>

      <div className="card">
        <div className="flex w-full justify-content-end -mt-3 mb-2 gap-1">
          <Button
            icon="pi pi-file-export"
            size="small"
            label={t("mytasks.exportCsv")}
            tooltip={t("mytasks.csvFile")}
            className="text-900"
            text
            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
            type="button"
            onClick={() => exportCsvData(exportData, "MyTasks_" + formatDate2(new Date().toString()))}
            disabled={!tasks}
          />
          <Button
            label={t("mytasks.exportExcel")}
            icon="pi pi-file-excel"
            text
            size="small"
            className="text-900"
            tooltip={t("mytasks.excelFile")}
            type="button"
            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => exportExcelData(exportData, "MyTasks_" + formatDate2(new Date().toString()))}
            disabled={!tasks}
          />
        </div>
        <DataTable value={tasks?.tasks}>
          {ITasksColumns.map((col) => {
            if (col.field === "Actions") {
              if (project.Status === 1) {
                return <Column key={col.field} {...col} />;
              }
            } else {
              return <Column key={col.field} {...col} />;
            }
          })}
        </DataTable>

        {tasks?.total > 10 && (
          <Paginator first={first} rows={10} totalRecords={tasks.total} onPageChange={handlePageChange} />
        )}
      </div>

      <TaskModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
          if (taskToEdit) {
            setTaskToEdit(null);
          }
        }}
        task={taskToEdit}
      />
      <HoursModal
        show={showHoursModal}
        closeModal={() => {
          setShowHoursModal(false);
          if (taskToEdit) {
            setTaskToEdit(null);
          }
        }}
        task={taskToEdit}
      />
    </section>
  );
}
