import { useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";

import { deactivateUser } from "state/actions/authActions";
import { selectDeactivationUserRequest, selectDeactivationUserSuccess } from "state/selectors/authSelectors";

import Loading from "components/Loading";

export default function Deactivate() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const deactivationRequest = useAppSelector(selectDeactivationUserRequest) as boolean;
  const deactivationSuccess = useAppSelector(selectDeactivationUserSuccess) as boolean;

  useEffect(() => {
    const action = searchParams.get("action");
    if (action) {
      dispatch(deactivateUser({ ActionToken: action }));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (deactivationSuccess) {
      navigate("/login");
    }
  }, [deactivationSuccess]);

  if (deactivationRequest) {
    return <Loading />;
  }

  return null;
}
