import { useEffect, useState } from "react";

import { PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { getOrganizationReports } from "state/actions/dashboardActions";
import {
  selectGetOrganizationReportsRequest,
  selectGetOrganizationReportsSucces
} from "state/selectors/dashboardSelectors";

import SectionTitle from "components/SectionTitle";

import ReportsList from "../../../Reports/components/ReportsList";

export default function Reports() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [first, setFirst] = useState<number>(0);

  const organizationReports = useAppSelector(selectGetOrganizationReportsSucces);
  const organizationReportsRequest = useAppSelector(selectGetOrganizationReportsRequest);

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    getData({ page: page });
  };

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 10
    };

    dispatch(getOrganizationReports({ params: queryParams }));
  };

  useEffect(() => {
    getData({});
  }, []);

  return (
    <div className="container mx-auto mt-6 px-2 md:px-0">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">{t("menu.reports")}</div>
      </SectionTitle>
      <ReportsList
        reports={organizationReports}
        reportsRequest={organizationReportsRequest}
        handlePageChange={handlePageChange}
        first={first}
        isOrganization={true}
      />
    </div>
  );
}
