import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { inviteMember, inviteMemberInit } from "state/actions/authActions";
import { selectInviteMemberRequest } from "state/selectors/authSelectors";
import { selectIndustryTypesSuccess } from "state/selectors/commonSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { InputText, InputTextarea, Select } from "components/FormControls";

import { IInviteMember } from "types/invite";

import { schema } from "./schema";

interface IInviteMemberModalProps {
  show: boolean;
  closeModal: () => void;
}

export default function InviteMemberModal({ show, closeModal }: IInviteMemberModalProps) {
  const dispatch = useAppDispatch();
  const inviteMemberRequest = useAppSelector(selectInviteMemberRequest);
  const inviteMemberSuccess = useAppSelector(selectIndustryTypesSuccess);

  const { inviteSeniority } = useCommon();

  const { t } = useTranslation();

  const { reset, handleSubmit, control, watch, clearErrors } = useForm<IInviteMember>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  useEffect(() => {
    if (inviteMemberSuccess) {
      resetForm();
    }
  }, [inviteMemberSuccess]);

  const resetForm = () => {
    reset({
      FirstName: "",
      LastName: "",
      Email: "",
      Skill: "",
      Seniority: "",
      Message: ""
    });
  };

  const handleClose = () => {
    closeModal();
    resetForm();
    clearErrors();
  };

  const onSubmit: SubmitHandler<IInviteMember> = (data) => {
    const inviteMemberData = {
      ...data,
      FirstName: data.FirstName as string,
      LastName: data.LastName as string,
      Email: data.Email as string,
      Skill: data.Skill as string,
      Seniority: data.Seniority as string,
      Message: data.Message as string
    };

    dispatch(inviteMember(inviteMemberData)).then(() => dispatch(inviteMemberInit()));

    handleClose();
  };

  const footerContent = (
    <div>
      <Button label={t("confirmation.cancel")} onClick={closeModal} outlined disabled={inviteMemberRequest} />
      <Button label={t("menu.invite")} type="submit" form="invite-modal-form" disabled={inviteMemberRequest} />
    </div>
  );

  return (
    <Dialog show={show} closeModal={closeModal} title={t("invite.inviteNewMember")} footerContent={footerContent}>
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="invite-modal-form">
        <>
          <Controller
            name="FirstName"
            control={control}
            render={({ field, fieldState }) => (
              <InputText label={t("form.firstName")} {...field} error={fieldState.error} />
            )}
            defaultValue=""
          />

          <Controller
            name="LastName"
            control={control}
            render={({ field, fieldState }) => (
              <InputText label={t("form.lastName")} {...field} error={fieldState.error} />
            )}
            defaultValue=""
          />

          <div className="flex justify-between">
            <div className="w-full">
              <Controller
                name="Email"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText label={t("form.email")} {...field} error={fieldState.error} />
                )}
                defaultValue=""
              />
            </div>
          </div>

          <Controller
            name="Seniority"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                label={t("common.seniority")}
                placeholder={t("common.seniority")}
                {...field}
                options={inviteSeniority}
                error={fieldState.error}
                defaultValue=""
              />
            )}
          />

          <Controller
            name="Skill"
            control={control}
            render={({ field, fieldState }) => (
              <InputText label={t("common.skill")} {...field} error={fieldState.error} />
            )}
            defaultValue=""
          />

          <Controller
            name="Message"
            control={control}
            render={({ field, fieldState }) => (
              <InputTextarea {...field} label={t("common.message")} error={fieldState.error} />
            )}
          />
        </>
      </form>
    </Dialog>
  );
}
