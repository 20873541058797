import useCommon from "hooks/useCommon";
import useTranslation from "hooks/useTranslation";

import { getDayMonthYear } from "utils/formatDate";

import DataField from "components/DataField";
import { Column, DataTable } from "components/DataTable";

import { IColumn } from "types/column";
import { IActivityReport, IActivityReports, IMReports } from "types/report";

interface IPrintReportProps {
  report: IMReports;
  reportActivities: IActivityReports;
}

export default function PrintReport({ report, reportActivities }: IPrintReportProps) {
  const { t } = useTranslation();
  const { reportStatus } = useCommon();

  const myReportsActivities = reportActivities?.report_activities?.map((reportItem: IActivityReport) => ({
    ProjectName: reportItem.ProjectName,
    Description: reportItem.TaskDescription,
    TaskName: reportItem.TaskName,
    Hours: reportItem.Hours,
    ActivityDate: getDayMonthYear(reportItem.ActivityDate),
    IdReport: reportItem.IdReport,
    Id: reportItem.Id
  }));

  const reportData = report?.myreport[0];

  const headers: IColumn[] = [
    { field: "ProjectName", header: t("mytasks.projectName") },
    { field: "Description", header: t("common.description") },
    { field: "TaskName", header: t("mytasks.taskName") },
    { field: "Hours", header: t("mytasks.hours") },
    { field: "ActivityDate", header: t("common.startDate") }
  ];

  if (!report || !reportActivities) {
    return null;
  }

  return (
    <div>
      <div className="relative mb-8">
        <div className="flex gap-4 pt-6">
          <div className="w-6 flex flex-column gap-4">
            <DataField label={t("myreports.reportSummary")} value={reportData.ReportName} />
            <DataField textField label={t("mytasks.partnerName")} value={reportData.PartnerName} />
            <DataField
              textField
              label={t("myreports.reportForMonthYear")}
              value={`${reportData.Month.toString().length === 1 ? "0" + reportData.Month : reportData.Month}/${
                reportData.Year
              }`}
            />
            <DataField label={t("myreports.contract")} value={reportData.ContractTitle} />
          </div>
          <div className="w-6 flex flex-column gap-4">
            <div className="flex w-full">
              <DataField
                label={t("assignee.hourlyRate")}
                value={reportData.HourlyRate + " " + reportData.CurrencyShortName}
              />
              <DataField
                status={reportData.Status === 1 ? "active" : "inactive"}
                label={t("common.status")}
                value={reportStatus.find((s) => s.value.toString() === reportData.Status.toString())?.label || ""}
              />
            </div>

            <div className="flex w-full">
              <DataField textField label={t("mytasks.totalHoursReported")} value={reportData.Duration} />
              <DataField label={t("myreports.reportedTo")} value={reportData.ReporterEmails} />
            </div>
            <div className="flex w-full">
              <DataField
                label={t("mytasks.totalAmountToCharge")}
                value={reportData.Earnings + " " + reportData.CurrencyShortName}
              />
              {reportData.Comment && <DataField label={t("common.comment")} value={`${reportData.Comment}`} />}
            </div>

            <div className="flex w-full">
              {(reportData?.Status === 0 || reportData?.Status === 1) && <div className="flex w-full"></div>}
            </div>
          </div>
        </div>
      </div>
      <DataTable value={myReportsActivities}>
        {headers.map((col) => (
          <Column key={col.field} {...col} />
        ))}
      </DataTable>
    </div>
  );
}
