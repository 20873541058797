import { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { getVdm } from "state/actions/dashboardActions";
import { selectGetVdmSuccess } from "state/selectors/dashboardSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { InputText } from "components/FormControls";

import { IMyVdm, IVdm } from "types/report";

interface IProjectTaskModalProps {
  show: boolean;
  closeModal: () => void;
  hourlyRate: string;
  currencyShortname: string;
}

export default function VdmModal({ show, closeModal, hourlyRate, currencyShortname }: IProjectTaskModalProps) {
  const dispatch = useAppDispatch();

  const vdm = useAppSelector(selectGetVdmSuccess) as IVdm;

  useEffect(() => {
    if (hourlyRate) {
      dispatch(getVdm(hourlyRate));
    }
  }, [currencyShortname]);

  useEffect(() => {
    if (vdm) {
      reset({
        HourlyRate: `${hourlyRate} / hr`,
        CommunityShares: `${vdm.cs_value} / hr`,
        CommunityIF: `${vdm.if_value} / hr`,
        OperationalAF: `${vdm.of_value} / hr`,
        YouReceive: `${vdm.m_value} / hr`
      });
    }
  }, [vdm]);

  const { t } = useTranslation();

  const { reset, control } = useForm<IMyVdm>({
    reValidateMode: "onChange"
  });

  const footerContent = (
    <div>
      <Button label={t("confirmation.cancel")} onClick={closeModal} outlined />
    </div>
  );

  return (
    <Dialog
      show={show}
      closeModal={closeModal}
      title={`${t("myreports.vdm")} in ${currencyShortname}`}
      footerContent={footerContent}
    >
      <form className="flex flex-column gap-4" id="task-modal-form">
        <Controller
          name="HourlyRate"
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("assignee.hourlyRate")} {...field} error={fieldState.error} readOnly />
          )}
          defaultValue=""
        />
        <Controller
          name="CommunityShares"
          control={control}
          render={({ field, fieldState }) => (
            <InputText
              label={`${t("myreports.comunityShares")} (${vdm?.cs_percent})`}
              {...field}
              error={fieldState.error}
              readOnly
            />
          )}
          defaultValue=""
        />
        <Controller
          name="CommunityIF"
          control={control}
          render={({ field, fieldState }) => (
            <InputText
              label={`${t("myreports.comunityInvestmentFund")} (${vdm?.if_percent})`}
              {...field}
              error={fieldState.error}
              readOnly
            />
          )}
          defaultValue=""
        />
        <Controller
          name="OperationalAF"
          control={control}
          render={({ field, fieldState }) => (
            <InputText
              label={`${t("myreports.operationalActivitiesFund")} (${vdm?.of_percent})`}
              {...field}
              error={fieldState.error}
              readOnly
            />
          )}
          defaultValue=""
        />
        <Controller
          name="YouReceive"
          control={control}
          render={({ field, fieldState }) => (
            <InputText
              label={`${t("myreports.youWillReceive")} (${vdm?.m_percent})`}
              {...field}
              error={fieldState.error}
              readOnly
            />
          )}
          defaultValue=""
        />
      </form>
    </Dialog>
  );
}
