import * as yum from "yup";

import { requiredValidator, requiredValidatorSelect } from "utils/validators";

export const schema = yum.object().shape({
  Title: requiredValidator,
  Description: requiredValidator,
  IdCurrency: requiredValidator,
  HourlyRate: requiredValidatorSelect,
  StartDate: requiredValidator,
  EndDate: requiredValidator
});
