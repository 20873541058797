import { useEffect, useState } from "react";

import { confirmDialog } from "primereact/confirmdialog";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { createProjectTask, createProjectTaskInit } from "state/actions/projectsActions";

import Button from "components/Button";
import { Column, DataTable } from "components/DataTable";
import { dateBodyTemplate, taskActionsBodyTemplate } from "components/DataTable/DataTableBodyTemplates";
import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

import { IColumn } from "types/column";
import { ITask } from "types/task";

import EditImportTaskModal from "./components/EditImportTaskModal";

export default function ImportTasks() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tasksToImport, setTasksToImport] = useState<undefined | ITask[]>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [taskToEdit, setTaskToEdit] = useState<null | ITask>(null);
  const setShowHoursModal = false;

  const { state } = useLocation();

  const deleteTask = (taskId: number) => {
    confirmDialog({
      acceptClassName: "p-button-danger",
      header: t("confirmation.areYouSure"),
      message: `${t("confirmation.doYouReallyWantToDeleteTheseRecords")}`,
      draggable: false,
      accept: () => {
        setTasksToImport(tasksToImport?.filter((task) => task.Id !== taskId));
      }
    });
  };

  const confirmImport = async () => {
    tasksToImport?.map(async (data) => {
      const taskData = {
        IdProject: data.IdProject,
        Name: data.Name,
        Description: data.Description ? data.Description : "",
        StartDate: data.StartDate || new Date(),
        EndDate: data.EndDate || new Date(),
        Duration: data.Duration
      };
      await dispatch(createProjectTask(taskData)).then(() => dispatch(createProjectTaskInit()));
    });

    if (tasksToImport) {
      navigate(`/projects/${tasksToImport[0].IdProject}`);
    }
  };

  const onSubmitUpdate = (task: ITask) => {
    const updatedTasks = tasksToImport?.filter((mTask) => mTask.Id !== task.Id);
    updatedTasks?.push(task);
    setTasksToImport(updatedTasks?.sort((task1, task2) => (task1.Id > task2.Id ? 1 : -1)));
  };

  const ITasksColumns: IColumn[] = [
    { field: "Id", header: "Id" },
    { field: "Name", header: t("mytasks.taskName") },
    { field: "Description", header: t("common.description") },
    {
      field: "StartDate",
      header: t("common.startDate"),
      body: (item: ITask) =>
        dateBodyTemplate(item?.StartDate) === "NaN/NaN/NaN" ? "" : dateBodyTemplate(item?.StartDate)
    },
    {
      field: "EndDate",
      header: t("common.endDate"),
      body: (item: ITask) => dateBodyTemplate(item?.EndDate)
    },
    { field: "Duration", header: t("task.duration") },
    {
      field: "Actions",
      body: (item: ITask) =>
        taskActionsBodyTemplate({ item, setShowEditModal, setTaskToEdit, setShowHoursModal, deleteTask }, false),
      bodyStyle: { textAlign: "center" },
      style: { width: "10rem" }
    }
  ];

  useEffect(() => {
    setTasksToImport(state as ITask[]);
  }, []);

  if (!tasksToImport) {
    return <Loading />;
  }

  return (
    <section className="container mx-auto">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("tasks.taskImportConfirmation")}
        </div>
      </SectionTitle>
      <div className="card">
        <DataTable value={tasksToImport}>
          {ITasksColumns.map((col) => {
            return <Column key={col.field} {...col} />;
          })}
        </DataTable>

        <Button
          icon="pi pi-check"
          className="my-6"
          size="small"
          label={t("tasks.confirmImport")}
          onClick={confirmImport}
        />
      </div>

      <EditImportTaskModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
          if (taskToEdit) {
            setTaskToEdit(null);
          }
        }}
        onSubmitUpdate={onSubmitUpdate}
        task={taskToEdit}
      />
    </section>
  );
}
