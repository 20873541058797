import { useEffect, useState } from "react";

import useArray from "hooks/useArray";
import { useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { selectGetUserDetailsDataSuccess, selectUpdateUserDetailsDataSuccess } from "state/selectors/usersSelectors";

import Button from "components/Button";
import Chip from "components/Chip";
import SectionTitle from "components/SectionTitle";

import { IUserDetails } from "types/user";

import SkillsModal from "../SkillsModal";

export default function Skills() {
  const { t } = useTranslation();

  const userDetails = useAppSelector(selectGetUserDetailsDataSuccess) as IUserDetails;
  const updateUserDetailsDataSuccess = useAppSelector(selectUpdateUserDetailsDataSuccess) as boolean;

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { uniqBySetWithArrayFrom } = useArray();

  useEffect(() => {
    if (updateUserDetailsDataSuccess) {
      setShowEditModal(false);
    }
  }, [updateUserDetailsDataSuccess]);

  if (!userDetails) {
    return null;
  }

  const filteredSkills = uniqBySetWithArrayFrom(userDetails.Skills);

  return (
    <section>
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("profile.skills")}
          {filteredSkills.length <= 0 ? (
            <Button
              label="Add new skills"
              icon="pi pi-plus"
              text
              className="text-900"
              tooltip="Add new skills"
              tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
              onClick={() => setShowEditModal(true)}
            />
          ) : null}
        </div>
      </SectionTitle>
      <div className="w-full card">
        <div className="relative -mt-5">
          {filteredSkills.length > 0 ? (
            <div className="absolute top-0 right-0 p-3 -mr-5">
              <Button
                icon="pi pi-file-edit"
                rounded
                text
                className="h-2rem w-2rem text-900 mr-1"
                tooltip="Edit skills"
                tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
                onClick={() => {
                  setShowEditModal(true);
                }}
              />
            </div>
          ) : null}
          <div className="pl-0 -mt-4 -mb-4 card flex flex-wrap gap-2">
            {filteredSkills?.length > 0 ? (
              filteredSkills.map((item) => (
                <Chip
                  label={item}
                  key={item}
                  style={{ backgroundColor: "var(--highlight-bg)", color: "var(--primary-500)" }}
                />
              ))
            ) : (
              <div>{t("profile.noWorkExperience")}</div>
            )}
          </div>
        </div>
      </div>
      <SkillsModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
        mySkills={filteredSkills}
      />
    </section>
  );
}
