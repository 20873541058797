import { useEffect, useRef } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Messages as PrimeMessages } from "primereact/messages";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { resetUserPassword } from "state/actions/authActions";
import {
  selectResetUserPasswordFail,
  selectResetUserPasswordRequest,
  selectResetUserPasswordSuccess
} from "state/selectors/authSelectors";

import Button from "components/Button";
import { InputPassword } from "components/FormControls";
import Loading from "components/Loading";
import Messages from "components/Messages/Messages";

import { schema } from "./schema";

export default function ResetPassword() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const msgs = useRef<PrimeMessages>(null);

  const resetUserPaswordRequest = useAppSelector(selectResetUserPasswordRequest);
  const resetUserPasswordSuccess = useAppSelector(selectResetUserPasswordSuccess);
  const resetUserPasswordFail = useAppSelector(selectResetUserPasswordFail);

  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const onSubmit = (data: Record<string, string>) => {
    const token = searchParams.get("action") as string;
    msgs.current?.clear();
    const passwordNotEqual = data.Password !== data.ConfirmPassword;
    if (passwordNotEqual) {
      setError("Password", { message: t("auth.passwordsDidntMatch") });
      setError("ConfirmPassword", { message: t("auth.passwordsDidntMatch") });
      return msgs.current?.show({
        sticky: true,
        severity: "error",
        detail: t("auth.passwordsDidntMatch")
      });
    } else {
      const userData = {
        ActionToken: token,
        Password: data.Password
      };
      dispatch(resetUserPassword(userData));
    }
  };

  useEffect(() => {
    if (resetUserPasswordFail) {
      navigate("/");
    }
  }, [resetUserPasswordFail]);

  if (resetUserPaswordRequest) {
    return <Loading />;
  }

  if (resetUserPasswordSuccess) {
    return (
      <>
        <div>{t("auth.resetPasswordSuccess")}</div>
        <div>{t("register.youCanNowSignin")}</div>
        <div className="pt-5">
          <Button label={t("form.goToLogin")} onClick={() => navigate("/login")} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="text-4xl line-height-2 text-center font-light pre">{t("auth.enterYourNewPasword")}</div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className="w-full md:w-24rem mx-auto flex flex-column mt-6 gap-4"
      >
        <Messages msgs={msgs} />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputPassword label={t("form.password")} {...field} error={fieldState.error} />
          )}
          name="Password"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputPassword label={t("form.confirmPassword")} {...field} error={fieldState.error} />
          )}
          name="ConfirmPassword"
          defaultValue=""
        />
        <Button label="Reset password" />
      </form>
    </>
  );
}
