import { useEffect, useState } from "react";

import useCommon from "hooks/useCommon";
import { useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { selectGetUserDataSuccess, selectUpdateUserDataSuccess } from "state/selectors/usersSelectors";

import Button from "components/Button";
import DataField from "components/DataField";
import SectionTitle from "components/SectionTitle";

import { IUserData } from "types/user";

import EditUserProfileModal from "../EditUserProfileModal";

export default function BasicInformation() {
  const { t } = useTranslation();
  const user = useAppSelector(selectGetUserDataSuccess) as IUserData;

  const [show, closeModal] = useState<boolean>(false);
  const { countries } = useCommon();

  const updateUserDetailsSuccess = useAppSelector(selectUpdateUserDataSuccess) as boolean;
  const country = countries?.find((country) => country?.value === user?.IdCountry)?.label as string;

  useEffect(() => {
    if (updateUserDetailsSuccess) {
      closeModal(false);
    }
  }, [updateUserDetailsSuccess]);

  if (!user) {
    return null;
  }

  return (
    <section className="mb-6">
      <SectionTitle>Basic information</SectionTitle>
      <div className="w-full flex card relative pt-6">
        <div className="flex flex-column w-6 gap-4">
          <DataField label="First Name" value={user?.FirstName} />
          <DataField label="Last Name" value={user?.LastName} />
        </div>
        <div className="w-6">
          <DataField label="Country" value={country} />
        </div>
        <div className="absolute top-0 right-0 p-3">
          <Button
            icon="pi pi-user-edit"
            rounded
            text
            className="h-2rem w-2rem text-900"
            tooltip="Edit basic information"
            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => closeModal(true)}
          />
        </div>
      </div>
      <EditUserProfileModal show={show} closeModal={() => closeModal(false)} />
    </section>
  );
}
