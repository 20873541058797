import { useEffect, useState } from "react";

import { NavLink, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { getProjectInfo, getProjectInfoInit } from "state/actions/projectsActions";
import {
  selectGetProjectInfoRequest,
  selectGetProjectInfoSuccess,
  selectUpdateProjectSuccess
} from "state/selectors/projectsSelectors";

import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

import { IProject } from "types/project";

import ProjectDetails from "../ProjectDetails";
import Tasks from "../Tasks";

export default function Project() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const projectId = params.id as string;
  const { t } = useTranslation();

  const { toast } = useToast();

  const project = useAppSelector(selectGetProjectInfoSuccess) as IProject;
  const projectRequest = useAppSelector(selectGetProjectInfoRequest) as boolean;
  const updateProjectSuccess = useAppSelector(selectUpdateProjectSuccess) as boolean;

  const [projectLoaded, setProjectLoaded] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getProjectInfo(projectId));
    return () => {
      dispatch(getProjectInfoInit());
    };
  }, []);

  useEffect(() => {
    if (project) {
      setProjectLoaded(true);
    }
  }, [project]);

  useEffect(() => {
    if (updateProjectSuccess) {
      dispatch(getProjectInfo(projectId));
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("project.updated"),
        life: 3000
      });
    }
  }, [updateProjectSuccess]);

  if (projectRequest && !projectLoaded) {
    return (
      <div className="h-full">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <section className="container mx-auto px-2 md:px-0">
        <SectionTitle>
          <div className="text-sm md:text-lg">
            <NavLink to="/projects">Projects</NavLink>
            <div className="inline text-base md:text-2xl">&nbsp;/&nbsp;{t("project.projectDetails")}</div>
          </div>
        </SectionTitle>
        {project && (
          <div className="card pt-0">
            <ProjectDetails {...project} />
          </div>
        )}
      </section>
      <Tasks {...project} />
    </>
  );
}
