import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { formatDate } from "utils/formatDate";

import { applyToJob, applyToJobInit } from "state/actions/jobsActions";
import { selectSearchJobsDataSuccess } from "state/selectors/jobsSelectors";
import { selectGetUserDataSuccess } from "state/selectors/usersSelectors";

import Button from "components/Button";
import DataField from "components/DataField";
import Divider from "components/Divider";

import { ISearchJob, ISearchJobs } from "types/job";
import { IUserData } from "types/user";

const JobsBoardList = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const searchjobs = useAppSelector(selectSearchJobsDataSuccess) as ISearchJobs;
  const userData = useAppSelector(selectGetUserDataSuccess) as IUserData;

  const jobsToDisplay = searchjobs?.jobs;

  if (!jobsToDisplay) {
    return null;
  }

  const handleApplyToJob = (jobId: number) => {
    const jobApplicationsData = {
      IdJob: parseInt(jobId as unknown as string)
    };
    dispatch(applyToJob(jobApplicationsData)).then(() => dispatch(applyToJobInit()));
  };

  return (
    <div className="w-full">
      {jobsToDisplay.map((job: ISearchJob, index: number) => (
        <div key={index} className="relative">
          {index > 0 && <Divider className="mb-4 mt-4" />}
          <div className="w-full">
            <div className="flex w-full justify-content-end mb-0 gap-2">
              {userData?.Id !== job.IdOwner && (
                <Button
                  icon="pi pi-check"
                  size="small"
                  label={job.ApplyStatus === 1 ? t("job.applied") : t("job.applyNow")}
                  onClick={() => handleApplyToJob(job.Id)}
                  disabled={job.ApplyStatus === 1 ? true : false}
                />
              )}
            </div>
            <div className="flex gap-0 md:gap-4 mb-6 flex-column md:flex-row">
              <div className="pt-4 md:w-6 flex flex-row md:flex-column md:gap-4">
                <DataField label={t("job.title")} value={job.Name} />
                <DataField textField label={t("common.description")} value={job.Description} />
              </div>
              <div className="pt-4 md:w-6 flex flex-column gap-4">
                <div className="flex-row">
                  <DataField className="break-text" textField label={t("common.web")} value={job.Web} />
                </div>

                <div className="flex w-full flex-row">
                  <DataField
                    status={job.Status === 1 ? "active" : "inactive"}
                    label={t("common.status")}
                    value={job.Status === 1 ? t("common.active") : t("common.notActive")}
                  />
                  <DataField
                    label={t("project.isStartup")}
                    value={job.IsStartup === 1 ? t("confirmation.yes") : t("confirmation.no")}
                  />
                </div>

                <div className="flex w-full flex-column md:flex-row gap-4 md:gap-0">
                  <DataField label={t("job.organizationName")} value={job.OrganizationName} />
                  <DataField className="break-text" label={t("job.organizationWeb")} value={job.OrganizationWeb} />
                </div>
                <div className="flex w-full">
                  <DataField label={t("common.startDate")} value={formatDate(job.StartDate)} />
                  <DataField label={t("common.endDate")} value={formatDate(job.EndDate)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default JobsBoardList;
