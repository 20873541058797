import { action, init } from "state/utils/action";

import {
  createProject as createProjectService,
  createProjectTask as createProjectTaskService,
  deleteProjectTask as deleteProjectTaskService,
  getMyProjects as getMyProjectsServices,
  getMyTaskInfo as getMyTaskInfoService,
  getMyTasks as getMyTasksServices,
  getProjectCreators as getProjectCreatorsService,
  getProjectInfo as getProjectInfoService,
  getProjectPositions as getProjectPositionsService,
  getProjectTaskInfo as getProjectTaskInfoService,
  getProjectTasks as getProjectTasksService,
  getProjects as getProjectsService,
  updateProject as updateProjectService,
  updateProjectTask as updateProjectTaskService
} from "services/ProjectsService";

import {
  CREATE_PROJECT,
  CREATE_PROJECT_TASK,
  DELETE_PROJECT_TASK,
  GET_MY_PROJECTS,
  GET_MY_TASKS,
  GET_MY_TASK_INFO,
  GET_PROJECTS,
  GET_PROJECT_CREATORS,
  GET_PROJECT_INFO,
  GET_PROJECT_POSITIONS,
  GET_PROJECT_TASKS,
  GET_PROJECT_TASK_INFO,
  UPDATE_PROJECT,
  UPDATE_PROJECT_TASK
} from "../constants/projectsConstants";

export const getProjects = (params: any) => action(`projects/${GET_PROJECTS}`, getProjectsService, params);

export const createProject = (params: Record<string, unknown>) =>
  action(`projects/${CREATE_PROJECT}`, createProjectService, params);
export const createProjectInit = () => init(`projects/${CREATE_PROJECT}/init`);

export const updateProject = (params: Record<string, unknown>) =>
  action(`projects/${UPDATE_PROJECT}`, updateProjectService, params);
export const updateProjectInit = () => init(`projects/${UPDATE_PROJECT}/init`);

export const getProjectInfo = (projectId: string) =>
  action(`projects/${GET_PROJECT_INFO}`, getProjectInfoService, projectId);
export const getProjectInfoInit = () => init(`projects/${GET_PROJECT_INFO}/init`);

export const getProjectTasks = (params: Record<string, unknown>) =>
  action(`tasks/${GET_PROJECT_TASKS}`, getProjectTasksService, params);

export const createProjectTask = (params: Record<string, unknown>) =>
  action(`tasks/${CREATE_PROJECT_TASK}`, createProjectTaskService, params);
export const createProjectTaskInit = () => init(`tasks/${CREATE_PROJECT_TASK}/init`);

export const updateProjectTask = (params: Record<string, unknown>) =>
  action(`tasks/${UPDATE_PROJECT_TASK}`, updateProjectTaskService, params);
export const updateProjectTaskInit = () => init(`tasks/${UPDATE_PROJECT_TASK}/init`);

export const deleteProjectTask = (taskId: number) =>
  action(`tasks/${DELETE_PROJECT_TASK}`, deleteProjectTaskService, taskId);
export const deleteProjectTaskInit = () => init(`tasks/${DELETE_PROJECT_TASK}/init`);

export const getProjectTaskInfo = (taskId: string) =>
  action(`tasks/${GET_PROJECT_TASK_INFO}`, getProjectTaskInfoService, taskId);

export const getProjectPositions = () => action(`assignees/${GET_PROJECT_POSITIONS}`, getProjectPositionsService);
export const getProjectCreators = (projectId: number) =>
  action(`assignees/${GET_PROJECT_CREATORS}`, getProjectCreatorsService, projectId);

export const getMyTasks = (params: Record<string, unknown>) =>
  action(`mytasks/${GET_MY_TASKS}`, getMyTasksServices, params);
export const getMyTasksInit = () => init(`mytasks/${GET_MY_TASKS}/init`);

export const getMyTaskInfo = (taskId: number) => action(`mytasks/${GET_MY_TASK_INFO}`, getMyTaskInfoService, taskId);

export const getMyProjects = (params = "") => action(`myprojects/${GET_MY_PROJECTS}`, getMyProjectsServices, params);
