import { useState } from "react";

import useCommon from "hooks/useCommon";
import useTranslation from "hooks/useTranslation";

import { getDayMonthYear } from "utils/formatDate";

import Button from "components/Button";
import DataField from "components/DataField";

import { IProject } from "types/project";

import ProjectModal from "../ProjectModal";

export default function ProjectDetails(project: IProject) {
  const { t } = useTranslation();
  const { currencies } = useCommon();

  const [showEditModal, setShowEditModal] = useState(false);

  if (!project) {
    return null;
  }

  return (
    <div className="relative">
      <div className="absolute top-0 right-0 p-3 -mr-3">
        <Button
          icon="pi pi-file-edit"
          rounded
          text
          className="h-2rem w-2rem text-900 mr-1 absolute pr-2 md:pr-0 md:relative"
          tooltip="Edit project"
          tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
          type="button"
          onClick={() => {
            setShowEditModal(true);
          }}
        />
      </div>
      <div className="flex gap-4 pt-3 md:pt-6 flex-column md:flex-row">
        <div className="md:w-6 flex md:flex-column gap-4">
          <DataField label={t("project.projectName")} value={project.Name} />
          <DataField textField label={t("common.description")} value={project.Description} />
        </div>
        <div className="md:w-6 flex flex-column gap-4">
          <div className="flex w-full gap-4 md:gap-0 flex-column md:flex-row">
            <DataField textField label={t("common.web")} value={project.Web} className="break-text" />
            <DataField
              status={project.Status === 1 ? "active" : "inactive"}
              label={t("common.status")}
              value={project.Status === 1 ? t("common.active") : t("common.notActive")}
            />
          </div>

          <div className="flex w-full gap-4 md:gap-0">
            <DataField
              label={t("project.isStartup")}
              value={project.IsStartup === 1 ? t("confirmation.yes") : t("confirmation.no")}
            />
            <DataField label={t("project.estimatedMonths")} value={`${project.EstimatedTime}`} />
          </div>
          <div className="flex w-full gap-4 md:gap-0">
            <DataField label={t("project.estimatedBudget")} value={project.EstimatedBudget} />
            <DataField
              label={t("project.currency")}
              value={`${currencies?.find((currency) => currency.value === project.IdCurrency)?.label}`}
            />
          </div>
          <div className="flex w-full gap-4 md:gap-0">
            <DataField label={t("common.startDate")} value={getDayMonthYear(project.StartDate)} />
            <DataField label={t("common.endDate")} value={getDayMonthYear(project.EndDate)} />
          </div>
        </div>
      </div>
      <ProjectModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
        project={project}
      />
    </div>
  );
}
