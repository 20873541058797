
import Bounties from "./components/Bounties";
import MyBounties from "./components/MyBounties";

export default function Community() {
  return (
    <>
      <div className="container mx-auto py-0 px-2 md:px-0">
        <MyBounties />
        <Bounties />
      </div>
    </>
  );
}
