import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { resetPassword, resetPasswordInit } from "state/actions/authActions";
import {
  selectResetPasswordUserFail,
  selectResetPasswordUserRequest,
  selectResetPasswordUserSuccess
} from "state/selectors/authSelectors";

import Button from "components/Button";
import { InputText } from "components/FormControls";
import Loading from "components/Loading";

import { schema } from "./schema";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resetPaswordUserRequest = useAppSelector(selectResetPasswordUserRequest);
  const resetPasswordUserSuccess = useAppSelector(selectResetPasswordUserSuccess);
  const resetPasswordUserFail = useAppSelector(selectResetPasswordUserFail);

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const onSubmit = (data: Record<string, string>) => {
    const userData = {
      Email: data.email
    };
    dispatch(resetPassword(userData));
  };

  useEffect(() => {
    dispatch(resetPasswordInit());
  }, []);

  if (resetPaswordUserRequest) {
    return <Loading />;
  }

  if (resetPasswordUserSuccess) {
    return (
      <>
        <div>{t("auth.resetPaswordRequest")}</div>
        <div>{t("auth.pleaseCheckEmail")}</div>
        <div className="pt-6">
          <Button onClick={() => navigate("/login")} label={t("form.goToLogin")} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="text-4xl line-height-2 text-center font-light pre">{t("forgot.title")}</div>
      <div className="my-6 text-dark-gray text-lg text-center flex flex-column justify-content-center">
        {t("auth.alreadyHaveAccount")}&nbsp;
        <Button onClick={() => navigate("/login")} label={t("auth.signIn")} link />
      </div>
      {resetPasswordUserFail && (
        <div className="pb-12 text-center text-error-alert font-semibold">{t("formValidators.validEmail")}</div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className="w-full md:w-24rem mx-auto flex flex-column gap-4"
      >
        <Controller
          control={control}
          render={({ field, fieldState }) => <InputText label="Email" {...field} error={fieldState.error} />}
          name="email"
          defaultValue=""
        />
        <Button label="Reset password" />
      </form>
    </>
  );
}
