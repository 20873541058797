import { confirmDialog } from "primereact/confirmdialog";

import useCommon from "hooks/useCommon";
import { useAppDispatch } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { getDayMonthYear } from "utils/formatDate";

import { deleteInvite, deleteInviteInit } from "state/actions/authActions";

import Button from "components/Button";
import { Column, DataTable } from "components/DataTable";
import Loading from "components/Loading";

import { IColumn } from "types/column";
import { IInvite } from "types/invite";

export default function InvitesTable({ invites, inviteRequests }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { inviteStatus } = useCommon();

  const filteredInvites = invites?.invites?.map((inviteItem: any) => ({
    Id: inviteItem.Id,
    FirstName: inviteItem.FirstName,
    LastName: inviteItem.LastName,
    StartDate: getDayMonthYear(inviteItem.StartDate),
    Email: inviteItem.Email,
    Status: inviteStatus.find((s) => s.value.toString() === inviteItem.Status.toString())?.label
  }));

  const headers: IColumn[] = [
    { field: "FirstName", header: t("form.firstName"), sortable: true },
    { field: "LastName", header: t("form.lastName"), sortable: true },
    { field: "StartDate", header: t("common.startDate"), sortable: true },
    { field: "Email", header: t("form.email"), sortable: true },
    { field: "Status", header: t("common.status"), sortable: true }
  ];

  const handleDeleteInvite = (invite: IInvite) => {
    const inviteToDelete = {
      Id: parseInt(invite.Id as unknown as string)
    };

    dispatch(deleteInvite(inviteToDelete.Id)).then(() => dispatch(deleteInviteInit()));
  };

  const deleteInviteConfirmDialog = (invite: IInvite) => {
    confirmDialog({
      header: t("confirmation.areYouSure"),
      message: `${t("invite.deleteInvite")
        .replace("[user]", invite.FirstName + " " + invite.LastName)}`,
      draggable: false,
      accept: () => handleDeleteInvite(invite)
    });
  };

  const viewInviteBodyTemplate = (rowData: IInvite) => {
    return (
      <div className="w-full">
        {inviteStatus.find((s) => s.label.toString() === rowData.Status.toString())?.value === 1 && (
          <div className="flex w-full">
            <Button
              label={t("common.delete")}
              severity="danger"
              type="button"
              icon="pi pi-times"
              className="ml-2 w-full"
              onClick={() => {
              deleteInviteConfirmDialog(rowData);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  if (inviteRequests) {
    return <Loading />;
  }

  return (
    <DataTable value={filteredInvites}>
      {headers.map((col) => (
        <Column key={col.field} {...col} />
      ))}
      <Column body={viewInviteBodyTemplate} />
    </DataTable>
  );
}
