import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { updateUserData, updateUserDataInit } from "state/actions/usersAction";
import { selectGetUserDataSuccess, selectUpdateUserDataRequest } from "state/selectors/usersSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { InputText, Select } from "components/FormControls";

import { IUserData } from "types/user";

import { schema } from "./schema";

export default function EditUserProfileModal({ show, closeModal }: any) {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectGetUserDataSuccess) as IUserData;
  const updateUserDataRequest = useAppSelector(selectUpdateUserDataRequest) as boolean;

  const { currencies, paymentTypes, countries } = useCommon();

  const { handleSubmit, control } = useForm<IUserData>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    defaultValues: {
      ...user
    }
  });

  const { t } = useTranslation();

  const onSubmit: SubmitHandler<IUserData> = (data) => {
    const user = {
      ...data,
      IdCountry: data.IdCountry || "27",
      IdPaymentType: data.IdPaymentType || "1",
      IdCurrency: data.IdCurrency || "1",
      HourlyRate: parseFloat(data?.HourlyRate) || "0"
    };
    dispatch(updateUserData(user)).then(() => dispatch(updateUserDataInit()));
  };

  const footerContent = (
    <div>
      <Button
        label={t("confirmation.cancel")}
        onClick={() => closeModal(false)}
        disabled={updateUserDataRequest}
        outlined
        type="button"
      />
      <Button label={t("confirmation.save")} form="edit-personal-details-form" disabled={updateUserDataRequest} />
    </div>
  );

  return (
    <Dialog
      show={show}
      closeModal={closeModal}
      title={t("profile.editUserProfileModalTitle")}
      footerContent={footerContent}
    >
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="edit-personal-details-form">
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("form.firstName")} {...field} error={fieldState.error} />
          )}
          name="FirstName"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("form.lastName")} {...field} error={fieldState.error} />
          )}
          name="LastName"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("form.headline")} {...field} error={fieldState.error} />
          )}
          name="Headline"
        />
        <Controller
          name="IdCountry"
          render={({ field, fieldState }) => (
            <Select
              label={t("form.selectCounty")}
              placeholder={t("form.selectCounty")}
              options={countries}
              error={fieldState.error}
              {...field}
            />
          )}
          control={control}
        />
        <Controller
          name="IdPaymentType"
          render={({ field, fieldState }) => (
            <Select
              label={t("form.selectPaymentType")}
              placeholder={t("form.selectPaymentType")}
              options={paymentTypes}
              error={fieldState.error}
              {...field}
            />
          )}
          control={control}
        />
        <Controller
          name="IdCurrency"
          render={({ field, fieldState }) => (
            <Select
              label={t("form.selectCurrency")}
              placeholder={t("form.selectCurrency")}
              options={currencies}
              error={fieldState.error}
              {...field}
            />
          )}
          control={control}
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("assignee.hourlyRate")} {...field} error={fieldState.error} type="number" />
          )}
          name="HourlyRate"
        />
      </form>
    </Dialog>
  );
}
