import { useEffect, useState } from "react";

import { PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { listInvitedMembers } from "state/actions/authActions";
import { selectDeleteInviteFail, selectDeleteInviteSuccess, selectInviteMemberFail, selectInviteMemberSuccess, selectListInvitedMembersSuccess } from "state/selectors/authSelectors";

import Button from "components/Button";
import SectionTitle from "components/SectionTitle";

import { IInvites } from "types/invite";

import InviteMemberModal from "./components/InviteMemberModal";
import InvitesList from "./components/InvitesList";

export default function Invites() {
  const { t } = useTranslation();
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const [first, setFirst] = useState<number>(0);

  const inviteMemberSuccess = useAppSelector(selectInviteMemberSuccess);
  const inviteMemberFail = useAppSelector(selectInviteMemberFail);
  const deleteInviteSuccess = useAppSelector(selectDeleteInviteSuccess);
  const deleteInviteFail = useAppSelector(selectDeleteInviteFail);
  const listInvitedMembersSuccess = useAppSelector(selectListInvitedMembersSuccess) as IInvites;

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  useEffect(() => {
    if (inviteMemberSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("invite.sent"),
        life: 3000
      });
      getData({});
    }
    if (deleteInviteSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("invite.deleted"),
        life: 3000
      });
      getData({});
    }
  }, [inviteMemberSuccess, deleteInviteSuccess]);

  useEffect(() => {
    if (inviteMemberFail) {
      toast?.current?.show({
        severity: "error",
        summary: t("common.error"),
        detail: t("invite.failedInviteRequest"),
        sticky: true
      });
    }
    if (inviteMemberFail) {
      toast?.current?.show({
        severity: "error",
        summary: t("common.error"),
        detail: t("invite.failedDeleteInviteRequest"),
        sticky: true
      });
    }
  }, [inviteMemberFail, deleteInviteFail]);

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };

    dispatch(listInvitedMembers({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    getData({ page: page });
  };

  useEffect(() => {
    getData({});
  }, []);

  return (
    <section className="container mx-auto py-0 px-2 md:px-0">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("invite.invitesHistory")}
          <Button
            icon="pi pi-plus"
            text
            className="text-900"
            tooltip={t("invite.inviteNewMember")}
            type="button"
            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => setShowEditModal(true)}
            ><span className="text-900 p-button-label hidden md:flex ml-2"> {t("invite.inviteNewMember")}</span></Button>
        </div>
      </SectionTitle>
      <InvitesList invites={listInvitedMembersSuccess} handlePageChange={handlePageChange} first={first} />
      <InviteMemberModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
      />
    </section>
  );
}
