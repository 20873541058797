import { useEffect, useState } from "react";

import { PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { getAdminReports } from "state/actions/dashboardActions";
import { selectGetAdminReportsDataSuccess, selectGetAdminReportsRequest } from "state/selectors/dashboardSelectors";

import SectionTitle from "components/SectionTitle";

import { IMyReports } from "types/report";

import AdminReportsList from "./components/AdminReportsList";

export default function AdminReports() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [first, setFirst] = useState<number>(0);

  const getAdminReportsSuccess = useAppSelector(selectGetAdminReportsDataSuccess) as IMyReports;
  const getAdminReportsRequest = useAppSelector(selectGetAdminReportsRequest) as boolean;

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };

    dispatch(getAdminReports({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    getData({ page: page });
  };

  useEffect(() => {
    getData({});
  }, []);

  return (
    <div className="container mx-auto mt-6">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("myreports.myReports")}
        </div>
      </SectionTitle>
      <AdminReportsList
        reports={getAdminReportsSuccess}
        reportsRequest={getAdminReportsRequest}
        handlePageChange={handlePageChange}
        first={first}
      />
    </div>
  );
}
