import { api } from "utils/api";

const baseJobsUrl = `https://${process.env.REACT_APP_ENV}jobs.${process.env.REACT_APP_API_URL}/jobs`;

// get jobs (client)
export const getJobs = (params: Record<string, unknown>) => api.get(`${baseJobsUrl}`, params);

// create job (client)
export const createJob = (params: Record<string, unknown>) => api.post(`${baseJobsUrl}`, params);

// update job (client)
export const updateJob = (params: Record<string, unknown>) => api.put(`${baseJobsUrl}`, params);

// get job information
export const getJobInfo = (jobId: string) => api.get(`${baseJobsUrl}/${jobId}`);

// list of all jobs from all clients
export const searchJobs = (params: string) => api.get(`${baseJobsUrl}/search-jobs/list`, params);

// apply to job (creator)
export const applyToJob = (params: Record<string, unknown>) =>
  api.post(`${baseJobsUrl}/job-applications/creator`, params);

// get all jobs that creator has applied to
export const getAllJobApplications = (params?: string) =>
  api.get(`${baseJobsUrl}/job-applications/creator/list`, params);

// get all contracts for creator
export const getAllContracts = (params: string) => api.get(`${baseJobsUrl}/contracts/creator/list`, params);

export const getJobApplicationsClient = (params: Record<string, unknown>) =>
  api.get(`${baseJobsUrl}/job-applications/client/list`, params);

// get all contracts for client
export const getAllContractsClient = (params: string) => api.get(`${baseJobsUrl}/contracts/client/list`, params);

// update job application client
export const updateJobApplicationClient = (params: string) => api.put(`${baseJobsUrl}/job-applications/client`, params);

// create contract
export const createContract = (params: Record<string, unknown>) => api.post(`${baseJobsUrl}/contracts/client`, params);
