import { useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { getDayMonthYear } from "utils/formatDate";

import { selectGetMyBountiesDataSuccess, selectGetMyBountiesDataRequest } from "state/selectors/dashboardSelectors";

import { Column, DataTable } from "components/DataTable";
import Loading from "components/Loading";

import { IBounties } from "types/bounty";
import { IColumn } from "types/column";

export default function MyBountiesTable() {
  const { t } = useTranslation();

  const myBountiesRequest = useAppSelector(selectGetMyBountiesDataRequest);
  const myBountiesSuccess = useAppSelector(selectGetMyBountiesDataSuccess) as IBounties;

  const myBounties = myBountiesSuccess?.mybounties?.map((bountyItem) => ({
    Id: bountyItem.Id,
    Name: bountyItem.Name,
    FullName: bountyItem.FullName,
    StartDate: getDayMonthYear(bountyItem.StartDate),
    CurrentStage: bountyItem.CurrentStage + t("common.year"),
    CurrentPayoff: bountyItem.CurrentPayoff + " %",
    TotalEarnedWithCurrency: bountyItem.TotalEarned + " " + bountyItem.CurrencyShortName
  }));

  const headers: IColumn[] = [
    { field: "Name", header: t("common.name") },
    { field: "FullName", header: t("mybounties.person") },
    { field: "StartDate", header: t("common.startDate") },
    { field: "CurrentStage", header: t("mybounties.currentstage") },
    { field: "CurrentPayoff", header: t("mybounties.currentpayoff") },
    { field: "TotalEarnedWithCurrency", header: t("mybounties.totalearned") }
  ];

  if (myBountiesRequest) {
    return <Loading />;
  }

  return (
    <DataTable value={myBounties}>
      {headers.map((col) => (
        <Column sortable key={col.field} {...col} />
      ))}
    </DataTable>
  );
}
