import { useEffect, useMemo, useRef } from "react";

import { NavLink, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { exportReport } from "utils/exportFile";
import { getDateForExport } from "utils/formatDate";

import { getMyReport, getMyReportActivities } from "state/actions/dashboardActions";
import {
  selectGetMyReportDataSuccess,
  selectGetMyReportsRequest,
  selectMyGetReportActivitiesRequest,
  selectMyGetReportActivitiesSuccess,
  selectUpdateReportDataSuccess
} from "state/selectors/dashboardSelectors";

import Button from "components/Button";
import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

import { IActivityReports, IMReports } from "types/report";

import PrintReport from "../PrintReport";
import ReportActivityTable from "../ReportActivityTable";
import ReportDetails from "../ReportDetails";

export default function Report() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const reportId = params.id as string;
  const { t } = useTranslation();
  const { toast } = useToast();

  const componentRef = useRef<any>();

  const reportsRequest = useAppSelector(selectGetMyReportsRequest) as boolean;
  const report = useAppSelector(selectGetMyReportDataSuccess) as IMReports;

  const reportActivityRequest = useAppSelector(selectMyGetReportActivitiesRequest) as boolean;
  const reportActivitySuccess = useAppSelector(selectMyGetReportActivitiesSuccess) as IActivityReports;

  const reportUpdateSuccess = useAppSelector(selectUpdateReportDataSuccess);

  const reportData = useMemo(() => {
    return reportActivitySuccess?.report_activities?.map((item) => ({
      "Project Name": item.ProjectName,
      Description: item.TaskDescription,
      "Task Name": item.TaskName,
      Hours: parseInt(item.Hours),
      "Start date": item.ActivityDate
    }));
  }, [reportActivitySuccess?.report_activities]);

  const handleExportPdf = useReactToPrint({
    content: () => componentRef?.current
  });

  const handleExportExcel = () => {
    return exportReport(reportData, `MyReportActivity_${getDateForExport()}`, t);
  };

  useEffect(() => {
    dispatch(getMyReportActivities(reportId));
    dispatch(getMyReport(reportId));
  }, [reportId]);

  useEffect(() => {
    if (reportUpdateSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("myreports.reportSent"),
        life: 3000
      });
      dispatch(getMyReport(reportId));
    }
  }, [reportUpdateSuccess]);

  if (reportsRequest) {
    return (
      <div className="h-full">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <section className="container mx-auto px-2 md:px-0">
        <SectionTitle>
          <div className="relative flex justify-content-between align-items-center w-full px-3 md:px-0">
            <div className="text-sm md:text-lg">
              <NavLink to="/reports">Reports</NavLink>
              <div className="inline text-base md:text-2xl">&nbsp;/&nbsp;{t("myreports.reportDetails")}</div>
            </div>
            <div className="flex">
              <Button
                icon="pi pi-file-pdf"
                text
                className="text-900 mr-1"
                tooltip="Export to pdf"
                tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
                onClick={handleExportPdf}
              >
                <span className="text-900 p-button-label hidden md:flex ml-2">Export to pdf</span>
              </Button>
              <Button
                icon="pi pi-file-excel"
                text
                className="text-900 mr-0 md:mr-1"
                tooltip="Export to excel"
                tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
                onClick={handleExportExcel}
              >
                <span className="text-900 p-button-label hidden md:flex ml-2">Export to excel</span>
              </Button>
            </div>
          </div>
        </SectionTitle>
        <div className="card pt-0">{<ReportDetails {...report} />}</div>
      </section>
      <section className="container mx-auto">
        <SectionTitle>
          <div className="relative flex justify-content-between align-items-center w-full px-3 md:px-0">
            {t("myreports.reportActivity")}
          </div>
        </SectionTitle>
        <div className="card">
          <ReportActivityTable
            reportActivityRequest={reportActivityRequest}
            reportActivitySuccess={reportActivitySuccess}
          />
        </div>
        <div style={{ display: "none" }}>
          <div className="card" ref={componentRef}>
            <PrintReport report={report} reportActivities={reportActivitySuccess} />
          </div>
        </div>
      </section>
    </>
  );
}
