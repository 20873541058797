import { Controller, useForm } from "react-hook-form";

import { useAppDispatch } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { updateOrganizationReport, updateOrganizationReportInit } from "state/actions/dashboardActions";
import { sendNotification, sendNotificationInit } from "state/actions/notificationAction";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { InputTextarea } from "components/FormControls";

import { IModal } from "types/modal";

interface SubmitReportModalProps extends IModal {
  report: any;
}

export default function SubmitReportModal({ show, closeModal, report }: SubmitReportModalProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm<any>({
    reValidateMode: "onChange"
  });

  const onSubmit = (data: any) => {
    const reportToSend = {
      Name: report.ProjectName,
      IdContract: report.IdContract,
      Id: parseInt(report.IdReport),
      Comment: data.Comment ? data.Comment : "",
      Status: 3,
      Year: report.Year,
      Month: report.Month,
      ReportDate: report.ReportDate
    };

    const notificationToSend = {
      notificationType: "reviewAccepted",
      Email: report.ReporterEmails,
      EmailRequester: report.Email,
      ReportComment: data.Comment ? ". Comment: " + data.Comment + "." : ".",
      ReportId: parseInt(report.IdReport)
    };

    dispatch(updateOrganizationReport(reportToSend)).then(() => dispatch(updateOrganizationReportInit()));

    dispatch(sendNotification(notificationToSend)).then(() => dispatch(sendNotificationInit()));

    closeModal();
  };

  const footerContent = (
    <div>
      <Button onClick={closeModal} label={t("confirmation.cancel")} type="button" outlined />
      <Button type="submit" label={t("confirmation.submit")} form="submit-report-form" />
    </div>
  );

  return (
    <Dialog show={show} closeModal={closeModal} title={t("myreports.acceptReport")} footerContent={footerContent}>
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="submit-report-form">
        <Controller
          name="Comment"
          control={control}
          render={({ field, fieldState }) => (
            <InputTextarea rows={5} {...field} label={t("common.comment")} error={fieldState.error} />
          )}
        />
      </form>
    </Dialog>
  );
}
