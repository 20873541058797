import {
  GET_COLLEGE_DEGREES,
  GET_COUNTRIES,
  GET_CURRENCIES,
  GET_EMPLOYMENT_TYPES,
  GET_INDUSTRY_TYPES,
  GET_PAYMENT_TYPES,
  GET_ROLES,
  GET_SKILLS,
  GET_JOB_TYPES
} from "state/constants/commonConstants";
import { action } from "state/utils/action";

import {
  getCollegeDegrees as getCollegeDegreesService,
  getCountries as getCountriesService,
  getCurrencies as getCurrenciesService,
  getEmploymentTypes as getEmploymentTypesService,
  getIndustryTypes as getIndustryTypesService,
  getPaymentTypes as getPaymentTypesService,
  getRoles as getRolesService,
  getSkills as getSkillsService,
  getJobTypes as getJobTypesService
} from "services/CommonService";

export const getCountries = () => action(`common/${GET_COUNTRIES}`, getCountriesService);
export const getCurrencies = () => action(`common/${GET_CURRENCIES}`, getCurrenciesService);
export const getEmploymentTypes = () => action(`common/${GET_EMPLOYMENT_TYPES}`, getEmploymentTypesService);
export const getIndustryTypes = () => action(`common/${GET_INDUSTRY_TYPES}`, getIndustryTypesService);
export const getPaymentTypes = () => action(`common/${GET_PAYMENT_TYPES}`, getPaymentTypesService);
export const getRoles = () => action(`common/${GET_ROLES}`, getRolesService);
export const getSkills = () => action(`common/${GET_SKILLS}`, getSkillsService);
export const getCollegeDegrees = () => action(`common/${GET_COLLEGE_DEGREES}`, getCollegeDegreesService);
export const getJobTypes = () => action(`common/${GET_JOB_TYPES}`, getJobTypesService);
