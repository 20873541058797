import { ReactNode } from "react";

import "./Label.css";

interface LabelProps {
  children: ReactNode;
  error?: boolean;
}

export default function Label({ children, error }: LabelProps) {
  return <label className={`absolute custom-label text-sm ${error ? "p-error" : ""}`}>{children}</label>;
}
