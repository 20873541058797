import { RootState } from "../store";

export const selectCountriesSuccess = (state: RootState) => state.common.countries.data;
export const selectPaymentTypesSuccess = (state: RootState) => state.common.paymentTypes.data;
export const selectCurrenciesSuccess = (state: RootState) => state.common.currencies.data;
export const selectCollegeDegreesSuccess = (state: RootState) => state.common.collegeDegrees.data;
export const selectEmploymentTypesSuccess = (state: RootState) => state.common.employmentTypes.data;
export const selectIndustryTypesSuccess = (state: RootState) => state.common.industryTypes.data;
export const selectSkillsSuccess = (state: RootState) => state.common.skills.data;
export const selectJobTypesSuccess = (state: RootState) => state.common.jobTypes.data;
