import { Paginator } from "primereact/paginator";

import AdminReportsTable from "../AdminReportsTable";

export default function AdminReportsList({ reports, reportsRequest, handlePageChange, first }: any) {
  return (
    <section>
      <div className="card">
        <AdminReportsTable reports={reports} reportsRequest={reportsRequest} />
        {(reports?.total || 1) > 5 && (
          <Paginator first={first} rows={5} totalRecords={reports?.total} onPageChange={handlePageChange} />
        )}
      </div>
    </section>
  );
}
