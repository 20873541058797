import { useRef } from "react";

import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { useNavigate } from "react-router-dom";

import useTranslation from "hooks/useTranslation";

import Button from "components/Button";

import "./MyPersonalDropdown.css";

interface IMyPersonalDropdownProps {
  toggleSidebar: () => void;
}

  export default function MyPersonalDropdown({ toggleSidebar }: IMyPersonalDropdownProps)  {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const menu = useRef<Menu>(null);

  const navFunc = (link: string) => {
    toggleSidebar();
    navigate(link);
  };

  const mobItems = [
    {
      label: t("menu.myPersonal"),
      items: [
        {
      label: t("menu.contracts"),
      command: () => {
        navFunc("/contracts");
      }
    },
    {
      label: t("menu.projects"),
      command: () => {
        navFunc("/projects");
      }
    },
    {
      label: t("menu.reports"),
      command: () => {
        navFunc("/reports");
      }
    }
  ]
}
  ];

  const items = mobItems.map((section) => section.items).flat();

  return (
    <>
    <div className="lg:flex xl:flex justify-content-center relative hidden">
      <Menu model={items} popup ref={menu} id="menu" popupAlignment="left" />
      <Button
        type="button"
        label={t("menu.myPersonal")}
        link
        className="text-white"
        onClick={(event: React.MouseEvent<HTMLElement>) => menu?.current?.toggle(event)}
      />
    </div>
       <div className="md:flex sm:flex justify-content-between lg:hidden xl:hidden">
       <PanelMenu model={mobItems} className="w-full"/>
     </div>
     </>
  );
}
