import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import produce from "immer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import useArray from "hooks/useArray";
import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { updateUserDetailsData, updateUserDetailsDataInit } from "state/actions/usersAction";
import {
  selectGetUserDetailsDataSuccess,
  selectUpdateUserDetailsDataRequest,
  selectUpdateUserDetailsDataSuccess
} from "state/selectors/usersSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { Checkbox, InputText, InputTextarea, MultiSelect, Select } from "components/FormControls";

import { IUserDetails, IWork } from "types/user";

import { schema } from "./schema";

export interface IWorkToEdit extends IWork {
  index: number;
  ICurrentlyWorkHere?: boolean;
}

interface WorkExperienceModalProps {
  show: boolean;
  closeDialog: () => void;
  work?: null | IWorkToEdit;
}

export default function WorkExperienceModal({ show, closeDialog, work }: WorkExperienceModalProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { uniqBySetWithArrayFrom } = useArray();
  const { months, years, employmentTypes, industryTypes, skills } = useCommon();

  const userDetails = useAppSelector(selectGetUserDetailsDataSuccess) as IUserDetails;
  const updateUserDetailsDataRequest = useAppSelector(selectUpdateUserDetailsDataRequest) as boolean;
  const updateUserDetailsDataSuccess = useAppSelector(selectUpdateUserDetailsDataSuccess) as boolean;

  const { reset, handleSubmit, control, watch, clearErrors, trigger } = useForm<IWorkToEdit>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  const IWorkHere = watch("ICurrentlyWorkHere") as boolean;

  const resetForm = () => {
    reset({
      Title: "",
      EmploymentType: "",
      CompanyName: "",
      Location: "",
      Industry: "",
      StartMonth: 0,
      StartYear: 0,
      EndMonth: 0,
      EndYear: 0,
      Description: "",
      Skills: [],
      ICurrentlyWorkHere: false
    });
  };

  const handleClose = () => {
    closeDialog();
    resetForm();
    clearErrors();
  };

  const onSubmit: SubmitHandler<IWorkToEdit> = (data) => {
    const workToSubmit = {
      Title: data.Title,
      EmploymentType: data.EmploymentType,
      CompanyName: data.CompanyName,
      Location: data.Location,
      Industry: data.Industry,
      StartMonth: data.StartMonth as unknown as number,
      StartYear: data.StartYear as unknown as number,
      EndMonth: IWorkHere ? 0 : (data.EndMonth as unknown as number),
      EndYear: IWorkHere ? 0 : (data.EndYear as unknown as number),
      Description: data.Description,
      Skills: data.Skills
    };

    if (work) {
      const updatedData = produce(userDetails, (draft) => {
        draft.Work[work.index] = workToSubmit;
        draft.Skills = uniqBySetWithArrayFrom([...userDetails.Skills, ...workToSubmit.Skills]);
      });
      dispatch(updateUserDetailsData(updatedData)).then(() => dispatch(updateUserDetailsDataInit()));
    } else {
      const addedData = produce(userDetails, (draft) => {
        draft.Work.push(workToSubmit);
        draft.Skills = uniqBySetWithArrayFrom([...userDetails.Skills, ...workToSubmit.Skills]);
      });
      dispatch(updateUserDetailsData(addedData)).then(() => dispatch(updateUserDetailsDataInit()));
    }
    handleClose();
  };

  useEffect(() => {
    if (work) {
      reset({
        ...work,
        ICurrentlyWorkHere: !work.EndMonth && !work.EndYear
      });
      trigger();
    } else resetForm();
  }, [work]);

  useEffect(() => {
    if (updateUserDetailsDataSuccess) {
      resetForm();
    }
  }, [updateUserDetailsDataSuccess]);

  useEffect(() => {
    trigger();
  }, [IWorkHere]);

  const footerContent = (
    <div>
      <Button label={t("confirmation.cancel")} onClick={closeDialog} outlined disabled={updateUserDetailsDataRequest} />
      <Button
        label={t("confirmation.save")}
        type="submit"
        form="edit-experience-form"
        disabled={updateUserDetailsDataRequest}
      />
    </div>
  );

  return (
    <Dialog
      show={show}
      closeModal={handleClose}
      title={t(work ? "profile.editWorkExperienceTitle" : "profile.addWorkExperienceTitle")}
      footerContent={footerContent}
    >
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="edit-experience-form">
        <Controller
          control={control}
          render={({ field, fieldState }) => <InputText label={t("work.title")} {...field} error={fieldState.error} />}
          name="Title"
        />
        <Controller
          name="EmploymentType"
          render={({ field, fieldState }) => (
            <Select
              label={t("work.employmentType")}
              placeholder={t("work.employmentType")}
              {...field}
              options={employmentTypes}
              error={fieldState.error}
            />
          )}
          control={control}
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("work.company")} {...field} error={fieldState.error} />
          )}
          name="CompanyName"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("work.location")} {...field} error={fieldState.error} />
          )}
          name="Location"
        />
        <Controller
          name="Industry"
          render={({ field, fieldState }) => (
            <Select
              label={t("work.industry")}
              placeholder={t("work.industry")}
              {...field}
              options={industryTypes}
              error={fieldState.error}
            />
          )}
          control={control}
          defaultValue=""
        />
        <Controller
          name="Skills"
          render={({ field, fieldState }) => (
            <MultiSelect display="chip" {...field} options={skills} error={fieldState.error} />
          )}
          control={control}
        />

        <div className="flex justify-between lg:flex-row xl:flex-row flex-column">
          <div className="w-full mr-3">
            <Controller
              name="StartMonth"
              render={({ field, fieldState }) => (
                <Select
                  label={t("common.startMonth")}
                  placeholder={t("common.startMonth")}
                  {...field}
                  options={months}
                  error={fieldState.error}
                />
              )}
              control={control}
              defaultValue={0}
            />
          </div>

          <div className="w-full lg:ml-3 xl:ml-3">
            <Controller
              name="StartYear"
              render={({ field, fieldState }) => (
                <Select
                  label={t("common.startYear")}
                  placeholder={t("common.startYear")}
                  {...field}
                  options={years}
                  error={fieldState.error}
                />
              )}
              control={control}
              defaultValue={0}
            />
          </div>
        </div>
        <div className="">
          <Controller
            name="ICurrentlyWorkHere"
            render={({ field }) => <Checkbox {...field} checked={IWorkHere} label={t("work.iCurrentlyWorkHere")} />}
            control={control}
          />
        </div>
        {!IWorkHere && (
          <div className="flex justify-between lg:flex-row xl:flex-row flex-column">
            <div className="w-full mr-3">
              <Controller
                name="EndMonth"
                render={({ field, fieldState }) => (
                  <Select
                    disabled={IWorkHere}
                    label={t("common.endMonth")}
                    placeholder={t("common.endMonth")}
                    {...field}
                    options={months}
                    error={fieldState.error}
                  />
                )}
                control={control}
                defaultValue={0}
              />
            </div>

            <div className="w-full lg:ml-3 xl:ml-3">
              <Controller
                name="EndYear"
                render={({ field, fieldState }) => (
                  <Select
                    disabled={IWorkHere}
                    label={t("common.endYear")}
                    placeholder={t("common.endYear")}
                    {...field}
                    options={years}
                    error={fieldState.error}
                  />
                )}
                control={control}
                defaultValue={0}
              />
            </div>
          </div>
        )}
        <Controller
          name="Description"
          control={control}
          render={({ field, fieldState }) => (
            <InputTextarea className="mb-1" {...field} label={t("common.description")} error={fieldState.error} />
          )}
        />
      </form>
    </Dialog>
  );
}
