import axios, { AxiosRequestConfig } from "axios";

import { getToken, removeToken } from "utils/token";

const ApiClient = axios.create();
type apiParamsAny = any;

ApiClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken();
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const api = {
  get: async (url: string, params?: apiParamsAny) => {
    try {
      return await ApiClient.get(url, params);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 || error.response?.status === 403) {
          removeToken();
          window.location.href = "/login";
        }

        throw error;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  },
  post: async (url: string, params?: apiParamsAny) => {
    try {
      return await ApiClient.post(url, params);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  },
  put: async (url: string, params?: apiParamsAny) => {
    try {
      return await ApiClient.put(url, params);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  },
  delete: async (url: string, params?: apiParamsAny) => {
    try {
      return await ApiClient.delete(url, params);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        console.log("unexpected error: ", error);
      }
    }
  }
};
