import {
  GET_USER_DATA,
  GET_USER_DETAILS_DATA,
  UPDATE_USER_DATA,
  UPDATE_USER_DETAILS_DATA
} from "state/constants/usersConstants";
import { action, init } from "state/utils/action";

import {
  getUserData as getUserDataService,
  getUserDetailsData as getUserDetailsDataService,
  updateUserData as updateUserDataService,
  updateUserDetailsData as updateUserDetailsDataService
} from "services/UsersService";
import { IUserDetails } from "types/user";

export const getUserData = () => action(`users/${GET_USER_DATA}`, getUserDataService);

export const updateUserData = (params: Record<string, unknown>) =>
  action(`users/${UPDATE_USER_DATA}`, updateUserDataService, params);
export const updateUserDataInit = () => init(`users/${UPDATE_USER_DATA}/init`);

export const getUserDetailsData = () => action(`users/${GET_USER_DETAILS_DATA}`, getUserDetailsDataService);

export const updateUserDetailsData = (params: IUserDetails) =>
  action(`users/${UPDATE_USER_DETAILS_DATA}`, updateUserDetailsDataService, params);
export const updateUserDetailsDataInit = () => init(`users/${UPDATE_USER_DETAILS_DATA}/init`);
