import { forwardRef } from "react";

import { Dropdown as PrimeDropdown, DropdownProps as PrimeDropdownProps } from "primereact/dropdown";

import Label from "components/Label";

import { FieldError } from "types/FieldError";

interface SelectProps extends PrimeDropdownProps {
  error?: FieldError;
  className?: string;
  label?: string;
  name: string;
}

const Select = forwardRef<PrimeDropdown, SelectProps>((props, ref) => {
  const { className = "" } = props;

  return (
    <div className={`input relative flex flex-column text-sm mt-4 ${className}`}>
      {props.label && <Label>{props.label}</Label>}
      <PrimeDropdown className={`${props.error ? "p-invalid" : ""}`} {...props} ref={ref} />
      {props?.error && (
        <small className="absolute p-error" id={props.name}>
          {props?.error?.message}
        </small>
      )}
    </div>
  );
});

Select.displayName = "Select";

Select.defaultProps = {
  placeholder: "Select",
  name: "select"
};

export default Select;
