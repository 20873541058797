import { action, init } from "state/utils/action";

import {
  createReportActivity as createReportActvityService,
  createReport as createReportService,
  deleteReportActivity as deleteReportActivityService,
  deleteReport as deleteReportService,
  getMyBounties as getMyBountiesService,
  getMyContracts as getMyContractsService,
  getMyReportActivities as getMyReportActivitiesService,
  getMyReport as getMyReportService,
  getMyReports as getMyReportsService,
  getMyStakes as getMyStakesService,
  getOrganizationReportActivities as getOrganizationReportActivitiesService,
  getOrganizationReport as getOrganizationReportService,
  getOrganizationReports as getOrganizationReportsService,
  getReportActivities as getReportActivitiesService,
  getReportActivity as getReportActivityService,
  updateOrganizationReport as updateOrganizationReportService,
  updateReportActivity as updateReportActvityService,
  updateReport as updateReportService,
  getVdm as getVdmService,
  getBountiesRequests as getBountiesRequestsService,
  getBountiesHistoryRequests as getBountiesHistoryRequestsService,
  claimBounty as claimBountyService,
  getBountyStatuses as getBountyStatusesService,
  getBountyTypes as getBountyTypesService,
  getMembersList as getMembersListService,
  updateBountyStatus as updateBountyStatusService,
  getAdminReports as getAdminReportsService,
  getAdminReport as getAdminReportService,
  addReportPayment as addReportPaymentService
} from "services/DashboardService";

import {
  CREATE_REPORT,
  CREATE_REPORT_ACTIVITY,
  DELETE_REPORT,
  DELETE_REPORT_ACTIVITY,
  GET_MY_BOUNTIES,
  GET_MY_CONTRACTS,
  GET_MY_REPORT,
  GET_MY_REPORTS,
  GET_MY_REPORT_ACTIVITIES,
  GET_MY_STAKES,
  GET_ORGANIZATION_REPORT,
  GET_ORGANIZATION_REPORTS,
  GET_ORGANIZATION_REPORT_ACTIVITIES,
  GET_REPORT_ACTIVITIES,
  GET_REPORT_ACTIVITY,
  UPDATE_ORGANIZATION_REPORT,
  UPDATE_REPORT,
  UPDATE_REPORT_ACTIVITY,
  GET_VDM,
  GET_BOUNTIES_REQUESTS,
  GET_BOUNTIES_HISTORY_REQUESTS,
  CLAIM_BOUNTY,
  GET_BOUNTY_STATUSES,
  GET_BOUNTY_TYPES,
  GET_MEMBERS_LIST,
  UPDATE_BOUNTY_STATUS,
  GET_ADMIN_REPORTS,
  GET_ADMIN_REPORT,
  ADD_REPORT_PAYMENT
} from "../constants/dashboardConstants";

export const getMyStakes = (params = "") => action(`dashboard/${GET_MY_STAKES}`, getMyStakesService, params);
export const getMyBounties = (params: Record<string, unknown>) =>
  action(`dashboard/${GET_MY_BOUNTIES}`, getMyBountiesService, params);
export const getMyReports = (params: Record<string, unknown>) =>
  action(`dashboard/${GET_MY_REPORTS}`, getMyReportsService, params);
export const getMyReport = (reportId: string) => action(`dashboard/${GET_MY_REPORT}`, getMyReportService, reportId);

export const createReport = (params: Record<string, unknown>) =>
  action(`dashboard/${CREATE_REPORT}`, createReportService, params);
export const createReportInit = () => init(`dashboard/${CREATE_REPORT}/init`);

export const updateReport = (params: Record<string, unknown>) =>
  action(`dashboard/${UPDATE_REPORT}`, updateReportService, params);
export const updateReportInit = () => init(`dashboard/${UPDATE_REPORT}/init`);

export const getMyContracts = (params = "") => action(`dashboard/${GET_MY_CONTRACTS}`, getMyContractsService, params);

export const createReportActivity = (params: Record<string, unknown>) =>
  action(`dashboard/${CREATE_REPORT_ACTIVITY}`, createReportActvityService, params);
export const createReportActivityInit = () => init(`dashboard/${CREATE_REPORT_ACTIVITY}/init`);

export const updateReportActivity = (params: Record<string, unknown>) =>
  action(`dashboard/${UPDATE_REPORT_ACTIVITY}`, updateReportActvityService, params);
export const updateReportActivityInit = () => init(`dashboard/${UPDATE_REPORT_ACTIVITY}/init`);

export const getReportActivities = (reportId: string) =>
  action(`dashboard/${GET_REPORT_ACTIVITIES}`, getReportActivitiesService, reportId);
export const getMyReportActivities = (reportId: string) =>
  action(`dashboard/${GET_MY_REPORT_ACTIVITIES}`, getMyReportActivitiesService, reportId);
export const getReportActivy = (reportActivityId: string) =>
  action(`dashboard/${GET_REPORT_ACTIVITY}`, getReportActivityService, reportActivityId);

export const deleteReport = (params: string) => action(`dashboard/${DELETE_REPORT}`, deleteReportService, params);
export const deleteReportInit = () => init(`dashboard/${DELETE_REPORT}/init`);

export const deleteReportActivity = (params: string) =>
  action(`dashboard/${DELETE_REPORT_ACTIVITY}`, deleteReportActivityService, params);
export const deleteReportActivityInit = () => init(`dashboard/${DELETE_REPORT_ACTIVITY}/init`);

// Organization reports
export const getOrganizationReports = (params: Record<string, unknown>) =>
  action(`dashboard/${GET_ORGANIZATION_REPORTS}`, getOrganizationReportsService, params);
export const getOrganizationReport = (params: Record<string, unknown>) =>
  action(`dashboard/${GET_ORGANIZATION_REPORT}`, getOrganizationReportService, params);
export const updateOrganizationReport = (params: Record<string, unknown>) =>
  action(`dashboard/${UPDATE_ORGANIZATION_REPORT}`, updateOrganizationReportService, params);
export const updateOrganizationReportInit = () => init(`dashboard/${UPDATE_ORGANIZATION_REPORT}/init`);
export const getOrganizationReportActivities = (params: Record<string, unknown>) =>
  action(`dashboard/${GET_ORGANIZATION_REPORT_ACTIVITIES}`, getOrganizationReportActivitiesService, params);

export const getVdm = (hourlyRate: string) => action(`dashboard/${GET_VDM}`, getVdmService, hourlyRate);

export const getBountiesRequests = (params: Record<string, unknown>) =>
  action(`dashboard/${GET_BOUNTIES_REQUESTS}`, getBountiesRequestsService, params);

export const getBountiesHistoryRequests = (params: Record<string, unknown>) =>
  action(`dashboard/${GET_BOUNTIES_HISTORY_REQUESTS}`, getBountiesHistoryRequestsService, params);

export const claimBounty = (params: Record<string, unknown>) =>
  action(`dashboard/${CLAIM_BOUNTY}`, claimBountyService, params);
export const claimBountyInit = () => init(`dashboard/${CLAIM_BOUNTY}/init`);

export const updateBountyStatus = (params: Record<string, unknown>) =>
  action(`dashboard/${UPDATE_BOUNTY_STATUS}`, updateBountyStatusService, params);
export const updateBountyStatusInit = () => init(`dashboard/${UPDATE_BOUNTY_STATUS}/init`);

export const getBountyStatuses = () => action(`dashboard/${GET_BOUNTY_STATUSES}`, getBountyStatusesService);
export const getBountyTypes = () => action(`dashboard/${GET_BOUNTY_TYPES}`, getBountyTypesService);
export const getMembersList = () => action(`dashboard/${GET_MEMBERS_LIST}`, getMembersListService);

export const getAdminReports = (params: Record<string, unknown>) =>
  action(`dashboard/${GET_ADMIN_REPORTS}`, getAdminReportsService, params);
export const getAdminReport = (reportId: string) =>
  action(`dashboard/${GET_ADMIN_REPORT}`, getAdminReportService, reportId);
export const addReportPayment = (params: Record<string, unknown>) =>
  action(`dashboard/${ADD_REPORT_PAYMENT}`, addReportPaymentService, params);
export const addReportPaymentInit = () => init(`dashboard/${ADD_REPORT_PAYMENT}/init`);
