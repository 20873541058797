import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { getMyStakes } from "state/actions/dashboardActions";
import { selectGetMyStakesDataSuccess } from "state/selectors/dashboardSelectors";

import HeaderDataField from "components/HeroDataField";

import { IMyStakes } from "types/dashboard";

const MyStakes = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const myStakes = useAppSelector(selectGetMyStakesDataSuccess) as IMyStakes;

  useEffect(() => {
    dispatch(getMyStakes());
  }, []);

  if (!myStakes) {
    return null;
  }

  return (
    <>
      <div className="h-full flex w-full align-items-center justify-content-center text-white-smooth flex-wrap flex-wrap">
        <div className="flex sm:flex-1 flex-wrap align-content-center mx-auto align-items-center justify-content-center md:gap-6 gap-4 ml-4">
          <HeaderDataField 
            label={t("mystakes.earnedForCommunity")}
            value={Number(myStakes.EarnedForTheCommunity).toFixed(2)}
            information={t("mystakes.earnedForTheCommunityDescription")}
          />
          <HeaderDataField
            label={t("mystakes.earnedInStaking")}
            value={Number(myStakes.EarnedInStaking).toFixed(2)}
            information={t("mystakes.earnedInStakingDescription")}
          />
          <HeaderDataField
            label={t("mystakes.earnedIn") + myStakes.CurrencyShortName}
            value={Number(myStakes.Earned).toFixed(2)}
            information={t("mystakes.myDirectEarningsDescription")}
          />
          <HeaderDataField
            label={t("mystakes.stakingPercentOfTotal")}
            value={Number(myStakes.StakingPercentOfTotal).toFixed(2)}
            information={t("mystakes.stackingInTotalDescription")}
          />
        </div>
      </div>
    </>
  );
};
export default MyStakes;
