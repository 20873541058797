import { STATUS } from "utils/constants";

import { RootState } from "../store";

export const selectGetOrganizationSuccess = (state: RootState) => state.organizations.getOrganization.data;
export const selectGetOrganizationRequest = (state: RootState) =>
  state.organizations.getOrganization.status === STATUS.PENDING;

export const selectUpdateOrganizationSuccess = (state: RootState) => state.organizations.updateOrganization.data;
export const selectUpdateOrganizationRequest = (state: RootState) =>
  state.organizations.updateOrganization.status === STATUS.PENDING;
export const selectUpdateOrganizationFail = (state: RootState) =>
  state.organizations.updateOrganization.status === STATUS.REJECTED;

export const selectCrateOrganizationSuccess = (state: RootState) => state.organizations.createOrganization.data;
export const selectCrateOrganizationRequest = (state: RootState) =>
  state.organizations.createOrganization.status === STATUS.PENDING;
export const selectCrateOrganizationDFail = (state: RootState) =>
  state.organizations.createOrganization.status === STATUS.REJECTED;
