import { useRef } from "react";

import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { selectGetUserDataSuccess } from "state/selectors/usersSelectors";

import Button from "components/Button";

import { UserType, IUserData } from "types/user";

interface IOrganizationDropdownProps {
  toggleSidebar: () => void;
}

export default function OrganizationDropdown({ toggleSidebar }: IOrganizationDropdownProps)  {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector(selectGetUserDataSuccess) as IUserData;

  const menu = useRef<Menu>(null);


  const navFunc = (link: string) => {
    toggleSidebar();
    navigate(link);
  };

  const mobItems = [
    {
      label: t("menu.myOrganization"),
      items: [
        {
      label: t("organization.organizationInfo"),
      command: () => {
        navFunc("/organization");
      }
    },
    {
      label: t("menu.jobs"),
      command: () => {
        navFunc("/organization/jobs");
      }
    },
    {
      label: t("menu.contracts"),
      command: () => {
        navFunc("/organization/contracts");
      }
    },
    {
      label: t("jobApplications"),
      command: () => {
        navFunc("/organization/job-applications");
      }
    },
    {
      label: t("menu.reports"),
      command: () => {
        navFunc("/organization/reports");
      }
    }
  ]
}
  ];

  const items = mobItems.map((section) => section.items).flat();

  return (
    <>
    <div className="lg:flex xl:flex justify-content-center relative hidden">
      <Menu model={items} popup ref={menu} id="menu" popupAlignment="left" />
      <Button
        type="button"
        label={t("menu.myOrganization")}
        link
        className="text-white"
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          user?.UserType === UserType.Client ? menu?.current?.toggle(event) : navigate("/organization")
        }
      />
    </div>
    <div className="md:flex sm:flex justify-content-between lg:hidden xl:hidden">
    {user?.UserType === UserType.Client ? (
                    <PanelMenu model={mobItems} className="w-full" />
                  )
                  : (
                    <div className="p-component p-panelmenu w-full">
                      <div className="p-panelmenu-panel">
                        <div className="p-component p-panelmenu-header">
                          <a className="p-panelmenu-header-link" href="#" onClick={() => navigate("/organization")}>
                            <span className="p-menuitem-text" onClick={toggleSidebar}>{t("menu.myOrganization")}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
      
     </div>
     </>
  );
}
