import { useState } from "react";

import { confirmDialog } from "primereact/confirmdialog";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { exportCsvData, exportExcelData } from "utils/exportFile";
import { formatDate2, getDayMonthYear } from "utils/formatDate";

import { deleteProjectTask } from "state/actions/projectsActions";
import { selectGetMyTasksDataRequest, selectGetMyTasksDataSuccess } from "state/selectors/projectsSelectors";

import ImportModal from "views/Home/components/MyTasks/components/ImportModal";
import HoursModal from "views/Projects/components/HoursModal";

import Button from "components/Button";
import { Column, DataTable } from "components/DataTable";
import { dateBodyTemplate, taskActionsBodyTemplate } from "components/DataTable/DataTableBodyTemplates";
import Loading from "components/Loading";

import { IColumn } from "types/column";
import { IMyTasks, ITask } from "types/task";

import MyTaskModal from "../MyTaskModal";

export default function MyTasksTable() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const myTasksRequest = useAppSelector(selectGetMyTasksDataRequest);
  const myTasks = useAppSelector(selectGetMyTasksDataSuccess) as IMyTasks;

  const [showImportModal, setShowImportModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showHoursModal, setShowHoursModal] = useState<boolean>(false);
  const [taskToEdit, setTaskToEdit] = useState<null | ITask>(null);

  const deleteTask = (taskId: number) => {
    confirmDialog({
      acceptClassName: "p-button-danger",
      header: t("confirmation.areYouSure"),
      message: `${t("confirmation.doYouReallyWantToDeleteTheseRecords")} ${t(
        "confirmation.thisProcessCannotBeUndone"
      )}`,
      draggable: false,
      accept: () => {
        dispatch(deleteProjectTask(taskId));
      }
    });
  };

  const IMyTasksColumns: IColumn[] = [
    { field: "ProjectName", header: t("mytasks.projectName"), sortable: true },
    { field: "PartnerName", header: t("mytasks.partnerName"), sortable: true },
    { field: "Name", header: t("mytasks.taskName"), sortable: true },
    {
      field: "StartDate",
      header: t("common.startDate"),
      body: (item: ITask) => dateBodyTemplate(item?.StartDate),
      sortable: true
    },
    { field: "Duration", header: t("mytasks.duration"), sortable: true },
    {
      field: "Actions",
      body: (item: ITask) =>
        taskActionsBodyTemplate({ item, setShowEditModal, setTaskToEdit, setShowHoursModal, deleteTask }),
      bodyStyle: { textAlign: "center" },
      style: { width: "10rem" }
    }
  ];

  const exportData = myTasks?.mytasks?.map((item: any) => ({
    ProjectName: item.ProjectName,
    Name: item.Name,
    StartDate: getDayMonthYear(item.StartDate),
    Duration: item.Duration
  }));

  if (myTasksRequest) {
    return <Loading />;
  }

  return (
    <div>
      <div className="w-full">
        <div className="relative flex justify-content-end align-items-end w-full gap-0">
          <Button
            icon="pi pi-file-export"
            tooltip={t("mytasks.csvFile")}
            className="text-900"
            text
            outlined
            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
            type="button"
            onClick={() => exportCsvData(exportData, "MyTasks_" + formatDate2(new Date().toString()))}
            disabled={!myTasks}
            ><span className="text-900 p-button-text hidden md:flex ml-2 ">  {t("mytasks.exportCsv")}</span></Button>
          <Button
            icon="pi pi-file-excel"
            text
            className="text-900"
            tooltip={t("mytasks.excelFile")}
            type="button"
            outlined
            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => exportExcelData(exportData, "MyTasks_" + formatDate2(new Date().toString()))}
            disabled={!myTasks}
            ><span className="text-900 p-button-text hidden md:flex ml-2"> {t("mytasks.exportExcel")}</span></Button>
        </div>

        <DataTable value={myTasks?.mytasks}>
          {IMyTasksColumns.map((col) => (
            <Column key={col.field} {...col} />
          ))}
        </DataTable>
      </div>
      <ImportModal
        show={showImportModal}
        closeModal={() => {
          setShowImportModal(false);
        }}
      />
      <MyTaskModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
          if (taskToEdit) {
            setTaskToEdit(null);
          }
        }}
        task={taskToEdit}
      />
      <HoursModal
        show={showHoursModal}
        closeModal={() => {
          setShowHoursModal(false);
          if (taskToEdit) {
            setTaskToEdit(null);
          }
        }}
        task={taskToEdit}
      />
    </div>
  );
}
