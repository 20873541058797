import { Paginator } from "primereact/paginator";

import BountiesTable from "../BountiesTable";

export default function BountiesList({ bounties, bountiesRequest, handlePageChange, first }: any) {
  return (
    <section>
      <div className="card">
        <BountiesTable bounties={bounties} bountiesRequest={bountiesRequest} />
        {(bounties?.total || 1) > 5 && (
          <Paginator first={first} rows={5} totalRecords={bounties?.total} onPageChange={handlePageChange} />
        )}
      </div>
    </section>
  );
}
