import { useEffect, useState } from "react";

import produce from "immer";
import { confirmDialog } from "primereact/confirmdialog";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { updateUserDetailsData, updateUserDetailsDataInit } from "state/actions/usersAction";
import { selectGetUserDetailsDataSuccess, selectUpdateUserDetailsDataSuccess } from "state/selectors/usersSelectors";

import Button from "components/Button";
import Chip from "components/Chip";
import DataField from "components/DataField";
import DataLabel from "components/DataLabel";
import Divider from "components/Divider";
import SectionTitle from "components/SectionTitle";

import { IUserDetails, IWork } from "types/user";

import WorkExperienceModal from "../WorkExperienceModal";
import { IWorkToEdit } from "../WorkExperienceModal/WorkExperienceModal";

export default function WorkExperience() {
  const { t } = useTranslation();
  const { months } = useCommon();
  const dispatch = useAppDispatch();

  const userDetails = useAppSelector(selectGetUserDetailsDataSuccess) as IUserDetails;
  const updateUserDetailsDataSuccess = useAppSelector(selectUpdateUserDetailsDataSuccess) as boolean;

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [workExperienceToEdit, setWorkExperienceToEdit] = useState<null | IWorkToEdit>(null);

  useEffect(() => {
    if (updateUserDetailsDataSuccess) {
      setShowEditModal(false);
      if (workExperienceToEdit) {
        setWorkExperienceToEdit(null);
      }
    }
  }, [updateUserDetailsDataSuccess]);

  if (!userDetails) {
    return null;
  }

  const handleDeleteWorkExperience = (workExperienceIndex: number) => {
    const deletedData = produce(userDetails, (draft) => {
      draft.Work.splice(workExperienceIndex, 1);
    });
    dispatch(updateUserDetailsData(deletedData)).then(() => dispatch(updateUserDetailsDataInit()));
  };

  const deleteWorkExperience = (workExperienceIndex: number) => {
    confirmDialog({
      acceptClassName: "p-button-danger",
      header: t("confirmation.areYouSure"),
      message: `${t("confirmation.doYouReallyWantToDeleteTheseRecords")} ${t(
        "confirmation.thisProcessCannotBeUndone"
      )}`,
      draggable: false,
      accept: () => handleDeleteWorkExperience(workExperienceIndex)
    });
  };

  return (
    <section>
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("profile.workExperience")}
          <Button
              icon="pi pi-plus"
              text
              className="text-900"
              tooltip={t("profile.addWorkExperienceTitle")}
              type="button"
              tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
              onClick={() => setShowEditModal(true)}
            ><span className="text-900 p-button-label hidden md:flex ml-2"> {t("profile.addWorkExperienceTitle")}</span></Button>

        </div>
      </SectionTitle>
      <div className="w-full card">
        {userDetails?.Work?.length > 0 ? (
          userDetails?.Work.map((work: IWork, index: number) => (
            <div key={index} className="relative -mt-5">
              {index > 0 && <Divider />}
              <div className="absolute top-0 right-0 p-3 -mr-5">
                <Button
                  icon="pi pi-file-edit"
                  rounded
                  text
                  className="h-2rem w-2rem text-900 mr-1"
                  tooltip="Edit work experience"
                  tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
                  onClick={() => {
                    setShowEditModal(true);
                    setWorkExperienceToEdit({ index, ...work });
                  }}
                />
                <Button
                  icon="pi pi-trash"
                  rounded
                  text
                  className="h-2rem w-2rem text-900"
                  tooltip="Delete work experience"
                  tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
                  onClick={() => {
                    deleteWorkExperience(index);
                  }}
                />
              </div>

              <div className="flex">
                <div className="pt-6 w-full flex flex-column gap-4">
                  <DataField label={t("work.title")} value={work.Title} />
                  <DataField label={t("work.employmentType")} value={work.EmploymentType} />
                  <DataField label={t("work.company")} value={work.CompanyName} />
                </div>
                <div className="pt-6 w-full flex flex-column gap-4">
                  <div className="w-full flex-column xl:flex-row lg:flex-row flex  row-gap-4 xl:row-gap-0 lg:row-gap-0">
                    <DataField
                      label={t("common.startDate")}
                      value={`${months?.find((month) => month.value === work.StartMonth)?.label}, ${work.StartYear}`}
                    />
                    <DataField
                      label={t("common.endDate")}
                      value={
                        work.EndMonth
                          ? `${months?.find((month) => month.value === work.EndMonth)?.label}, ${work.EndYear}`
                          : "Present"
                      }
                    />
                  </div>
                  <div className="flex flex-column w-full items-start gap-4">
                    <DataField label={t("work.location")} value={work.Location} />
                    <DataField label={t("work.industry")} value={work.Industry} />
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="pt-6 w-full">
                  <DataLabel label={t("profile.tools")} />
                  <div className="pl-0 -mt-6 card flex flex-wrap gap-2">
                    {work.Skills.map((item: string) => (
                      <Chip
                        key={item}
                        label={item}
                        style={{ backgroundColor: "var(--highlight-bg)", color: "var(--primary-500)" }}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="pt-0 w-full mb-8">
                  <DataField textField label={t("common.description")} value={work.Description} />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>{t("profile.noWorkExperience")}</div>
        )}
      </div>
      <WorkExperienceModal
        show={showEditModal}
        closeDialog={() => {
          setShowEditModal(false);
          if (workExperienceToEdit) {
            setWorkExperienceToEdit(null);
          }
        }}
        work={workExperienceToEdit}
      />
    </section>
  );
}
