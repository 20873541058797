import { useEffect } from "react";

import { NavLink, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { getAdminReport } from "state/actions/dashboardActions";
import {
  selectGetAdminReportDataSuccess,
  selectGetAdminReportsRequest,
  selectAddReportPaymentDataSuccess
} from "state/selectors/dashboardSelectors";

import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

import { IMReports } from "types/report";

import ReportDetails from "../ReportDetails";

export default function AdminReport() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const reportId = params.id as string;
  const { t } = useTranslation();
  const { toast } = useToast();

  const reportsRequest = useAppSelector(selectGetAdminReportsRequest) as boolean;
  const report = useAppSelector(selectGetAdminReportDataSuccess) as IMReports;

  const addReportPaymentSuccess = useAppSelector(selectAddReportPaymentDataSuccess);

  useEffect(() => {
    dispatch(getAdminReport(reportId));
  }, [reportId]);

  useEffect(() => {
    if (addReportPaymentSuccess) {
      dispatch(getAdminReport(reportId));
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("myreports.updated"),
        life: 3000
      });
    }
  }, [addReportPaymentSuccess]);

  if (reportsRequest) {
    return (
      <div className="h-full">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <section className="container mx-auto">
        <SectionTitle>
          <div className="relative flex justify-content-between align-items-center w-full">
            <div>
              <NavLink to="/admin-reports" className="text-lg">
                {t("menu.reports")}
              </NavLink>
              &nbsp;/&nbsp;{t("myreports.reportDetails")}
            </div>
            <div></div>
          </div>
        </SectionTitle>
        <div className="card pt-0">{<ReportDetails {...report} />}</div>
      </section>
    </>
  );
}
