import useCommon from "hooks/useCommon";
import useTranslation from "hooks/useTranslation";

import { getDayMonthYear } from "utils/formatDate";

import { Column, DataTable } from "components/DataTable";

import { IColumn } from "types/column";

export default function MyBountiesTable({ bounties }: any) {
  const { t } = useTranslation();

  const { bountyStatuses } = useCommon();

  const filteredBounties = bounties?.mybounties?.map((bountyItem: any) => ({
    Id: bountyItem.Id,
    Name: bountyItem.Name,
    FullName: bountyItem.FullName,
    StartDate: getDayMonthYear(bountyItem.StartDate),
    Status: bountyStatuses.find((s) => s.value.toString() === bountyItem.Status.toString())?.label,
    Created: getDayMonthYear(bountyItem.Created),
    Updated: getDayMonthYear(bountyItem.Updated)
  }));

  const headers: IColumn[] = [
    { field: "Name", header: t("common.name"), sortable: true },
    { field: "FullName", header: t("mybounties.person"), sortable: true },
    { field: "StartDate", header: t("common.startDate"), sortable: true },
    { field: "Status", header: t("common.status"), sortable: true },
    { field: "Created", header: t("common.created"), sortable: true },
    { field: "Updated", header: t("common.updated"), sortable: true }
  ];

  return (
    <DataTable value={filteredBounties}>
      {headers.map((col) => (
        <Column key={col.field} {...col} />
      ))}
    </DataTable>
  );
}
