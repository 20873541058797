import { forwardRef } from "react";

import {
  TabPanel as PrimeTabPanel,
  TabPanelProps as PrimeTabPanelProps,
  TabView as PrimeTabView,
  TabViewProps as PrimeTabViewProps
} from "primereact/tabview";

export const TabView = ({ ...props }: PrimeTabViewProps) => {
  return <PrimeTabView {...props} />;
};

export const TabPanel = forwardRef<PrimeTabPanel, PrimeTabPanelProps>((props, ref) => {
  return (
    <PrimeTabPanel ref={ref} header={props.header}>
      {props.children}
    </PrimeTabPanel>
  );
});

TabPanel.displayName = "TabPanel";
