import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { createJob, updateJob } from "state/actions/jobsActions";
import { selectUpdateJobRequest, selectUpdateJobSuccess } from "state/selectors/jobsSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { Calendar, Checkbox, InputText, InputTextarea, Select } from "components/FormControls";

import { IJob } from "types/job";

import { schema } from "./schema";

interface IJobModalProps {
  show: boolean;
  closeModal: () => void;
  job: null | IJob;
}

const JobModal = ({ show, closeModal, job }: IJobModalProps) => {
  const dispatch = useAppDispatch();
  const updateJobRequest = useAppSelector(selectUpdateJobRequest);
  const updateJobSuccess = useAppSelector(selectUpdateJobSuccess);

  const { t } = useTranslation();
  const { jobTypes } = useCommon();

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  const [endYearError, setEndYearError] = useState(false);
  const StartDate = watch("StartDate", new Date());
  const EndDate = watch("EndDate", new Date());

  useEffect(() => {
    if (job) {
      reset({
        ...job,
        StartDate: new Date(Date.parse(job.StartDate)),
        EndDate: new Date(Date.parse(job.EndDate)),
        Status: job.Status === 1,
        IsStartup: job.IsStartup === 1
      });
    } else {
      resetForm();
    }
  }, [job]);

  useEffect(() => {
    if (updateJobSuccess) {
      resetForm();
    }
  }, [updateJobSuccess]);

  useEffect(() => {
    if (StartDate > EndDate) {
      setEndYearError(true);
      setError("StartDateEndDate", { message: t("formValidators.startDateEndDateError") });
    } else {
      setEndYearError(false);
      clearErrors("StartDateEndDate");
    }
  }, [StartDate, EndDate, errors]);

  const resetForm = () => {
    reset({
      IdOwner: 0,
      Name: "",
      Description: "",
      Web: "",
      Status: 0,
      StartDate: new Date(),
      EndDate: new Date(),
      IsStartup: 0,
      IdType: 0,
      IdOrganization: 0
    });
  };

  const handleClose = () => {
    closeModal();
    resetForm();
    setEndYearError(false);
  };

  const onSubmit = (data: Record<string, IJob>) => {
    if (errors.StartDateEndDate) {
      return;
    }

    const jobData = {
      Id: data.Id ? parseInt(data.Id as unknown as string) : undefined,
      IdOwner: data.IdOwner,
      Name: data.Name,
      Description: data.Description,
      Web: data.Web,
      Status: data.Status ? 1 : 0,
      StartDate: StartDate.toJSON(),
      EndDate: EndDate.toJSON(),
      IsStartup: data.IsStartup ? 1 : 0,
      IdType: data.IdType,
      IdOrganization: data.IdOrganization
    };

    if (data.Id === undefined) {
      dispatch(createJob(jobData));
    } else {
      dispatch(updateJob(jobData));
    }
    handleClose();
  };

  const footerContent = (
    <div>
      <Button
        label={t("confirmation.cancel")}
        onClick={handleClose}
        disabled={updateJobRequest}
        type="button"
        outlined
      />
      <Button label={t("confirmation.save")} className="ml-2.5" type="submit" form="job-details-form" />
    </div>
  );

  return (
    <Dialog
      show={show}
      closeModal={handleClose}
      title={t(job !== null && job.Id !== undefined ? "job.editJob" : "job.addNewJob")}
      footerContent={footerContent}
    >
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="job-details-form">
        <Controller
          control={control}
          render={({ field, fieldState }) => <InputText label={t("job.title")} {...field} error={fieldState.error} />}
          name="Name"
        />
        <Controller
          name="Description"
          control={control}
          render={({ field, fieldState }) => (
            <InputTextarea rows={5} {...field} label={t("common.description")} error={fieldState.error} />
          )}
        />

        <Controller
          control={control}
          render={({ field, fieldState }) => <InputText label={t("common.web")} {...field} error={fieldState.error} />}
          name="Web"
        />

        <Controller
          name="IdType"
          render={({ field, fieldState }) => (
            <Select
              placeholder={t("job.type")}
              label={t("job.type")}
              {...field}
              options={jobTypes}
              error={fieldState.error}
            />
          )}
          control={control}
          defaultValue=""
        />

        <div className="flex flex-column md:flex-row">
          <div className="w-full mr-3">
            <Controller
              name="StartDate"
              control={control}
              render={({ field }) => <Calendar label={t("common.startDate")} {...field} />}
              defaultValue={StartDate}
            />
          </div>
          <div className="w-full mt-4 md:mt-0 md:ml-3">
            <Controller
              name="EndDate"
              control={control}
              render={({ field }) => <Calendar label={t("common.endDate")} {...field} />}
              defaultValue={EndDate}
            />
          </div>
        </div>
        <div className="flex">
          <div className="w-full mr-3">
            <Controller
              control={control}
              render={({ field }) => {
                return <Checkbox {...field} checked={field.value} label={t("job.setToActive")} />;
              }}
              name="Status"
            />
          </div>
          <div className="w-full ml-3 pt-2 md:pt-0">
            <Controller
              control={control}
              render={({ field }) => {
                return <Checkbox {...field} checked={field.value} label={t("common.isStartup")} />;
              }}
              name="IsStartup"
            />
          </div>
        </div>
        {endYearError && (
          <div className="absolute right-7 text-xs mt-2 mb-4 text-error-alert">
            {t("formValidators.startDateEndDateError")}
          </div>
        )}
      </form>
    </Dialog>
  );
};

export default JobModal;
