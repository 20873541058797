import { useState } from "react";

import useTranslation from "hooks/useTranslation";

import SectionTitle from "components/SectionTitle";
import { TabPanel, TabView } from "components/Tabs";

import MyContracts from "./components/MyContracts";
import MyJobsList from "./components/MyJobsList";

export default function MyJobs() {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <section className="container mx-auto px-2 md:px-0">
      <SectionTitle>
        <div>{t("menu.contracts")}</div>
      </SectionTitle>
      <div className="card">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header={t("menu.appliedJobs")}>
            <MyJobsList />
          </TabPanel>
          <TabPanel header={t("job.myContracts")}>
            <MyContracts />
          </TabPanel>
        </TabView>
      </div>
    </section>
  );
}
