export const LOGIN_USER = "loginUser";
export const REGISTER_USER = "registerUser";
export const LOGOUT_USER = "logoutUser";
export const DEACTIVATE_USER = "deactivateUser";
export const ACTIVATE_USER = "activateUser";
export const RESET_PASSWORD = "resetPassword";
export const RESET_USER_PASSWORD = "resetUserPassword";
export const CHANGE_USER_PASSWORD = "changeUserPassword";
export const LIST_INVITED_MEMBERS = "listInvitedMembers";
export const INVITE_MEMBER = "inviteMember";
export const DELETE_INVITE = "deleteInvite";
export const GET_INVITE_DETAILS = "getInviteDetails";
export const REGISTER_INVITE = "registerInvite";
