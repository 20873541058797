import { STATUS } from "utils/constants";

import { RootState } from "../store";

export const selectGetUserDataSuccess = (state: RootState) => state.users.getUserData.data;
export const selectGetUserDataRequest = (state: RootState) => state.users.getUserData.status === STATUS.PENDING;

export const selectUpdateUserDataSuccess = (state: RootState) => state.users.updateUserData.data;
export const selectUpdateUserDataRequest = (state: RootState) => state.users.updateUserData.status === STATUS.PENDING;
export const selectUpdateUserDataFail = (state: RootState) => state.users.updateUserData.status === STATUS.REJECTED;

export const selectGetUserDetailsDataSuccess = (state: RootState) => state.users.getUserDetailsData.data;
export const selectGetUserDetailsDataRequest = (state: RootState) =>
  state.users.getUserDetailsData.status === STATUS.PENDING;

export const selectUpdateUserDetailsDataSuccess = (state: RootState) =>
  state.users.updateUserDetailsData.data && state.users.updateUserDetailsData.status === STATUS.FULFILLED;
export const selectUpdateUserDetailsDataRequest = (state: RootState) =>
  state.users.updateUserDetailsData.status === STATUS.PENDING;
