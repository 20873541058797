import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import produce from "immer";
import { Controller, useForm } from "react-hook-form";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { updateUserDetailsData, updateUserDetailsDataInit } from "state/actions/usersAction";
import {
  selectGetUserDetailsDataSuccess,
  selectUpdateUserDetailsDataRequest,
  selectUpdateUserDetailsDataSuccess
} from "state/selectors/usersSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { InputText, InputTextarea, Select } from "components/FormControls";

import { IEducation, IUserDetails } from "types/user";

import { schema } from "./schema";

export interface IEducationToEdit extends IEducation {
  index: number;
  value?: string;
}

interface EducationModalProps {
  show: boolean;
  closeDialog: () => void;
  education: IEducationToEdit | null;
}

export default function EducationModal({ show, closeDialog, education }: EducationModalProps) {
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector(selectGetUserDetailsDataSuccess) as IUserDetails;
  const updateUserDetailsDataRequest = useAppSelector(selectUpdateUserDetailsDataRequest) as boolean;
  const updateUserDetailsDataSuccess = useAppSelector(selectUpdateUserDetailsDataSuccess) as boolean;

  const [endYearError, setEndYearError] = useState<boolean>(false);

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  const { t } = useTranslation();
  const { months, years, collegeDegrees } = useCommon();

  const StartMonth = watch("StartMonth");
  const StartYear = watch("StartYear");
  const EndMonth = watch("EndMonth");
  const EndYear = watch("EndYear");

  useEffect(() => {
    if (education) {
      reset({
        ...education
      });
    } else {
      resetForm();
    }
  }, [education]);

  const resetForm = () => {
    reset({
      School: "",
      Degree: "",
      FieldOfStudy: "",
      StartMonth: null,
      StartYear: null,
      EndMonth: null,
      EndYear: null,
      Grade: null,
      Activities: "",
      Description: ""
    });
  };

  const onSubmit = (data: Record<string, IEducationToEdit>) => {
    if (errors.StartDateEndDate) {
      return;
    }
    const educationToSubmit = {
      School: data.School as unknown as string,
      Degree: data.Degree as unknown as string,
      FieldOfStudy: data.FieldOfStudy as unknown as string,
      StartMonth: data.StartMonth as unknown as number,
      StartYear: data.StartYear as unknown as number,
      EndMonth: data.EndMonth as unknown as number,
      EndYear: data.EndYear as unknown as number,
      Grade: parseInt(data.Grade as unknown as string),
      Activities: data.Activities as unknown as string,
      Description: data.Description as unknown as string
    };

    if (education) {
      const updatedData = produce(userDetails, (draft) => {
        draft.Education[education.index] = educationToSubmit;
      });
      dispatch(updateUserDetailsData(updatedData)).then(() => dispatch(updateUserDetailsDataInit()));
    } else {
      const addedData = produce(userDetails, (draft) => {
        draft.Education.push(educationToSubmit);
      });
      dispatch(updateUserDetailsData(addedData)).then(() => dispatch(updateUserDetailsDataInit()));
    }
  };

  useEffect(() => {
    if (updateUserDetailsDataSuccess) {
      resetForm();
    }
  }, [updateUserDetailsDataSuccess]);

  useEffect(() => {
    if (StartMonth && StartYear && EndMonth && EndYear) {
      const startDate = +(StartYear + StartMonth);
      const endDate = +(EndYear + EndMonth);
      if (endDate < startDate) {
        setEndYearError(true);
        setError("StartDateEndDate", { message: t("formValidators.startDateEndDateError") });
      } else {
        setEndYearError(false);
        clearErrors("StartDateEndDate");
      }
    }
  }, [StartMonth, StartYear, EndMonth, EndYear, errors]);

  const footerContent = (
    <div>
      <Button label={t("confirmation.cancel")} onClick={closeDialog} outlined disabled={updateUserDetailsDataRequest} />
      <Button
        label={t("confirmation.save")}
        type="submit"
        form="edit-education-form"
        disabled={updateUserDetailsDataRequest}
      />
    </div>
  );

  return (
    <Dialog
      show={show}
      closeModal={closeDialog}
      title={t(education ? "profile.editEducationModalTitle" : "profile.addEducationModalTitle")}
      footerContent={footerContent}
    >
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="edit-education-form">
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("education.school")} {...field} error={fieldState.error} />
          )}
          name="School"
        />
        <Controller
          name="Degree"
          render={({ field, fieldState }) => (
            <Select
              label={t("education.degree")}
              placeholder={t("education.degree")}
              options={collegeDegrees}
              {...field}
              error={fieldState.error}
            />
          )}
          control={control}
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("education.fieldOfStudy")} {...field} error={fieldState.error} />
          )}
          name="FieldOfStudy"
        />
        <div className="flex justify-between lg:flex-row xl:flex-row flex-column">
          <div className="w-full mr-3">
            <Controller
              name="StartMonth"
              render={({ field, fieldState }) => (
                <Select
                  label={t("common.startMonth")}
                  placeholder={t("common.startMonth")}
                  {...field}
                  options={months}
                  error={fieldState.error}
                />
              )}
              control={control}
              defaultValue={StartMonth}
            />
          </div>
          <div className="w-full lg:ml-3 xl:ml-3">
            <Controller
              name="StartYear"
              render={({ field, fieldState }) => (
                <Select  label={t("common.startYear")} placeholder={t("common.startYear")} {...field} options={years} error={fieldState.error} />
              )}
              control={control}
              defaultValue={StartYear}
            />
          </div>
        </div>
        <div className="flex justify-between lg:flex-row xl:flex-row flex-column">
          <div className="w-full mr-3">
            <Controller
              name="EndMonth"
              render={({ field, fieldState }) => (
                <Select
                  label={t("common.endMonth")}
                  placeholder={t("common.endMonth")}
                  {...field}
                  options={months}
                  error={fieldState.error}
                />
              )}
              control={control}
              defaultValue={EndMonth}
            />
          </div>
          <div className="w-full lg:ml-3 xl:ml-3">
            <Controller
              name="EndYear"
              render={({ field, fieldState }) => (
                <Select
                  label={t("common.endYear")}
                  placeholder={t("common.endYear")}
                  {...field}
                  options={years}
                  error={fieldState.error}
                />
              )}
              control={control}
              defaultValue={EndYear}
            />
          </div>
        </div>
        {endYearError && (
          <div className="absolute text-xs bottom-4 text-error-alert">{t("formValidators.startDateEndDateError")}</div>
        )}
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("education.grade")} error={fieldState.error} {...field} type="number" />
          )}
          name="Grade"
        />
        <Controller
          name="Activities"
          control={control}
          render={({ field, fieldState }) => (
            <InputTextarea {...field} label={t("education.activities")} error={fieldState.error} />
          )}
        />
        <Controller
          name="Description"
          control={control}
          render={({ field, fieldState }) => (
            <InputTextarea {...field} label={t("common.description")} error={fieldState.error} />
          )}
        />
      </form>
    </Dialog>
  );
}
