import * as yup from "yup";

import { requiredValidator, requiredValidatorSelect } from "utils/validators";

export const schema = yup.object().shape({
  FirstName: requiredValidator,
  LastName: requiredValidator,
  Headline: requiredValidator,
  IdCountry: requiredValidatorSelect,
  IdPaymentType: requiredValidatorSelect,
  IdCurrency: requiredValidatorSelect
});
