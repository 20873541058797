import { ReactNode } from "react";

import ReactDatePicker from "react-datepicker";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

import "./DatePicker.css";

interface DatePickerProps {
  label: string;
  disabled?: boolean;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<object>> | undefined;
  helper?: string;
  icon?: ReactNode;
  onChange: (date: Date) => void;
  selected: Date;
}

export default function DatePicker({
  label,
  error,
  helper,
  disabled,
  icon,
  onChange,
  selected,
  ...rest
}: DatePickerProps) {
  return (
    <div className="date-picker-wrapper relative group">
      <ReactDatePicker
        className="w-full h-12 rounded-lg bg-transparent border border-gray outline-none px-4 date-picker text-gray1 hover:border-purple focus:border-purple focus:border-2"
        selected={selected}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        disabled={disabled}
        {...rest}
        placeholderText={label}
      />
      {label && selected && (
        <label className={`date-picker-label group-hover:text-purple ${error ? "!text-error-alert" : ""}`}>
          {label}
        </label>
      )}
      {helper && <div className="absolute text-xs -bottom-[18px]">{helper}</div>}
      {error && <div className="absolute text-xs -bottom-4 text-error-alert">{error.message}</div>}
      {icon && <div className="absolute right-2 top-2.5 z-10">{icon}</div>}
    </div>
  );
}
