import { useEffect, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Messages as PrimeMessages } from "primereact/messages";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import {
  activateUserInit,
  changeUserPasswordInit,
  loginUser,
  loginUserInit,
  registerInviteInit
} from "state/actions/authActions";
import {
  selectActivationUserSuccess,
  selectChangeUserPasswordSuccess,
  selectIsUserAuthenticated,
  selectLoginUserFail,
  selectLoginUserRequest,
  selectLoginUserSuccess,
  selectRegisterInviteSuccess
} from "state/selectors/authSelectors";

import Button from "components/Button";
import { InputPassword, InputText } from "components/FormControls";
import Loading from "components/Loading";
import Messages from "components/Messages/Messages";

import { schema } from "./schema";

export default function Login() {
  const msgs = useRef<PrimeMessages>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loginUserFail = useAppSelector(selectLoginUserFail) as Record<string, string>;
  const loginUserRequest = useAppSelector(selectLoginUserRequest);
  const loginUserSuccess = useAppSelector(selectLoginUserSuccess);
  const userIsAuthenticated = useAppSelector(selectIsUserAuthenticated);
  const activationUser = useAppSelector(selectActivationUserSuccess);
  const registerInviteUserSuccess = useAppSelector(selectRegisterInviteSuccess);
  const changeUserPassword = useAppSelector(selectChangeUserPasswordSuccess);
  const [isUserPasswordChanged, setIsUserPasswordChanged] = useState(false);

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onChange"
  });

  const onSubmit = (data: Record<string, string>) => {
    const userData = {
      Email: data.email,
      Password: data.password
    };
    dispatch(loginUser(userData));
  };

  useEffect(() => {
    if (loginUserSuccess || userIsAuthenticated) {
      navigate("/");
    }
    if (changeUserPassword) {
      setIsUserPasswordChanged(true);
      dispatch(changeUserPasswordInit());
    }
    if (activationUser) {
      msgs.current?.show({
        sticky: true,
        severity: "success",
        detail: `${t("register.successActivatedAccount")} ${t("register.youCanNowSignin")}`
      });
      dispatch(activateUserInit());
    }
    if (registerInviteUserSuccess) {
      msgs.current?.show({
        sticky: true,
        severity: "success",
        detail: `${t("register.successRegisterAccount")} ${t("register.youCanNowLogin")}`
      });
      dispatch(registerInviteInit());
    }
  }, [loginUserSuccess, userIsAuthenticated, changeUserPassword, activationUser, registerInviteUserSuccess]);

  useEffect(() => {
    if (loginUserFail) {
      msgs.current?.show({
        sticky: true,
        severity: "error",
        detail: t(`login.${loginUserFail?.message.replace(/\s/g, "").toLowerCase()}`)
      });
      return () => {
        dispatch(loginUserInit());
        msgs.current?.clear();
      };
    }
  }, [loginUserFail]);

  if (loginUserRequest) {
    return <Loading />;
  }

  return (
    <>
      <div className="mt-6 mb-4 text-lg text-center flex flex-column justify-content-center">
        {t("login.alreadyHaveAccount")}&nbsp;
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className="w-full md:w-24rem mx-auto flex flex-column gap-4"
      >
        {isUserPasswordChanged && (
          <div>
            <div className="text-center font-semibold text-green">{t("auth.resetPasswordSuccess")}</div>
            <div className="pb-12 text-center font-semibold text-green">{t("register.youCanNowSignin")}</div>
          </div>
        )}
        <Messages msgs={msgs} />
        <Controller
          control={control}
          render={({ field, fieldState }) => <InputText label="Email" {...field} error={fieldState.error} />}
          name="email"
          rules={{ required: "Email is required." }}
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputPassword label="Password" {...field} error={fieldState.error} feedback={false} />
          )}
          name="password"
          rules={{ required: "Password is required." }}
          defaultValue=""
        />

        <div className="flex flex-column justify-content-center">
          <Button label={t("login.logIn")} disabled={loginUserRequest} className="w-full" />
          <Button
            type="button"
            label={t("login.forgotPassword")}
            disabled={loginUserRequest}
            className="mt-5 m-auto"
            link
            onClick={() => navigate("/forgot-password")}
          />
        </div>
      </form>
    </>
  );
}
