import { Paginator } from "primereact/paginator";

import MyBountiesTable from "../MyBountiesTable";

export default function MyBountiesList({ bounties, handlePageChange, first }: any) {
  return (
    <section>
      <div className="card">
        <MyBountiesTable bounties={bounties} />
        {(bounties?.total || 1) > 5 && (
          <Paginator first={first} rows={5} totalRecords={bounties?.total} onPageChange={handlePageChange} />
        )}
      </div>
    </section>
  );
}
