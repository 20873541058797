import { useEffect, useState } from "react";

import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { searchJobs } from "state/actions/jobsActions";
import {
  selectApplyToJobFail,
  selectApplyToJobSuccess,
  selectSearchJobsDataRequest,
  selectSearchJobsDataSuccess
} from "state/selectors/jobsSelectors";

import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

import { ISearchJobs } from "types/job";

import SearchJobsList from "./components/SearchJobsList";

export default function JobsBoard() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { toast } = useToast();
  const [first, setFirst] = useState<number>(0);

  const searchjobs = useAppSelector(selectSearchJobsDataSuccess) as ISearchJobs;
  const searchjobsRequests = useAppSelector(selectSearchJobsDataRequest) as boolean;
  const applyToJobSuccess = useAppSelector(selectApplyToJobSuccess);
  const applyToJobFail = useAppSelector(selectApplyToJobFail);

  const getData = ({ status, page, perPage }: { status?: string | number; page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };
    if (status) {
      queryParams.status = status;
    }
    dispatch(searchJobs({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    getData({ page: page });
  };

  useEffect(() => {
    if (applyToJobFail) {
      toast?.current?.show({
        severity: "error",
        summary: t("common.error"),
        detail: t("errorBoundary.title"),
        sticky: true
      });
    }
  }, [applyToJobFail]);

  useEffect(() => {
    if (applyToJobSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("job.applied"),
        life: 3000
      });
    }
  }, [applyToJobSuccess]);

  useEffect(() => {
    getData({});
    setFirst(0);
  }, [applyToJobSuccess]);

  if (searchjobsRequests) {
    return (
      <div className="h-full">
        <Loading />
      </div>
    );
  }

  if (searchjobs) {
    return (
      <section className="container mx-auto px-2 md:px-0">
        <SectionTitle>
          <div className="relative flex justify-content-between align-items-center w-full">{t("jobs.searchJobs")}</div>
        </SectionTitle>
        <div className="card">
          <SearchJobsList />
          {searchjobs?.total > 5 && (
            <Paginator first={first} rows={5} totalRecords={searchjobs.total} onPageChange={handlePageChange} />
          )}
        </div>
      </section>
    );
  }
  return null;
}
