import { useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import {
  getCollegeDegrees,
  getCountries,
  getCurrencies,
  getEmploymentTypes,
  getIndustryTypes,
  getJobTypes,
  getPaymentTypes,
  getSkills
} from "state/actions/commonActions";
import { getBountyStatuses, getBountyTypes, getMembersList } from "state/actions/dashboardActions";
import { getProjectPositions } from "state/actions/projectsActions";
import {
  selectCollegeDegreesSuccess,
  selectCountriesSuccess,
  selectCurrenciesSuccess,
  selectEmploymentTypesSuccess,
  selectIndustryTypesSuccess,
  selectJobTypesSuccess,
  selectPaymentTypesSuccess,
  selectSkillsSuccess
} from "state/selectors/commonSelectors";
import {
  selectBountyTypesSuccess,
  selectBountyStatusesSuccess,
  selectMembersListSuccess
} from "state/selectors/dashboardSelectors";
import { selectGetAllContractsSuccess } from "state/selectors/jobsSelectors";
import { selectProjectPositionsSuccess } from "state/selectors/projectsSelectors";

import { IMyContract, IMyContracts } from "types/contract";

interface IBountyStatus {
  StatusName: string;
  Id: number;
}

interface IBountyTypes {
  Name: string;
  Id: number;
}

interface IMembers {
  FullName: string;
  Id: number;
}

interface ICountry {
  Name: string;
  Id: number;
}

interface IJobTypes {
  Name: string;
  Id: number;
}

interface IPaymentType {
  Name: string;
  Id: number;
}

interface ICurrency {
  Name: string;
  ShortName: string;
  Id: number;
}

interface IPosition {
  Name: string;
  Id: number;
}

export default function useCommon() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const countriesSuccess = useAppSelector(selectCountriesSuccess) as ICountry[];
  const paymentTypesSuccess = useAppSelector(selectPaymentTypesSuccess) as IPaymentType[];
  const currenciesSuccess = useAppSelector(selectCurrenciesSuccess) as ICurrency[];
  const employmentTypesSuccess = useAppSelector(selectEmploymentTypesSuccess) as string[];
  const collegeDegreesSuccess = useAppSelector(selectCollegeDegreesSuccess) as string[];
  const industryTypesSuccess = useAppSelector(selectIndustryTypesSuccess) as string[];
  const skillsSuccess = useAppSelector(selectSkillsSuccess) as string[];
  const jobTypesSuccess = useAppSelector(selectJobTypesSuccess) as IJobTypes[];
  const projectPositionsSuccess = useAppSelector(selectProjectPositionsSuccess) as IPosition[];
  const myContractsSuccess = useAppSelector(selectGetAllContractsSuccess) as IMyContracts;
  const bountyStatusesSuccess = useAppSelector(selectBountyStatusesSuccess) as IBountyStatus[];
  const bountyTypesSuccess = useAppSelector(selectBountyTypesSuccess) as IBountyTypes[];
  const membersListSuccess = useAppSelector(selectMembersListSuccess) as IMembers[];

  useEffect(() => {
    if (!currenciesSuccess) {
      dispatch(getCurrencies());
    }
  }, []);

  useEffect(() => {
    if (!countriesSuccess) {
      dispatch(getCountries());
    }
  }, []);

  useEffect(() => {
    if (!paymentTypesSuccess) {
      dispatch(getPaymentTypes());
    }
  }, []);

  useEffect(() => {
    if (!collegeDegreesSuccess) {
      dispatch(getCollegeDegrees());
    }
  }, []);

  useEffect(() => {
    if (!employmentTypesSuccess) {
      dispatch(getEmploymentTypes());
    }
  }, []);

  useEffect(() => {
    if (!skillsSuccess) {
      dispatch(getSkills());
    }
  }, []);

  useEffect(() => {
    if (!industryTypesSuccess) {
      dispatch(getIndustryTypes());
    }
  }, []);

  useEffect(() => {
    if (!jobTypesSuccess) {
      dispatch(getJobTypes());
    }
  }, []);

  useEffect(() => {
    if (!projectPositionsSuccess) {
      dispatch(getProjectPositions());
    }
  }, []);

  useEffect(() => {
    if (!bountyStatusesSuccess) {
      dispatch(getBountyStatuses());
    }
  }, []);

  useEffect(() => {
    if (!bountyTypesSuccess) {
      dispatch(getBountyTypes());
    }
  }, []);

  useEffect(() => {
    if (!membersListSuccess) {
      dispatch(getMembersList());
    }
  }, []);

  const countries = useMemo(() => {
    return countriesSuccess?.map((item) => ({ label: item.Name, value: item.Id }));
  }, [countriesSuccess]);

  const paymentTypes = useMemo(() => {
    return paymentTypesSuccess?.map((item) => ({ label: item.Name, value: item.Id }));
  }, [paymentTypesSuccess]);

  const currencies = useMemo(() => {
    return currenciesSuccess?.map((item) => ({ label: item.Name, value: item.Id }));
  }, [currenciesSuccess]);

  const currenciesShortNames = useMemo(() => {
    return currenciesSuccess?.map((item) => ({ label: item.ShortName, value: item.Id }));
  }, [currenciesSuccess]);

  const collegeDegrees = useMemo(() => {
    return collegeDegreesSuccess?.map((item: string) => ({ label: item, value: item }));
  }, [collegeDegreesSuccess]);

  const employmentTypes = useMemo(() => {
    return employmentTypesSuccess?.map((item: string) => ({ label: item, value: item }));
  }, [employmentTypesSuccess]);

  const industryTypes = useMemo(() => {
    return industryTypesSuccess?.map((item: string) => ({ label: item, value: item }));
  }, [industryTypesSuccess]);

  const skills = useMemo(() => {
    return skillsSuccess?.map((item: string) => ({ label: item, value: item }));
  }, [skillsSuccess]);

  const jobTypes = useMemo(() => {
    return jobTypesSuccess?.map((item) => ({ label: item.Name, value: item.Id }));
  }, [jobTypesSuccess]);

  const projectPositions = useMemo(() => {
    return projectPositionsSuccess?.map((item) => ({ label: item.Name, value: item.Id }));
  }, [projectPositionsSuccess]);

  const myContracts = useMemo(() => {
    return myContractsSuccess?.contracts.map((item: IMyContract) => ({
      label: item.Title,
      value: parseInt(item.Id as unknown as string)
    }));
  }, [projectPositionsSuccess]);

  const bountyStatuses = useMemo(() => {
    return bountyStatusesSuccess?.map((item: IBountyStatus) => ({
      label: item.StatusName,
      value: parseInt(item.Id as unknown as string)
    }));
  }, [bountyStatusesSuccess]);

  const bountyTypes = useMemo(() => {
    return bountyTypesSuccess?.map((item: IBountyTypes) => ({
      label: item.Name,
      value: parseInt(item.Id as unknown as string)
    }));
  }, [bountyTypesSuccess]);

  const membersList = useMemo(() => {
    return membersListSuccess?.map((item: IMembers) => ({
      label: item.FullName,
      value: parseInt(item.Id as unknown as string)
    }));
  }, [membersListSuccess]);

  const months = [
    { value: 1, label: t("months.january") },
    { value: 2, label: t("months.february") },
    { value: 3, label: t("months.march") },
    { value: 4, label: t("months.april") },
    { value: 5, label: t("months.may") },
    { value: 6, label: t("months.june") },
    { value: 7, label: t("months.july") },
    { value: 8, label: t("months.august") },
    { value: 9, label: t("months.september") },
    { value: 10, label: t("months.october") },
    { value: 11, label: t("months.november") },
    { value: 12, label: t("months.december") }
  ];

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    let startYear = 1950;
    const y = [];
    while (startYear <= currentYear) {
      const newYear = startYear++;
      y.push({ label: newYear, value: newYear });
    }
    y.reverse();
    return y;
  }, []);

  const projectStatus = [
    { value: 1, label: t("common.active") },
    { value: 0, label: t("common.inactive") }
  ];

  const taskStatus = [
    { value: 2, label: t("common.approved") },
    { value: 1, label: t("common.active") },
    { value: 0, label: t("common.notActive") }
  ];

  const reportStatus = [
    { value: 0, label: t("common.workInProgress") },
    { value: 1, label: t("common.workInProgress") },
    { value: 2, label: t("common.inreview") },
    { value: 3, label: t("common.approved") },
    { value: 4, label: t("common.available") }
  ];

  const applicantStatus = [
    { value: 0, label: t("common.canceled") },
    { value: 1, label: t("common.applied") },
    { value: 2, label: t("common.inreview") },
    { value: 3, label: t("common.accepted") },
    { value: 4, label: t("common.rejected") }
  ];

  const bountyStatus = [
    { value: 0, label: t("common.canceled") },
    { value: 1, label: t("common.applied") },
    { value: 2, label: t("common.inreview") },
    { value: 3, label: t("common.accepted") },
    { value: 4, label: t("common.rejected") }
  ];

  const inviteStatus = [
    { value: 2, label: t("common.accepted") },
    { value: 1, label: t("common.pending") }
  ];

  const inviteSeniority = [
     t("common.entryLevel") ,
     t("common.junior"),
     t("common.associate"),
     t("common.midLevel"),
     t("common.senior"),
     t("common.lead")
  ];

  return {
    countries,
    paymentTypes,
    currencies,
    currenciesShortNames,
    months,
    years,
    collegeDegrees,
    employmentTypes,
    industryTypes,
    skills,
    projectStatus,
    taskStatus,
    reportStatus,
    jobTypes,
    projectPositions,
    myContracts,
    applicantStatus,
    bountyStatuses,
    bountyTypes,
    membersList,
    inviteStatus,
    inviteSeniority
  };
}
