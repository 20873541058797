import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { claimBounty, claimBountyInit } from "state/actions/dashboardActions";
import { selectClaimBountyDataRequest, selectClaimBountyDataSuccess } from "state/selectors/dashboardSelectors";
import { selectGetUserDataSuccess } from "state/selectors/usersSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { Calendar, Select } from "components/FormControls";

import { IClaimBounty } from "types/bounty";
import { IUserData } from "types/user";

import { schema } from "./schema";

interface IClaimBountyModalProps {
  show: boolean;
  closeModal: () => void;
}

export default function ClaimBountyModal({ show, closeModal }: IClaimBountyModalProps) {
  const dispatch = useAppDispatch();
  const claimBountyRequest = useAppSelector(selectClaimBountyDataRequest);
  const claimBountySuccess = useAppSelector(selectClaimBountyDataSuccess);

  const user = useAppSelector(selectGetUserDataSuccess) as IUserData;

  const { bountyTypes, membersList } = useCommon();

  const { t } = useTranslation();

  const { reset, handleSubmit, control, watch, clearErrors } = useForm<IClaimBounty>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  useEffect(() => {
    if (claimBountySuccess) {
      resetForm();
    }
  }, [claimBountySuccess]);

  const resetForm = () => {
    reset({
      IdMember: 0,
      IdType: 0
    });
  };

  const handleClose = () => {
    closeModal();
    resetForm();
    clearErrors();
  };

  const StartDate = watch("StartDate", new Date());

  const onSubmit: SubmitHandler<IClaimBounty> = (data) => {
    const claimBountyData = {
      ...data,
      IdMember: parseInt(data.IdMember as unknown as string),
      IdType: parseInt(data.IdType as unknown as string),
      StartDate: StartDate.toJSON()
    };

    dispatch(claimBounty(claimBountyData)).then(() => dispatch(claimBountyInit()));

    handleClose();
  };

  const footerContent = (
    <div>
      <Button label={t("confirmation.cancel")} onClick={closeModal} outlined disabled={claimBountyRequest} />
      <Button label={t("confirmation.save")} type="submit" form="bounty-modal-form" disabled={claimBountyRequest} />
    </div>
  );

  return (
    <Dialog show={show} closeModal={closeModal} title={t("mybounties.claimnewbounty")} footerContent={footerContent}>
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="bounty-modal-form">
        <>
          <Controller
            name="IdMember"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                placeholder={t("bounty.communityName")}
                {...field}
                options={membersList.filter((member) => member.value !== user.Id)}
                error={fieldState.error}
              />
            )}
          />

          <Controller
            name="IdType"
            control={control}
            render={({ field, fieldState }) => (
              <Select placeholder={t("bounty.bountyType")} {...field} options={bountyTypes} error={fieldState.error} />
            )}
          />

          <div className="flex justify-between">
            <div className="w-full">
              <Controller
                name="StartDate"
                control={control}
                render={({ field, fieldState }) => (
                  <Calendar label={t("common.startDate")} {...field} error={fieldState.error} />
                )}
              />
            </div>
          </div>
        </>
      </form>
    </Dialog>
  );
}
