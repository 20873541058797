import Label from "components/Label";

interface DataFieldProps {
  label: string;
  value: string | number;
  textField?: boolean;
  status?: string;
  className?: string;
}

export default function DataField({ label, value, className = "" }: DataFieldProps) {
  if (!value) {
    return null;
  }
  return (
    <div className={`input relative flex flex-column mt-4 w-full ${className}`}>
      <Label>{label}</Label>
      {value}
    </div>
  );
}
