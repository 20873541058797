import { useNavigate } from "react-router-dom";

import useCommon from "hooks/useCommon";
import useTranslation from "hooks/useTranslation";

import { getDayMonthYear } from "utils/formatDate";

import { Column, DataTable } from "components/DataTable";
import Loading from "components/Loading";

import { IColumn } from "types/column";
import { IReport } from "types/report";

export default function AdminReportsTable({ reports, reportsRequest, isOrganization }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { reportStatus } = useCommon();

  const filteredReports = reports?.myreports?.map((reportItem: any) => ({
    ReportName: reportItem.ReportName,
    PartnerName: reportItem.PartnerName,
    ProjectName: reportItem.ContractTitle,
    ReportDate: getDayMonthYear(reportItem.ReportDate),
    Duration: reportItem.Duration,
    Status: reportStatus.find((s) => s.value.toString() === reportItem.Status.toString())?.label,
    Earnings: reportItem.Earnings + " " + reportItem.CurrencyShortName,
    IdReport: reportItem.IdReport
  }));

  const headers: IColumn[] = [
    { field: "ReportName", header: t("myreports.reportName") },
    { field: "PartnerName", header: t("mytasks.partnerName") },
    { field: "ProjectName", header: t("contract.title") },
    { field: "ReportDate", header: t("myreports.creationDate") },
    { field: "Duration", header: t("mytasks.hours") },
    { field: "Status", header: t("mytasks.status") },
    { field: "Earnings", header: t("mytasks.earnings") }
  ];

  const viewReportBodyTemplate = (rowData: IReport) => {
    return (
      <div
        className="pi pi-arrow-right"
        style={{ fontSize: "1.2rem", color: "var(--primary-color)", cursor: "pointer" }}
        onClick={() => navigate(`/admin-reports/${rowData.IdReport}`)}
      />
    );
  };

  if (reportsRequest) {
    return <Loading />;
  }

  return (
    <DataTable value={filteredReports}>
      {headers.map((col) => (
        <Column key={col.field} {...col} />
      ))}
      <Column body={viewReportBodyTemplate} />
    </DataTable>
  );
}
