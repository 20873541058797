import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import produce from "immer";
import { Controller, useForm } from "react-hook-form";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { updateUserDetailsData, updateUserDetailsDataInit } from "state/actions/usersAction";
import { selectGetUserDetailsDataSuccess, selectUpdateUserDetailsDataRequest } from "state/selectors/usersSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import MultiSelect from "components/FormControls/MultiSelect";

import { IUserDetails } from "types/user";

import { schema } from "./schema";

interface SkillsModalProps {
  show: boolean;
  closeModal: () => void;
  mySkills?: string[];
}

export default function SkillsModal({ show, closeModal, mySkills }: SkillsModalProps) {
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector(selectGetUserDetailsDataSuccess) as IUserDetails;
  const updateUserDetailsDataRequest = useAppSelector(selectUpdateUserDetailsDataRequest) as boolean;

  const { reset, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  const { t } = useTranslation();
  const { skills } = useCommon();

  const onSubmit = (data: Record<string, any>) => {
    const skills = data.Skills.map((item: { label: string; value: string }) => item);

    const updatedData = produce(userDetails, (draft) => {
      draft.Skills = skills;
    });
    dispatch(updateUserDetailsData(updatedData)).then(() => dispatch(updateUserDetailsDataInit()));
    closeModal();
  };

  useEffect(() => {
    if (mySkills) {
      reset({
        Skills: mySkills
      });
    } else reset({ Skills: [] });
  }, [mySkills]);

  const footerContent = (
    <div>
      <Button
        type="button"
        label={t("confirmation.cancel")}
        onClick={closeModal}
        outlined
        disabled={updateUserDetailsDataRequest}
      />
      <Button
        label={t("confirmation.save")}
        type="submit"
        form="edit-skills-form"
        disabled={updateUserDetailsDataRequest}
      />
    </div>
  );

  return (
    <Dialog show={show} closeModal={closeModal} title={t("profile.skills")} footerContent={footerContent}>
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="edit-skills-form">
        <Controller
          name="Skills"
          render={({ field, fieldState }) => (
            <MultiSelect display="chip" {...field} options={skills} error={fieldState.error} />
          )}
          control={control}
        />
      </form>
    </Dialog>
  );
}
