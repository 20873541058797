import { useEffect, useRef } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Messages as PrimeMessages } from "primereact/messages";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { registerUser, registerUserInit } from "state/actions/authActions";
import {
  selectRegisterUserFail,
  selectRegisterUserRequest,
  selectRegisterUserSuccess
} from "state/selectors/authSelectors";

import Button from "components/Button";
import { Checkbox, InputPassword, InputText } from "components/FormControls";
import Loading from "components/Loading";
import Messages from "components/Messages/Messages";

import { schema } from "./schema";

export default function Register() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const msgs = useRef<PrimeMessages>(null);

  const registerUserSuccess = useAppSelector(selectRegisterUserSuccess);
  const registerUserFail = useAppSelector(selectRegisterUserFail) as Record<string, string>;
  const registerUserRequest = useAppSelector(selectRegisterUserRequest) as boolean;

  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const onSubmit = (data: Record<string, string | boolean>) => {
    msgs.current?.clear();
    const passwordNotEqual = data.Password !== data.ConfirmPassword;
    if (!data.PrivacyCompliance) {
      return msgs.current?.show({
        sticky: true,
        severity: "error",
        detail: t("register.complianceError")
      });
    } else {
      if (!passwordNotEqual) {
        const user = {
          Email: data.Email,
          Password: data.Password,
          FirstName: data.FirstName,
          LastName: data.LastName,
          PrivacyCompliance: 1
        };
        dispatch(registerUser(user));
      } else {
        msgs.current?.show({
          sticky: true,
          severity: "error",
          detail: t("auth.passwordsDidntMatch")
        });
        setError("Password", { message: t("auth.passwordsDidntMatch") });
        setError("ConfirmPassword", { message: t("auth.passwordsDidntMatch") });
      }
    }
  };

  useEffect(() => {
    return () => {
      if (registerUserSuccess || registerUserFail) {
        dispatch(registerUserInit());
      }
    };
  }, [registerUserSuccess, registerUserFail]);

  if (registerUserRequest) {
    return <Loading />;
  }

  if (registerUserSuccess) {
    return (
      <>
        <div>{t("register.successRequestMessage")}</div>
        <div>{t("register.successRequestEmailMessage")}</div>
        <div className="pt-6">
          <Button label={t("form.goToLogin")} onClick={() => navigate("/login")} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="text-4xl line-height-2 text-center font-light pre">{t("register.title")}</div>
      <div className="mt-6 text-dark-gray text-lg text-center flex flex-column justify-content-center">
        {t("auth.alreadyHaveAccount")}&nbsp;
        <Button label={t("auth.signIn")} className="m-auto" link onClick={() => navigate("/login")} />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className="w-full md:w-24rem mx-auto flex flex-column gap-4"
      >
        <Messages msgs={msgs} />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("form.lastName")} {...field} error={fieldState.error} />
          )}
          name="LastName"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("form.firstName")} {...field} error={fieldState.error} />
          )}
          name="FirstName"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => <InputText label={t("form.email")} {...field} error={fieldState.error} />}
          name="Email"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputPassword label={t("form.password")} {...field} error={fieldState.error} />
          )}
          name="Password"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputPassword label={t("form.confirmPassword")} {...field} error={fieldState.error} />
          )}
          name="ConfirmPassword"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field }) => <Checkbox {...field} checked={field.value} label={t("register.privacyPolicy")} />}
          name="PrivacyCompliance"
          defaultValue={false}
        />
        <Button label={t("form.register")} />
      </form>
    </>
  );
}
