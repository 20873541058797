import { createRoot } from "react-dom/client";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";

import { store } from "state/store";

import { langs } from "langs";
import Routes from "routes";

import reportWebVitals from "./reportWebVitals";

//theme
import "primereact/resources/themes/lara-light-blue/theme.css";

//core
import "primereact/resources/primereact.min.css";

// icons
import "primeicons/primeicons.css";

// flex
import "primeflex/primeflex.css";

import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container as Element);

// const locale = navigator.language.substring(0, 2);

root.render(
  <IntlProvider messages={langs["en"]} locale="en" defaultLocale="en">
    <Provider store={store}>
      <Routes />
    </Provider>
  </IntlProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
