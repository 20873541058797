import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FileUpload } from "primereact/fileupload";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { getProjects } from "state/actions/projectsActions";
import { selectGetProjectsDataSuccess, selectGetProjectInfoSuccess } from "state/selectors/projectsSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { Select } from "components/FormControls";

import { IProject, IProjects } from "types/project";
import { ITask, ITasks } from "types/task";

import { schema } from "./schema";

interface IProjectTaskModalProps {
  show: boolean;
  closeModal: () => void;
  task: null | ITask;
}

export default function ImportTasksModal({ show, closeModal, task }: IProjectTaskModalProps) {
  const dispatch = useAppDispatch();
  const myProjectsSelect = useAppSelector(selectGetProjectsDataSuccess) as IProjects;
  const myProjects = myProjectsSelect?.projects.map((pro) => ({ label: pro.Name, value: pro.Id }));
  const project = useAppSelector(selectGetProjectInfoSuccess) as IProject;
  const { toast } = useToast();

  const { t } = useTranslation();

  const { reset, handleSubmit, control } = useForm<ITask>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  const [redirectToImportTasks, setRedirectToImportTasks] = useState<boolean>(false);
  const [tasksToImport, setTasksToImport] = useState<null | ITask[]>(null);
  const [showImport, setShowImport] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<number>(-1);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProjects(""));
    resetForm();
  }, []);

  useEffect(() => {
    if (redirectToImportTasks) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("tasks.pleaseConfirmImport"),
        life: 5000
      });
      navigate("/import-tasks", { state: tasksToImport });
    }
  }, [redirectToImportTasks]);

  const resetForm = () => {
    reset({
      IdProject: undefined
    });
  };

  const handleClose = () => {
    closeModal();
    resetForm();
    setShowImport(false);
  };

  const onProjectSelect = (e: any) => {
    if (e.target.value >= 0) {
      setProjectId(e.target.value);
      setShowImport(true);
    }
  };

  const onSubmit: SubmitHandler<ITask> = () => {
    handleClose();
  };

  const uploadFileHandler = ({ files }: any) => {
    const iTasksToImport: ITasks = {
      tasks: [],
      total: 0
    };

    const [file] = files;
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const fileContent = e.target?.result;

      const lines: string[] | undefined = fileContent?.toString().split("\n");
      lines?.map((line, lineNum) => {
        // prettier-ignore
        const lineStr = line.replaceAll("\"", "");

        const taskData = lineStr.split(",");

        const task: ITask = {
          Id: lineNum + 1,
          IdProject: projectId,
          Name: taskData[0],
          Duration: Number(taskData[2]),
          StartDate: new Date(Date.parse(taskData[1])),
          EndDate: new Date()
        };

        iTasksToImport.tasks.push(task);
      });

      setTasksToImport(iTasksToImport.tasks);

      setRedirectToImportTasks(true);
    };
    fileReader.readAsText(file);
  };

  const footerContent = (
    <div>
      <Button label={t("confirmation.cancel")} onClick={handleClose} outlined />
    </div>
  );

  return (
    <Dialog show={show} closeModal={handleClose} title={t("tasks.importTasks")} footerContent={footerContent}>
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="task-modal-form">
        {!task && !project && (
          <div>
            <Controller
              name="IdProject"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  placeholder={t("project.projectName")}
                  {...field}
                  options={myProjects}
                  error={fieldState.error}
                  // onClick={onProjectSelect}
                  onChange={(e) => {
                    onProjectSelect(e);
                    return field.onChange(e);
                  }}
                />
              )}
            />

            <div className={`${showImport ? "my-4" : "hidden my-4"}`}>
              <FileUpload
                mode="advanced"
                auto={true}
                name="import"
                uploadHandler={uploadFileHandler}
                accept=".csv"
                customUpload={true}
                maxFileSize={1000000}
                chooseLabel={t("tasks.uploadCsvFile")}
                emptyTemplate={
                  <div>
                    <p className="m-0">{t("tasks.uploadCsvFileInfo")}</p>
                    <p className="m-0">{t("tasks.uploadCsvFileFormat")}</p>
                    <p className="m-0">{t("tasks.forExample")}</p>
                    <p className="m-0 font-bold">{t("tasks.uploadCsvFileFormatExample")}</p>
                  </div>
                }
              />
            </div>
          </div>
        )}
      </form>
    </Dialog>
  );
}
