import useTranslation from "hooks/useTranslation";

import { getDayMonthYear } from "utils/formatDate";

import { Column, DataTable } from "components/DataTable";
import Loading from "components/Loading";

import { IColumn } from "types/column";
import { IActivityReports } from "types/report";

interface ReportActivityTableProps {
  reportActivityRequest: boolean;
  reportActivitySuccess: IActivityReports;
}

export default function ReportActivityTable({
  reportActivityRequest,
  reportActivitySuccess
}: ReportActivityTableProps) {
  const { t } = useTranslation();
  const myReportsActivities = reportActivitySuccess?.report_activities?.map((reportItem) => ({
    ProjectName: reportItem.ProjectName,
    Description: reportItem.TaskDescription,
    TaskName: reportItem.TaskName,
    Hours: reportItem.Hours,
    ActivityDate: getDayMonthYear(reportItem.ActivityDate),
    IdReport: reportItem.IdReport,
    Id: reportItem.Id
  }));

  const headers: IColumn[] = [
    { field: "ProjectName", header: t("mytasks.projectName") },
    { field: "Description", header: t("common.description") },
    { field: "TaskName", header: t("mytasks.taskName") },
    { field: "Hours", header: t("mytasks.hours") },
    { field: "ActivityDate", header: t("common.startDate") }
  ];

  if (reportActivityRequest) {
    return <Loading />;
  }

  return (
    <DataTable value={myReportsActivities} tableStyle={{ minWidth: "50rem" }}>
      {headers.map((col) => (
        <Column key={col.field} {...col} sortable />
      ))}
    </DataTable>
  );
}
