import { forwardRef } from "react";

import { Toast as PrimeToast, ToastProps as PrimeToastProps } from "primereact/toast";

export const Toast = forwardRef<PrimeToast, PrimeToastProps>((props, ref) => {
  return <PrimeToast ref={ref} {...props} />;
});

Toast.displayName = "Toast";

export default Toast;
