import { combineReducers } from "@reduxjs/toolkit";

import {
  GET_COLLEGE_DEGREES,
  GET_COUNTRIES,
  GET_CURRENCIES,
  GET_EMPLOYMENT_TYPES,
  GET_INDUSTRY_TYPES,
  GET_PAYMENT_TYPES,
  GET_ROLES,
  GET_SKILLS,
  GET_JOB_TYPES
} from "state/constants/commonConstants";
import reducer from "state/utils/reducer";

export default combineReducers({
  countries: reducer(`common/${GET_COUNTRIES}`),
  currencies: reducer(`common/${GET_CURRENCIES}`),
  employmentTypes: reducer(`common/${GET_EMPLOYMENT_TYPES}`),
  industryTypes: reducer(`common/${GET_INDUSTRY_TYPES}`),
  paymentTypes: reducer(`common/${GET_PAYMENT_TYPES}`),
  roles: reducer(`common/${GET_ROLES}`),
  skills: reducer(`common/${GET_SKILLS}`),
  collegeDegrees: reducer(`common/${GET_COLLEGE_DEGREES}`),
  jobTypes: reducer(`common/${GET_JOB_TYPES}`)
});
