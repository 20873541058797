import { createAction } from "@reduxjs/toolkit";

import { saveToken } from "utils/token";

import {
  ACTIVATE_USER,
  DEACTIVATE_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  RESET_PASSWORD,
  RESET_USER_PASSWORD,
  CHANGE_USER_PASSWORD,
  LIST_INVITED_MEMBERS,
  INVITE_MEMBER,
  DELETE_INVITE,
  GET_INVITE_DETAILS,
  REGISTER_INVITE
} from "state/constants/authConstants";
import { action, init } from "state/utils/action";

import {
  activateUser as activateUserService,
  deactivateUser as deactivateUserService,
  loginUser as loginUserService,
  registerUser as registerUserService,
  resetPassword as resetPasswordUserService,
  resetUserPassword as resetUserPasswordService,
  changePassword as changeUserPasswordService,
  listInvitedMember as listInvitedMemberService,
  inviteMember as inviteMemberService,
  deleteInvite as deleteInviteService,
  getInviteDetails as getInviteDetailsService,
  registerInvite as registerInviteService
} from "services/AuthService";

const handleSaveToken = ({ data }: Record<string, { data: string }>) => {
  saveToken({ token: data.data });
};

export const registerUser = (params: Record<string, unknown>) =>
  action(`auth/${REGISTER_USER}`, registerUserService, params);
export const registerUserInit = () => init(`auth/${REGISTER_USER}/init`);

export const loginUser = (params: Record<string, unknown>) =>
  action(`auth/${LOGIN_USER}`, loginUserService, params, { customAction: handleSaveToken });
export const loginUserInit = () => init(`auth/${LOGIN_USER}/init`);

export const logoutUser = createAction(`auth/${LOGOUT_USER}`);

export const deactivateUser = (params: Record<string, string>) =>
  action(`auth/${DEACTIVATE_USER}`, deactivateUserService, params);
export const activateUser = (params: Record<string, string>) =>
  action(`auth/${ACTIVATE_USER}`, activateUserService, params);
export const activateUserInit = () => init(`auth/${ACTIVATE_USER}/init`);

export const resetPassword = (params: Record<string, string>) =>
  action(`auth/${RESET_PASSWORD}`, resetPasswordUserService, params);
export const resetPasswordInit = () => init(`auth/${RESET_PASSWORD}/init`);
export const resetUserPassword = (params: Record<string, string>) =>
  action(`auth/${RESET_USER_PASSWORD}`, resetUserPasswordService, params);
export const changeUserPassword = (params: Record<string, string>) =>
  action(`auth/${CHANGE_USER_PASSWORD}`, changeUserPasswordService, params);
export const changeUserPasswordInit = () => init(`auth/${CHANGE_USER_PASSWORD}/init`);

export const inviteMember = (params: Record<string, unknown>) =>
  action(`auth/${INVITE_MEMBER}`, inviteMemberService, params);
export const inviteMemberInit = () => init(`auth/${INVITE_MEMBER}/init`);

export const listInvitedMembers = (params: Record<string, unknown>) =>
  action(`auth/${LIST_INVITED_MEMBERS}`, listInvitedMemberService, params);

export const deleteInvite = (inviteId: number) => action(`auth/${DELETE_INVITE}`, deleteInviteService, inviteId);
export const deleteInviteInit = () => init(`auth/${DELETE_INVITE}/init`);

export const getInviteDetails = (inviteCode: string) =>
  action(`auth/${GET_INVITE_DETAILS}`, getInviteDetailsService, inviteCode);
export const registerInvite = (params: Record<string, unknown>) =>
  action(`auth/${REGISTER_INVITE}`, registerInviteService, params);
export const registerInviteInit = () => init(`auth/${REGISTER_INVITE}/init`);
