import { useRef } from "react";

import { OverlayPanel } from "primereact/overlaypanel";

import Button from "components/Button";

import "./HeroDataField.css";

interface HeroDataFieldProps {
  label: string;
  value: string | number;
  information?: string;
  textField?: boolean;
}

export default function HeroDataField({ label, value, information }: HeroDataFieldProps) {
  const op = useRef<any>(null);

  return (
    <div className="flex flex-column align-items-center justify-content-center text-white text-center ml-14 mr-14">
      <div className="text-xs lg:text-sm">
        {label}
        <Button
          type="button"
          className="button-no-outline"
          style={{ marginLeft: "4px", width: "25px", height: "20px" }}
          icon="pi pi-info-circle"
          rounded
          text
          severity="info"
          aria-label="Info"
          label=""
          onClick={(e) => op.current.toggle(e)}
        />
        <OverlayPanel ref={op} style={{ width: "450px" }}>
          <div className="text-md font-bold">{label}</div>
          <div className="text-sm" dangerouslySetInnerHTML={{ __html: `${information}` }}></div>
        </OverlayPanel>
      </div>
      <div className="text-4xl line-height-3 md:top-0">{value ?? "n/a"}</div>
    </div>
  );
}
