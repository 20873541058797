import { useEffect, useState } from "react";

import { confirmDialog } from "primereact/confirmdialog";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import {
  updateJobApplicationClient,
  updateJobApplicationClientInit,
  getJobApplicationsClient,
  getAllContractsClient
} from "state/actions/jobsActions";
import {
  selectGetJobApplicationsClientRequest,
  selectGetJobApplicationsClientSuccess,
  selectUpdateJobApplicationClientSuccess,
  selectCreateContractSuccess
} from "state/selectors/jobsSelectors";

import Button from "components/Button";
import DataField from "components/DataField";
import Divider from "components/Divider";
import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

import { IMyJobs, IMyJob } from "types/job";

import ContractModal from "../ContractModal";

export default function JobApplications() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const { applicantStatus } = useCommon();

  const [first, setFirst] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const [showCraeteContract, setShowCreateContract] = useState(false);
  const [jobApplicationToSubmit, setJobApplicationToSubmit] = useState<IMyJob>();

  const getJobApplicationsClientSuccess = useAppSelector(selectGetJobApplicationsClientSuccess) as IMyJobs;
  const getJobApplicationsClientRequest = useAppSelector(selectGetJobApplicationsClientRequest) as boolean;
  const getUpdateJobApplicationClient = useAppSelector(selectUpdateJobApplicationClientSuccess);
  const createContactSuccess = useAppSelector(selectCreateContractSuccess);

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };
    dispatch(getJobApplicationsClient({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setPage(event.page + 1);
    getData({ page: event.page + 1 });
  };

  useEffect(() => {
    getData({});
    dispatch(getAllContractsClient({}));
    setFirst(0);
    if (getUpdateJobApplicationClient) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("job.acceptedApplicant"),
        life: 3000
      });
    }
    if (createContactSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("contract.contractSuccesfullyCreated"),
        life: 3000
      });
    }
  }, [getUpdateJobApplicationClient, createContactSuccess]);

  const handleAcceptAppicant = (application: IMyJob) => {
    const joAppToUpdate = {
      Id: parseInt(application?.Id as unknown as string),
      IdJob: parseInt(application?.IdJob as unknown as string),
      IdUser: application?.IdUser,
      IdOwner: application?.IdOwner,
      Status: 2
    };

    dispatch(updateJobApplicationClient(joAppToUpdate)).then(() => dispatch(updateJobApplicationClientInit()));
  };

  const acceptApplicantConfirmDialog = (application: IMyJob) => {
    confirmDialog({
      header: t("confirmation.areYouSure"),
      message: `${t("job.acceptApplicant").replace("[name]", application.CreatorFullName)}`,
      draggable: false,
      accept: () => handleAcceptAppicant(application)
    });
  };

  const handleDeclineAppicant = (application: IMyJob) => {
    const joAppToUpdate = {
      Id: parseInt(application?.Id as unknown as string),
      IdJob: parseInt(application?.IdJob as unknown as string),
      IdUser: application?.IdUser,
      IdOwner: application?.IdOwner,
      Status: 4
    };

    dispatch(updateJobApplicationClient(joAppToUpdate)).then(() => dispatch(updateJobApplicationClientInit()));
  };

  const declineApplicantConfirmDialog = (application: IMyJob) => {
    confirmDialog({
      header: t("confirmation.areYouSure"),
      message: `${t("job.declineApplicant").replace("[name]", application.CreatorFullName)}`,
      draggable: false,
      accept: () => handleDeclineAppicant(application)
    });
  };

  const applications = getJobApplicationsClientSuccess?.jobs;

  if (getJobApplicationsClientRequest) {
    return (
      <div className="h-full">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <section className="container mx-auto px-2 md:px-0">
        <SectionTitle>{t("jobApplications")}</SectionTitle>
        <div className="card">
          {applications?.map((application: IMyJob, index: number) => (
            <div key={index} className="relative">
              {index > 0 && <Divider className="mb-4 mt-4" />}
              <div className="w-full">
                <div className="flex gap-4 mb-6">
                  <div className="pt-4 w-6 flex flex-column gap-4">
                    <DataField label={t("common.applicant")} value={application.CreatorFullName} />
                    <DataField textField label={t("common.name")} value={application.JobName} />
                    <DataField textField label={t("common.description")} value={application.JobDescription} />
                  </div>
                  <div className="pt-4 w-6 flex flex-column gap-4">
                    <div className="flex w-full flex-column md:flex-row gap-4 md:gap-0">
                      <DataField
                        className="break-text text-sm md:text-base"
                        textField
                        label={t("common.web")}
                        value={application.OrganizationWeb}
                      />
                      <DataField
                        label={t("common.status")}
                        value={
                          applicantStatus.find((appl) => appl.value === application.Status)?.label ||
                          t("common.unknown")
                        }
                      />
                    </div>
                    <div className="flex w-full">
                      <DataField label={t("job.organizationName")} value={application.OrganizationName} />
                    </div>
                    <div className="flex gap-4">
                      <DataField label={t("job.type")} value={application.ApplicationType} />
                      <div className="w-full">
                        {application?.Status === 1 && (
                          <div className="hidden md:flex w-full flex-column gap-2 md:flex-row md:gap-0">
                            <Button
                              label={t("common.accept")}
                              type="button"
                              icon="pi pi-check"
                              className="mr-2 w-full"
                              onClick={() => {
                                acceptApplicantConfirmDialog(application);
                              }}
                            />
                            <Button
                              label={t("common.decline")}
                              severity="danger"
                              type="button"
                              icon="pi pi-times"
                              className="md:ml-2 w-full"
                              onClick={() => {
                                declineApplicantConfirmDialog(application);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {application?.Status === 2 && (
                      <div className="hidden md:flex w-full flex-column gap-2 md:flex-row md:gap-0">
                        <Button
                          label={t("contract.createContract")}
                          type="button"
                          icon="pi pi-check"
                          className="mr-2 w-full"
                          onClick={() => {
                            setShowCreateContract(true);
                            setJobApplicationToSubmit(application);
                          }}
                        />
                        <Button
                          label={t("common.decline")}
                          severity="danger"
                          type="button"
                          icon="pi pi-times"
                          className="md:ml-2 w-full"
                          onClick={() => {
                            declineApplicantConfirmDialog(application);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="md:hidden">
                <div className="w-full">
                  {application?.Status === 1 && (
                    <div className="flex w-full  flex-column gap-2 md:flex-row md:gap-0">
                      <Button
                        label={t("common.accept")}
                        type="button"
                        icon="pi pi-check"
                        className="mr-2 w-full"
                        onClick={() => {
                          acceptApplicantConfirmDialog(application);
                        }}
                      />
                      <Button
                        label={t("common.decline")}
                        severity="danger"
                        type="button"
                        icon="pi pi-times"
                        className="md:ml-2 w-full"
                        onClick={() => {
                          declineApplicantConfirmDialog(application);
                        }}
                      />
                    </div>
                  )}
                </div>

                {application?.Status === 2 && (
                  <div className="flex w-full flex-column gap-2 md:flex-row md:gap-0">
                    <Button
                      label={t("contract.createContract")}
                      type="button"
                      icon="pi pi-check"
                      className="mr-2 w-full"
                      onClick={() => {
                        setShowCreateContract(true);
                        setJobApplicationToSubmit(application);
                      }}
                    />
                    <Button
                      label={t("common.decline")}
                      severity="danger"
                      type="button"
                      icon="pi pi-times"
                      className="md:ml-2 w-full"
                      onClick={() => {
                        declineApplicantConfirmDialog(application);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
          {!applications && (
            <div className="w-full">
              <div className="relative flex align-items-center justify-between">
                <span className="text-purple">{t("jobApplications.nojobApplications")}</span>
              </div>
            </div>
          )}
          {getJobApplicationsClientSuccess?.total > 5 && (
            <Paginator
              first={first}
              rows={5}
              totalRecords={getJobApplicationsClientSuccess.total}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </section>
      <ContractModal
        show={showCraeteContract}
        closeModal={() => setShowCreateContract(false)}
        jobApplication={jobApplicationToSubmit}
      />
    </>
  );
}
