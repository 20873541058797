import { decodeToken } from "react-jwt";

import { STATUS } from "utils/constants";
import { getToken } from "utils/token";

const getInitialLoginData = () => {
  if (getToken()) {
    const token = getToken();
    const user = decodeToken(token as string) as object;
    return { data: { token: token, ...user }, status: STATUS.FULFILLED, error: null };
  } else
    return {
      data: null,
      status: STATUS.INIT,
      error: null
    };
};

export const preloadedState = {
  auth: {
    login: getInitialLoginData()
  }
};
