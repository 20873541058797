import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { getOrganization } from "state/actions/organizationsActions";
import {
  selectCrateOrganizationSuccess,
  selectGetOrganizationRequest,
  selectGetOrganizationSuccess,
  selectUpdateOrganizationSuccess
} from "state/selectors/organizationsSelectors";

import Button from "components/Button";
import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

import { IOrganization } from "types/organization";

import OrganizationInfo from "./components/OrganizationInfo";
import OrganizationModal from "./components/OrganizationModal";

export default function Organization() {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const organization = useAppSelector(selectGetOrganizationSuccess) as IOrganization;
  const organizationRequest = useAppSelector(selectGetOrganizationRequest) as boolean;
  const updateOrganizationSuccess = useAppSelector(selectUpdateOrganizationSuccess) as boolean;
  const createOrganizationSuccess = useAppSelector(selectCrateOrganizationSuccess) as boolean;

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getOrganization());
    if (createOrganizationSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("organization.created"),
        life: 3000
      });
    }
    if (updateOrganizationSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("organization.updated"),
        life: 3000
      });
    }
  }, [createOrganizationSuccess, updateOrganizationSuccess]);

  if (organizationRequest) {
    return (
      <div className="h-full">
        <Loading />
      </div>
    );
  }

  if (organization) {
    return (
      <section className="container mx-auto px-2 md:px-0">
        <SectionTitle>
          <div className="relative flex justify-content-between align-items-center w-full">
            {t("organization.title")}
            {!organizationRequest && !Object.keys(organization).length && (
              <Button
                icon="pi pi-plus"
                text
                className="text-900"
                tooltip={t("organization.addNewOrganizationTitle")}
                tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
                onClick={() => setShowEditModal(true)}
              >
                <span className="text-900 p-button-label hidden md:flex ml-2">
                  {t("organization.addNewOrganizationTitle")}
                </span>
              </Button>
            )}
          </div>
        </SectionTitle>
        <div className="card">
          {!organizationRequest && Object.keys(organization).length > 0 && <OrganizationInfo />}
          {!organizationRequest && !Object.keys(organization).length && (
            <>
              <div className="w-full">
                <div className="relative flex align-items-center justify-between">
                  <span className="text-purple">{t("organization.noOrganization")}</span>
                </div>
              </div>
              <OrganizationModal show={showEditModal} closeModal={() => setShowEditModal(false)} />
            </>
          )}
        </div>
      </section>
    );
  }

  return null;
}
