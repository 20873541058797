import * as yup from "yup";

import { emailValidator, requiredValidator } from "utils/validators";

export const schema = yup.object().shape({
  FirstName: requiredValidator,
  LastName: requiredValidator,
  Email: emailValidator,
  Password: requiredValidator
});
