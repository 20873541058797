export const GET_JOBS = "getJobs";
export const CREATE_JOB = "createJob";
export const UPDATE_JOB = "updateJob";
export const GET_JOB_INFO = "getJobInfo";
export const GET_SEARCH_JOBS = "searchJobs";
export const APPLY_TO_JOB = "applyToJob";
export const GET_ALL_JOB_APPLICATIONS = "getAllJobApplications";
export const GET_ALL_CONTRACTS = "getAllContracts";
export const GET_JOB_APPLICATIONS_CLIENT = "getJobApplicationsClient";
export const GET_ALL_CONTRACTS_CLIENT = "getAllContractsClient";
export const UPDATE_JOB_APPLICATIONS_CLIENT = "updateJobApplicationsClient";
export const CREATE_CONTRACT = "createContract";
