import { useState } from "react";

import { confirmDialog } from "primereact/confirmdialog";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { updateReport, updateReportInit } from "state/actions/dashboardActions";
import { sendNotification, sendNotificationInit } from "state/actions/notificationAction";
import { selectGetUserDataSuccess } from "state/selectors/usersSelectors";

import Button from "components/Button";
import DataField from "components/DataField";

import { IMReports } from "types/report";
import { IUserData } from "types/user";

import VdmModal from "../VdmModal";

export default function ReportDetails(myReport: IMReports) {
  const { t } = useTranslation();
  const { reportStatus } = useCommon();
  const userData = useAppSelector(selectGetUserDataSuccess) as IUserData;
  const dispatch = useAppDispatch();

  const [showEditModal, setShowEditModal] = useState(false);
  const [hourlyRate, setHourlyRate] = useState("");
  const [currencyShortName, setCurrencyShortName] = useState("");

  if (!myReport.myreport) {
    return null;
  }

  const report = myReport?.myreport[0];

  const handleSendReport = () => {
    const reportToSend = {
      Id: parseInt(report.IdReport),
      Name: report.ReportName,
      IdContract: report.IdContract,
      IdProject: report.IdProject,
      Status: 2,
      Comment: report.Comment ? report.Comment : "",
      Year: report.Year,
      Month: report.Month,
      ReportDate: report.ReportDate
    };

    const notificationToSend = {
      notificationType: "requestReportReview",
      Email: report.ReporterEmails,
      EmailRequester: userData.Email,
      ReportComment: "",
      ReportId: parseInt(report.IdReport)
    };

    dispatch(updateReport(reportToSend)).then(() => {
      dispatch(updateReportInit());
    });

    dispatch(sendNotification(notificationToSend)).then(() => dispatch(sendNotificationInit()));
  };

  const getReportMonthYear = () => {
    const month = report.Month.toString().length === 1 ? "0" + report.Month : report.Month;
    return new Date(Date.parse(report.Year + "-" + month + "-01"));
  };

  const getCurrentMonthYear = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return new Date(Date.parse(date.getFullYear() + "-" + month + "-01"));
  };

  const sendReportConfirmDialog = () => {
    confirmDialog({
      header: t("confirmation.areYouSure"),
      message: `${t("myreports.sendToPartnerConfirm")}`,
      draggable: false,
      accept: () => handleSendReport()
    });
  };

  return (
    <div className="relative">
      <div className="flex gap-4 pt-6">
        <div className="w-6 flex flex-column gap-4">
          <DataField label={t("myreports.reportSummary")} value={report.ReportName} />
          <DataField textField label={t("mytasks.partnerName")} value={report.PartnerName} />
          <DataField
            textField
            label={t("myreports.reportForMonthYear")}
            value={`${report.Month.toString().length === 1 ? "0" + report.Month : report.Month}/${report.Year}`}
          />
          <DataField label={t("myreports.contract")} value={report.ContractTitle} />
        </div>
        <div className="w-6 flex flex-column gap-4">
          <div className="md:flex md:w-full">
            <div className="md:flex md:w-full">
              <DataField
                className="flex md:w-3"
                label={t("assignee.hourlyRate")}
                value={report.HourlyRate + " " + report.CurrencyShortName}
              />
              <Button
                type="button"
                style={{ width: "25px", height: "25px", marginTop: "10px" }}
                icon="pi pi-info-circle"
                rounded
                text
                severity="info"
                aria-label="Info"
                label=""
                onClick={() => {
                  setHourlyRate(report.HourlyRate);
                  setCurrencyShortName(report.CurrencyShortName);
                  setShowEditModal(true);
                }}
              />
            </div>
            <div className="flex w-full pt-7 md:pt-0">
              <DataField
                status={report.Status === 1 ? "active" : "inactive"}
                label={t("common.status")}
                value={reportStatus.find((s) => s.value.toString() === report.Status.toString())?.label || ""}
              />
            </div>
          </div>

          <div className="md:flex md:w-full">
            <DataField textField label={t("mytasks.totalHoursReported")} value={report.Duration} />
            <DataField className="mt-6 md:mt-4" label={t("myreports.reportedTo")} value={report.ReporterEmails} />
          </div>
          <div className="flex w-full">
            <DataField
              className="pt-4 md:pt-0"
              label={t("mytasks.totalAmountToCharge")}
              value={report.Earnings + " " + report.CurrencyShortName}
            />
            {report.Comment && <DataField label={t("common.comment")} value={`${report.Comment}`} />}
          </div>

          <div className="hidden w-full md:flex">
            {(report?.Status === 0 || report?.Status === 1) && (
              <div className="flex w-full">
                <Button
                  label={t("myreports.sendToPartner")}
                  type="button"
                  icon="pi pi-check"
                  tooltip={+getReportMonthYear() >= +getCurrentMonthYear() ? t("myreports.sendForReviewNextMonth") : ""}
                  tooltipOptions={{ showOnDisabled: true }}
                  disabled={+getReportMonthYear() >= +getCurrentMonthYear()}
                  onClick={() => sendReportConfirmDialog()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pt-4 flex w-full justify-content-evenly md:hidden">
        {(report?.Status === 0 || report?.Status === 1) && (
          <Button
            label={t("myreports.sendToPartner")}
            type="button"
            icon="pi pi-check"
            tooltip={+getReportMonthYear() >= +getCurrentMonthYear() ? t("myreports.sendForReviewNextMonth") : ""}
            tooltipOptions={{ showOnDisabled: true }}
            disabled={+getReportMonthYear() >= +getCurrentMonthYear()}
            onClick={() => sendReportConfirmDialog()}
          />
        )}
      </div>

      <VdmModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
        hourlyRate={hourlyRate}
        currencyShortname={currencyShortName}
      />
    </div>
  );
}
