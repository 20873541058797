import { combineReducers } from "@reduxjs/toolkit";

import {
  GET_USER_DATA,
  GET_USER_DETAILS_DATA,
  UPDATE_USER_DATA,
  UPDATE_USER_DETAILS_DATA
} from "state/constants/usersConstants";
import reducer from "state/utils/reducer";

export default combineReducers({
  getUserData: reducer(`users/${GET_USER_DATA}`),
  updateUserData: reducer(`users/${UPDATE_USER_DATA}`),
  getUserDetailsData: reducer(`users/${GET_USER_DETAILS_DATA}`),
  updateUserDetailsData: reducer(`users/${UPDATE_USER_DETAILS_DATA}`)
});
