import { Chip as PrimeChip } from "primereact/chip";

export default function Chip({ ...props }: any) {
  const { onRemove, children } = props;
  return (
    <PrimeChip onRemove={onRemove} {...props}>
      {children}
    </PrimeChip>
  );
}
