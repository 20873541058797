import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { getUserDetailsData } from "state/actions/usersAction";
import {
  selectGetUserDetailsDataSuccess,
  selectUpdateUserDataFail,
  selectUpdateUserDataSuccess,
  selectUpdateUserDetailsDataSuccess
} from "state/selectors/usersSelectors";

import Loading from "components/Loading";

import BasicInformation from "./components/BasicInformation";
import Education from "./components/Education";
import ProfileHero from "./components/ProfileHero";
import Skills from "./components/Skills";
import WorkExperience from "./components/WorkExperience";

export default function Profile() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { toast } = useToast();

  const updateUserDataSuccess = useAppSelector(selectUpdateUserDataSuccess);
  const updateUserDataFail = useAppSelector(selectUpdateUserDataFail);
  const userDetailsDataSuccess = useAppSelector(selectGetUserDetailsDataSuccess);
  const updateUserDetailsDataSuccess = useAppSelector(selectUpdateUserDetailsDataSuccess);

  useEffect(() => {
    dispatch(getUserDetailsData());
  }, [updateUserDetailsDataSuccess]);

  useEffect(() => {
    if (updateUserDataSuccess || updateUserDetailsDataSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("profile.updateSuccess"),
        life: 3000
      });
    }
  }, [updateUserDataSuccess, updateUserDetailsDataSuccess]);

  useEffect(() => {
    if (updateUserDataFail) {
      toast?.current?.show({
        severity: "error",
        summary: t("common.error"),
        detail: t("errorBoundary.title"),
        life: 3000
      });
    }
  }, [updateUserDataFail]);

  return (
    <>
      <ProfileHero />
      <div className="container mx-auto pt-4">
        {!userDetailsDataSuccess ? (
          <Loading />
        ) : (
          <>
           <div className="container mx-auto py-0 px-2 md:px-0">
            <BasicInformation />
            <Education />
            <WorkExperience />
            <Skills />
            </div>
          </>
        )}
      </div>
    </>
  );
}
