import * as yup from "yup";

import { requiredValidator, requiredValidatorSelect } from "utils/validators";

export const schema = yup.object().shape({
  School: requiredValidator,
  Degree: requiredValidator,
  FieldOfStudy: requiredValidator,
  StartMonth: requiredValidatorSelect,
  StartYear: requiredValidatorSelect,
  EndMonth: requiredValidatorSelect,
  EndYear: requiredValidatorSelect,
  Grade: requiredValidator,
  Activities: requiredValidator,
  Description: requiredValidator
});
