import { forwardRef } from "react";

import { InputNumber as PrimeInputNumber, InputNumberProps as PrimeInputNumberProps } from "primereact/inputnumber";

import Label from "components/Label";

import { FieldError } from "types/FieldError";

interface InputNumberProps extends PrimeInputNumberProps {
  error?: FieldError;
  label: string;
}

const InputNumber = forwardRef<PrimeInputNumber, InputNumberProps>((props, ref) => {
  return (
    <div className="input relative flex flex-column text-sm mt-4">
      <Label error={!!props.error}>{props.label}</Label>
      <PrimeInputNumber ref={ref} className={`${props.error ? "p-invalid" : ""}`} {...props} showButtons />
      {props?.error && (
        <small className="absolute p-error" id={props.name}>
          {props?.error?.message}
        </small>
      )}
    </div>
  );
});

InputNumber.displayName = "InputNumber";

export default InputNumber;
