import { forwardRef } from "react";

import {
  InputTextarea as PrimeInputTextarea,
  InputTextareaProps as PrimeInputTextareaProps
} from "primereact/inputtextarea";

import Label from "components/Label";

import { FieldError } from "types/FieldError";

interface InputTextareaProps extends PrimeInputTextareaProps {
  error?: FieldError;
  label: string;
}

const InputTextarea = forwardRef<HTMLTextAreaElement, InputTextareaProps>((props, ref) => (
  <div className="input relative flex flex-column text-sm mt-4">
    <Label error={!!props.error}>{props.label}</Label>
    <PrimeInputTextarea ref={ref} className={`${props.error ? "p-invalid" : ""}`} rows={5} {...props} />
    {props?.error && (
      <small className="absolute p-error" id={props.name}>
        {props?.error?.message}
      </small>
    )}
  </div>
));

InputTextarea.displayName = "InputTextarea";

export default InputTextarea;
