import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAppSelector } from "hooks/useRedux";

import { ROUTE_TYPE } from "utils/constants";

import { selectIsUserAuthenticated } from "state/selectors/authSelectors";

import { UserType } from "types/user";

import { selectUserAuthentication } from "../../state/selectors/authSelectors";

interface AuthWrapperProps {
  routeType: number;
}

function AuthWrapper({ routeType }: AuthWrapperProps) {
  const location = useLocation();
  const isUserAuthenticated = useAppSelector(selectIsUserAuthenticated) as boolean;
  const user = useAppSelector(selectUserAuthentication) as any;

  const { PUBLIC, PRIVATE, PUBLIC_LOGOUT, CLIENT } = ROUTE_TYPE;

  if (routeType === PUBLIC) {
    return <Outlet />;
  }

  if (routeType === PUBLIC_LOGOUT) {
    if (isUserAuthenticated) {
      return <Navigate to="/" state={{ from: location }} replace />;
    } else {
      return <Outlet />;
    }
  }

  if (routeType === PRIVATE) {
    if (isUserAuthenticated) {
      return <Outlet />;
    } else return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (routeType === CLIENT) {
    if (isUserAuthenticated && user?.UserType === UserType.Client) {
      return <Outlet />;
    } else return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default AuthWrapper;
