import useTranslation from "hooks/useTranslation";

import Dialog from "components/Dialog";
import FileUpload from "components/FileUpload";

interface IImportModalProps {
  show: boolean;
  closeModal: () => void;
}

const ImportModal = ({ show, closeModal }: IImportModalProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    closeModal();
  };

  return (
    <Dialog title={t("mytasks.importData")} show={show} closeModal={handleClose}>
      <FileUpload />
    </Dialog>
  );
};

export default ImportModal;
