import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { getAllContracts } from "state/actions/jobsActions";
import { createProject, createProjectInit, updateProject, updateProjectInit } from "state/actions/projectsActions";
import { selectGetAllContractsSuccess } from "state/selectors/jobsSelectors";
import { selectUpdateProjectRequest, selectUpdateProjectSuccess } from "state/selectors/projectsSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { Calendar, Checkbox, InputNumber, InputText, InputTextarea, Select } from "components/FormControls";

import { IMyContracts, IMyContract } from "types/contract";
import { IProject } from "types/project";

import { schema } from "./schema";

interface IProjectModalProps {
  show: boolean;
  closeModal: () => void;
  project: null | IProject;
}

export default function ProjectModal({ show, closeModal, project }: IProjectModalProps) {
  const dispatch = useAppDispatch();
  const updateProjectRequest = useAppSelector(selectUpdateProjectRequest);
  const updateProjectSuccess = useAppSelector(selectUpdateProjectSuccess);
  const getMyContracts = useAppSelector(selectGetAllContractsSuccess) as IMyContracts;
  const myContracts = getMyContracts?.contracts.map((item: IMyContract) => ({
    label: item.Title,
    value: parseInt(item.Id as unknown as string)
  }));

  const { t } = useTranslation();
  const { projectStatus, currencies } = useCommon();

  const { reset, handleSubmit, control, watch, clearErrors } = useForm<IProject>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  useEffect(() => {
    dispatch(getAllContracts({}));
  }, []);

  const StartDate = watch("StartDate", new Date());
  const EndDate = watch("EndDate", new Date());

  const resetForm = () => {
    reset({
      ...project
    });
  };

  const handleClose = () => {
    closeModal();
    resetForm();
    clearErrors();
  };

  const onSubmit: SubmitHandler<IProject> = (data) => {
    const isStartup =
      (data.IsStartup as unknown as string) === "true" || (data.IsStartup as unknown as string) === "1" ? 1 : 0;
    const projectData = {
      ...data,
      StartDate: StartDate.toJSON(),
      EndDate: EndDate.toJSON(),
      IdImported: null,
      IsImported: 0,
      IsStartup: isStartup,
      Status: data.Status,
      EstimatedBudget: parseInt(data.EstimatedBudget as unknown as string),
      IdCurrency: data.IdCurrency || "27",
      IdContract: parseInt(data.IdContract as unknown as string)
    };

    if (project?.Id) {
      dispatch(updateProject(projectData)).then(() => dispatch(updateProjectInit()));
    } else {
      dispatch(createProject(projectData)).then(() => dispatch(createProjectInit()));
    }
    handleClose();
  };

  useEffect(() => {
    if (project) {
      reset({
        ...project,
        StartDate: new Date(project.StartDate),
        EndDate: new Date(project.EndDate)
      });
    } else resetForm();
  }, [project]);

  useEffect(() => {
    if (updateProjectSuccess) {
      resetForm();
    }
  }, [updateProjectSuccess]);

  const footerContent = (
    <div>
      <Button label={t("confirmation.cancel")} onClick={closeModal} outlined disabled={updateProjectRequest} />
      <Button label={t("confirmation.save")} type="submit" form="edit-project-form" disabled={updateProjectRequest} />
    </div>
  );

  return (
    <Dialog
      show={show}
      closeModal={closeModal}
      title={t(project ? "project.editProject" : "project.addNewProject")}
      footerContent={footerContent}
    >
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="edit-project-form">
        <Controller
          name="IdContract"
          control={control}
          render={({ field, fieldState }) => (
            <Select
              label={t("common.contract")}
              placeholder={t("common.contract")}
              {...field}
              options={myContracts}
              error={fieldState.error}
            />
          )}
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => <InputText label={t("common.name")} {...field} error={fieldState.error} />}
          name="Name"
        />
        <Controller
          name="Description"
          control={control}
          render={({ field, fieldState }) => (
            <InputTextarea {...field} label={t("common.description")} error={fieldState.error} />
          )}
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => <InputText label={t("common.web")} {...field} error={fieldState.error} />}
          name="Web"
        />

        <Controller
          name="Status"
          control={control}
          render={({ field, fieldState }) => (
            <Select
              label={t("common.status")}
              placeholder={t("common.status")}
              {...field}
              options={projectStatus}
              error={fieldState.error}
            />
          )}
        />

        <Controller
          control={control}
          render={({ field }) => {
            return <Checkbox {...field} checked={!!field.value} label={t("project.isStartup")} />;
          }}
          name="IsStartup"
        />

        <div className="flex relative flex-column md:flex-row">
          <div className="w-full mr-3">
            <Controller
              name="StartDate"
              control={control}
              render={({ field, fieldState }) => (
                <Calendar label={t("common.startDate")} {...field} error={fieldState.error} />
              )}
            />
          </div>
          <div className="w-full ml-0 md:ml-3 mt-4 md:mt-0">
            <Controller
              name="EndDate"
              control={control}
              render={({ field, fieldState }) => (
                <Calendar label={t("common.endDate")} {...field} error={fieldState.error} />
              )}
            />
          </div>
        </div>
        <Controller
          name="IdCurrency"
          control={control}
          render={({ field, fieldState }) => (
            <Select
              placeholder={t("form.selectCurrency")}
              label={t("form.selectCurrency")}
              {...field}
              options={currencies}
              error={fieldState.error}
            />
          )}
        />
        <div className="flex relative flex-column md:flex-row gap-4 md:gap-0">
          <div className="w-full mr-3">
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <InputText label={t("project.estimatedBudget")} error={fieldState.error} {...field} />
              )}
              name="EstimatedBudget"
            />
          </div>
          <div className="w-full md:ml-3">
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label={t("project.estimatedMonths")}
                  error={fieldState.error}
                  id={field.name}
                  inputRef={field.ref}
                  value={field.value || null}
                  onBlur={field.onBlur}
                  onValueChange={(e) => field.onChange(e)}
                  useGrouping={false}
                />
              )}
              name="EstimatedTime"
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}
