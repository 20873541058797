import { Link } from "react-router-dom";

import useTranslation from "hooks/useTranslation";

import { ReactComponent as Facebook } from "images/svgs/facebook-negative.svg";
import { ReactComponent as Linkedin } from "images/svgs/linkedin-negative.svg";
import { ReactComponent as Name } from "images/svgs/name.svg";

import "./Footer.css";

export default function Footer() {
  const { t } = useTranslation();
  const today = new Date();
  const year = today.getFullYear();

  return (
    <footer className="bg-footer-gradient flex flex-column md:flex-row align-items-center justify-content-between w-full px-4 gap-2 h-8rem md:h-5rem lg:h-5rem xl:h-5rem pt-4 pb-4">
      <div className="flex flex-row align-items-center justify-content-center text-white z-1 gap-4 text-sm">
        <Link className="text-white link-decoration" to="/terms">
          {t("footer.terms")}
        </Link>
        <Link className="text-white link-decoration" to="/privacy">
          {t("footer.privacy")}
        </Link>
        <Link className="text-white link-decoration" to="/cookie">
          {t("footer.cookies")}
        </Link>
      </div>
      <div className="relative flex flex-row align-items-center justify-content-center left-0 right-0 mx-auto">
        <div className="text-white mr-2">
          &#169;
          {year}
        </div>
        <Link to="/">
          <Name className="fill-white mt-2" />
        </Link>
      </div>
      <div className="flex z-1">
        <a className="ml-4" href="https://www.facebook.com/#" target="_blank" rel="noreferrer">
          <Facebook />
        </a>
        <a className="ml-4" href="https://www.linkedin.com/#" target="_blank" rel="noreferrer">
          <Linkedin />
        </a>
      </div>
    </footer>
  );
}
