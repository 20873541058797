import { api } from "utils/api";

const baseProjectsUrl = `https://${process.env.REACT_APP_ENV}projects.${process.env.REACT_APP_API_URL}/projects`;

export const getProjects = (params: string) => api.get(`${baseProjectsUrl}`, params);

export const createProject = (params: Record<string, unknown>) => api.post(`${baseProjectsUrl}`, params);

export const updateProject = (params: Record<string, unknown>) => api.put(`${baseProjectsUrl}`, params);

export const getProjectInfo = (projectId: string) => api.get(`${baseProjectsUrl}/${projectId}`);

export const getProjectTasks = (params: Record<string, unknown>) =>
  api.get(`${baseProjectsUrl}/tasks/list/${params.projectId}`, params);

export const createProjectTask = (params: Record<string, unknown>) => api.post(`${baseProjectsUrl}/tasks`, params);

export const updateProjectTask = (params: Record<string, unknown>) => api.put(`${baseProjectsUrl}/tasks`, params);

export const deleteProjectTask = (taskId: number) => api.delete(`${baseProjectsUrl}/tasks/${taskId}`);

export const getProjectTaskInfo = (projectTaskId: string) => api.get(`${baseProjectsUrl}/tasks/${projectTaskId}`);

export const getProjectAssignees = (projectId: string) => api.get(`${baseProjectsUrl}/assignees/list/${projectId}`);
export const getProjectAssigneeInfo = (assigneeId: string) => api.get(`${baseProjectsUrl}/assignees/${assigneeId}`);
export const deleteProjectAssignee = (assigneeId: string) => api.delete(`${baseProjectsUrl}/assignees/${assigneeId}`);
export const getProjectAssigneeNames = (projectId: string) =>
  api.get(`${baseProjectsUrl}/assignees/names/${projectId}`);
export const createProjectAssignee = (params: Record<string, unknown>) =>
  api.post(`${baseProjectsUrl}/assignees`, params);
export const updateProjectAssignee = (params: Record<string, unknown>) =>
  api.put(`${baseProjectsUrl}/assignees`, params);
export const getProjectPositions = () => api.get(`${baseProjectsUrl}/positions/list`);
export const getProjectCreators = (projectId: string) =>
  api.get(`${baseProjectsUrl}/assignees/creators/names/${projectId}`);

export const getMyTasks = (params: Record<string, unknown>) => api.get(`${baseProjectsUrl}/mytasks/list`, params);
export const getMyTaskInfo = (taskId: number) => api.get(`${baseProjectsUrl}/mytasks/id/${taskId}`);
export const getTaskTimesheets = (taskId: string) => api.get(`${baseProjectsUrl}/tasktimesheets/list/${taskId}`);
export const createTaskTimesheet = (params: Record<string, unknown>) =>
  api.post(`${baseProjectsUrl}/tasktimesheets`, params);
export const updateTaskTimesheet = (params: Record<string, unknown>) =>
  api.put(`${baseProjectsUrl}/tasktimesheets`, params);
export const deleteTaskTimesheet = (taskTimesheetId: number) =>
  api.delete(`${baseProjectsUrl}/tasktimesheets/${taskTimesheetId}`);

export const getMyProjects = () => api.get(`${baseProjectsUrl}/myprojects/list`);
