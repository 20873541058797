import { useState } from "react";

import useCommon from "hooks/useCommon";
import useTranslation from "hooks/useTranslation";

import Button from "components/Button";
import DataField from "components/DataField";

import { IMReports } from "types/report";

import AddReportPaymentModal from "../AddReportPaymentModal";

export default function ReportDetails(myReport: IMReports) {
  const { t } = useTranslation();
  const { reportStatus } = useCommon();

  const [showEditModal, setShowEditModal] = useState(false);

  if (!myReport.myreport) {
    return null;
  }

  const report = myReport?.myreport[0];

  return (
    <div className="relative">
      <div className="flex gap-4 pt-6">
        <div className="w-6 flex flex-column gap-4">
          <DataField label={t("myreports.reportSummary")} value={report.ReportName} />
          <DataField textField label={t("mytasks.partnerName")} value={report.PartnerName} />
          <DataField
            textField
            label={t("myreports.reportForMonthYear")}
            value={`${report.Month.toString().length === 1 ? "0" + report.Month : report.Month}/${report.Year}`}
          />
          <DataField label={t("myreports.contract")} value={report.ContractTitle} />
        </div>
        <div className="w-6 flex flex-column gap-4">
          <div className="flex w-full">
            <div className="flex w-full">
              <DataField
                className="flex w-3"
                label={t("assignee.hourlyRate")}
                value={report.HourlyRate + " " + report.CurrencyShortName}
              />
            </div>
            <DataField
              status={report.Status === 1 ? "active" : "inactive"}
              label={t("common.status")}
              value={reportStatus.find((s) => s.value.toString() === report.Status.toString())?.label || ""}
            />
          </div>

          <div className="flex w-full">
            <DataField textField label={t("mytasks.totalHoursReported")} value={report.Duration} />
            <DataField label={t("myreports.reportedTo")} value={report.ReporterEmails} />
          </div>
          <div className="flex w-full">
            <DataField
              label={t("mytasks.totalAmountToCharge")}
              value={report.Earnings + " " + report.CurrencyShortName}
            />
            {report.Comment && <DataField label={t("common.comment")} value={`${report.Comment}`} />}
          </div>

          <div className="flex w-full">
            {report?.Status === 3 && (
              <div className="flex w-full">
                <Button
                  label={t("myreports.addReportPayment")}
                  type="button"
                  icon="pi pi-check"
                  onClick={() => setShowEditModal(true)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <AddReportPaymentModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
        report={report}
      />
    </div>
  );
}
