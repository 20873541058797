import * as yum from "yup";

import { requiredValidator, emailValidator } from "utils/validators";

export const schema = yum.object().shape({
  FirstName: requiredValidator,
  LastName: requiredValidator,
  Email: emailValidator,
  Skill: requiredValidator
});
