import { useNavigate } from "react-router-dom";

import { useAppSelector } from "hooks/useRedux";

import { selectGetProjectsDataSuccess } from "state/selectors/projectsSelectors";

import Button from "components/Button";
import Divider from "components/Divider";

import { IProject, IProjects } from "types/project";

import ProjectDetails from "../ProjectDetails";

const ProjectsList = () => {
  const projects = useAppSelector(selectGetProjectsDataSuccess) as IProjects;

  const navigate = useNavigate();

  const onProjectClick = (projectId: number) => {
    navigate(`/projects/${projectId}`);
  };

  if (!projects?.projects) {
    return null;
  }

  return (
    <>
      {projects?.projects.map((project: IProject, index: number) => (
        <div key={index} className="pb-5">
          {index > 0 && <Divider />}
          <ProjectDetails {...project} />
          <div className="flex w-full pt-6">
            <Button
              label="Project tasks"
              type="button"
              onClick={() => onProjectClick(project.Id)}
              className="w-full md:w-6 md:ml-auto"
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default ProjectsList;
