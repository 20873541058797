import { ReactNode, useEffect, useRef } from "react";

import { Toast as PrimeToast } from "primereact/toast";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";

import { getUserData } from "state/actions/usersAction";
import { selectIsUserAuthenticated } from "state/selectors/authSelectors";
import { selectUpdateUserDataSuccess } from "state/selectors/usersSelectors";

import ConfirmDialog from "components/ConfirmDialog";
import Footer from "components/Footer";
import Header from "components/Header";
import Toast from "components/Toast";

import "react-toastify/dist/ReactToastify.css";

import "layouts/MainLayout/MainLayout.css";

interface MainLayoutProps {
  children: ReactNode;
}

export default function MainLayout({ children }: MainLayoutProps) {
  const dispatch = useAppDispatch();
  const toast = useRef<PrimeToast>(null);

  const { setToastRef } = useToast();

  const userIsAuthenticated = useAppSelector(selectIsUserAuthenticated);
  const updateUserSuccess = useAppSelector(selectUpdateUserDataSuccess);

  useEffect(() => {
    if (userIsAuthenticated) {
      dispatch(getUserData());
    }
  }, [userIsAuthenticated, updateUserSuccess]);

  useEffect(() => {
    setToastRef(toast);
  }, [toast]);

  return (
    <div className="min-h-screen">
      <Header />
      <main className="pb-6">{children}</main>
      <Footer />
      <Toast ref={toast} />
      <ConfirmDialog />
    </div>
  );
}
