import { useNavigate } from "react-router-dom";

import { useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { selectGetMyReportsDataSuccess, selectGetMyReportsRequest } from "state/selectors/dashboardSelectors";

import { Column, DataTable } from "components/DataTable";
import Loading from "components/Loading";

import { IColumn } from "types/column";
import { IMyReports, IReport } from "types/report";

export default function MyReportsTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const myReportsRequest = useAppSelector(selectGetMyReportsRequest);
  const myReportsSuccess = useAppSelector(selectGetMyReportsDataSuccess) as IMyReports;

  const myReports = myReportsSuccess?.myreports?.map((reportItem) => ({
    ReportName: reportItem.ReportName,
    PartnerName: reportItem.PartnerName,
    ProjectName: reportItem.ProjectName,
    ReportDate: reportItem.ReportDate,
    Status: reportItem.StatusName,
    Comment: reportItem.Comment,
    Duration: reportItem.Duration,
    HourlyRate: reportItem.HourlyRate + " " + reportItem.CurrencyShortName,
    Earnings: reportItem.Earnings + " " + reportItem.CurrencyShortName,
    IdReport: parseInt(reportItem.IdReport)
  }));

  const headers: IColumn[] = [
    { field: "ReportName", header: t("myreports.reportName") },
    { field: "PartnerName", header: t("mytasks.partnerName") },
    { field: "ReportDate", header: t("myreports.creationDate") },
    { field: "Comment", header: t("myreports.partnersComment") },
    { field: "Duration", header: t("mytasks.duration") },
    { field: "HourlyRate", header: t("assignee.hourlyRate") },
    { field: "Earnings", header: t("mytasks.earnings") }
  ];

  const viewReportBodyTemplate = (rowData: IReport) => {
    return (
      <div
        className="pi pi-arrow-right"
        style={{ fontSize: "1.2rem", color: "var(--primary-color)", cursor: "pointer" }}
        onClick={() => navigate(`/reports/${rowData.IdReport}`)}
      />
    );
  };

  if (myReportsRequest) {
    return <Loading />;
  }

  return (
    <DataTable value={myReports}>
      {headers.map((col) => (
        <Column sortable key={col.field} {...col} />
      ))}
      <Column body={viewReportBodyTemplate} />
    </DataTable>
  );
}
