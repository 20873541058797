import { RefObject, useCallback, useState } from "react";

import { Toast as PrimeToast } from "primereact/toast";
import { useBetween } from "use-between";

export const useToastRef = () => {
  const [toast, setToast] = useState<RefObject<PrimeToast>>();
  const setToastRef = useCallback((ref: RefObject<PrimeToast>) => setToast(ref), []);
  return {
    toast,
    setToastRef
  };
};

export const useToast = () => useBetween(useToastRef);
