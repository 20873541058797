import { Avatar as PrimeAvatar } from "primereact/avatar";

export default function Avatar({ ...props }: any) {
  const { onClick, children } = props;
  return (
    <PrimeAvatar onClick={onClick} className="p-overlay-badge" {...props}>
      {children}
    </PrimeAvatar>
  );
}
