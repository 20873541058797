import { action, init } from "state/utils/action";

import {
  applyToJob as applyToJobService,
  createJob as createJobService,
  getAllContractsClient as getAllContractsClientService,
  getAllContracts as getAllContractsService,
  getAllJobApplications as getAllJobApplicationsService,
  getJobApplicationsClient as getJobApplicationsClientService,
  getJobInfo as getJobInfoService,
  getJobs as getJobsService,
  searchJobs as searchJobService,
  updateJob as updateJobService,
  updateJobApplicationClient as updateJobApplicationClientService,
  createContract as createContractService
} from "services/JobsService";

import {
  APPLY_TO_JOB,
  CREATE_JOB,
  GET_ALL_CONTRACTS,
  GET_ALL_CONTRACTS_CLIENT,
  GET_ALL_JOB_APPLICATIONS,
  GET_JOBS,
  GET_JOB_APPLICATIONS_CLIENT,
  GET_JOB_INFO,
  GET_SEARCH_JOBS,
  UPDATE_JOB,
  UPDATE_JOB_APPLICATIONS_CLIENT,
  CREATE_CONTRACT
} from "../constants/jobsConstants";

export const getJobs = (params: Record<string, unknown>) => action(`jobs/${GET_JOBS}`, getJobsService, params);

export const createJob = (params: Record<string, unknown>) => action(`jobs/${CREATE_JOB}`, createJobService, params);
export const createJobInit = () => init(`jobs/${CREATE_JOB}/init`);

export const updateJob = (params: Record<string, unknown>) => action(`jobs/${UPDATE_JOB}`, updateJobService, params);
export const updateJobInit = () => init(`jobs/${UPDATE_JOB}/init`);

export const getJobInfo = (jobId: string) => action(`jobs/${GET_JOB_INFO}`, getJobInfoService, jobId);

export const searchJobs = (params: Record<string, unknown>) =>
  action(`jobs/${GET_SEARCH_JOBS}`, searchJobService, params);

export const applyToJob = (params: Record<string, unknown>) =>
  action(`jobs/${APPLY_TO_JOB}`, applyToJobService, params);
export const applyToJobInit = () => init(`jobs/${APPLY_TO_JOB}/init`);

export const getAllJobApplications = (params: Record<string, unknown>) =>
  action(`jobs/${GET_ALL_JOB_APPLICATIONS}`, getAllJobApplicationsService, params);

export const getAllContracts = (params: Record<string, unknown>) =>
  action(`jobs/${GET_ALL_CONTRACTS}`, getAllContractsService, params);

export const getJobApplicationsClient = (params: Record<string, unknown>) =>
  action(`jobs/${GET_JOB_APPLICATIONS_CLIENT}`, getJobApplicationsClientService, params);

export const getAllContractsClient = (params: Record<string, unknown>) =>
  action(`jobs/${GET_ALL_CONTRACTS_CLIENT}`, getAllContractsClientService, params);

export const updateJobApplicationClient = (params: Record<string, unknown>) =>
  action(`jobs/${UPDATE_JOB_APPLICATIONS_CLIENT}`, updateJobApplicationClientService, params);
export const updateJobApplicationClientInit = () => init(`jobs/${UPDATE_JOB_APPLICATIONS_CLIENT}/init`);

export const createContract = (params: Record<string, unknown>) =>
  action(`jobs/${CREATE_CONTRACT}`, createContractService, params);
export const createContractInit = () => init(`jobs/${CREATE_CONTRACT}/init`);
