import { Paginator } from "primereact/paginator";

import InvitesTable from "../InvitesTable";

export default function InvitesList({ invites, handlePageChange, first }: any) {
  return (
    <section>
      <div className="card">
        <InvitesTable invites={invites} />
        {(invites?.total || 1) > 5 && (
          <Paginator first={first} rows={5} totalRecords={invites?.total} onPageChange={handlePageChange} />
        )}
      </div>
    </section>
  );
}
