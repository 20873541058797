import { ColumnProps, Column as PrimeColumn } from "primereact/column";
import { DataTableProps, DataTableValueArray, DataTable as PrimeDataTable } from "primereact/datatable";

export const DataTable = ({ ...props }: DataTableProps<DataTableValueArray>) => {
  return <PrimeDataTable {...props} />;
};

export const Column = ({ ...props }: ColumnProps) => {
  return <PrimeColumn {...props} />;
};
