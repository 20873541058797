import { ReactNode } from "react";

import useTranslation from "hooks/useTranslation";

import { ReactComponent as Logo } from "images/svgs/logo.svg";
import { ReactComponent as Name } from "images/svgs/name.svg";

import "./AuthLayout.css";

interface AuthLayoutProps {
  children: ReactNode;
}

export default function AuthLayout({ children }: AuthLayoutProps) {
  const { t } = useTranslation();

  return (
    <div className="w-full h-screen flex bg-white">
      <div className="flex flex-column justify-content-center align-items-center w-12 md:w-6 px-4 md:px-8 md:px-0">
        <div className="auth-layout-bg flex flex-column w-full align-items-center md:hidden">
          <Logo className="fill-white w-16 h-auto mb-3" width={70} />
          <Name className="fill-white w-64 h-auto mb-3" height={22} width={230} />
        </div>
        {children}
      </div>
      <div className="auth-layout-bg w-6 relative hidden lg:flex justify-content-center align-items-center">
        <div className="text-center text-white pre text-5xl line-height-2 font-light">{t("login.moto")}</div>
        <div className="absolute auth-logo-bottom m-auto right-0 left-0 flex flex-column align-items-center">
          <Logo className="fill-white w-16 h-auto mb-3" width={100} />
          <Name className="fill-white w-64 h-auto" height={30} width={300} />
        </div>
      </div>
    </div>
  );
}
