import { forwardRef } from "react";

import { Calendar as PrimeCalendar, CalendarProps as PrimeCalendarProps } from "primereact/calendar";

import Label from "components/Label";

import { FieldError } from "types/FieldError";

interface CalendarProps extends PrimeCalendarProps {
  error?: FieldError;
  label: string;
  calview?: "date" | "month" | "year" | undefined;
  caldateformat?: string;
  showtime?: boolean;
  hourformat?: "12" | "24";
}

const Calendar = forwardRef<PrimeCalendar, CalendarProps>((props, ref) => {
  return (
    <div className="input relative flex flex-column text-sm mt-4">
      <Label error={!!props.error}>{props.label}</Label>
      <PrimeCalendar
        ref={ref}
        className={`${props.error ? "p-invalid" : ""}`}
        showIcon
        showButtonBar
        {...props}
        view={props.calview ? props.calview : "date"}
        dateFormat={props.caldateformat ? props.caldateformat : "dd/mm/yy"}
        showTime={props.showtime}
        hourFormat={props.hourformat}
      />

      {props?.error && (
        <small className="absolute p-error" id={props.name}>
          {props?.error?.message}
        </small>
      )}
    </div>
  );
});

Calendar.displayName = "Calendar";

export default Calendar;
