import useCommon from "hooks/useCommon";
import { useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { selectGetUserDataSuccess } from "state/selectors/usersSelectors";

import Avatar from "components/Avatar";
import Hero from "components/Hero";

import { IUserData } from "types/user";

export default function ProfileHero() {
  const { countries, currenciesShortNames } = useCommon();
  const { t } = useTranslation();

  const user = useAppSelector(selectGetUserDataSuccess) as IUserData;
  const userCountry = countries?.find((country) => country?.value === user?.IdCountry)?.label as string;
  const currency = currenciesShortNames?.find((currency) => currency?.value === user?.IdCurrency)?.label as string;

  return (
    <Hero>
      {user && (
        <div className="container mx-auto flex align-items-center h-full text-white relative py-0 px-2 md:px-0">
          <div className="mr-5">
            {user?.ProfilePhoto ? (
              <img src={user.ProfilePhoto} alt={user.FirstName} />
            ) : (
              <Avatar
                shape="circle"
                style={{ backgroundColor: "var(--primary-500)", width: "13rem", height: "13rem", fontSize: "5rem" }}
                label={user?.FirstName.charAt(0) + user?.LastName.charAt(0)}
              />
            )}
          </div>
          <div>
            <div className="lg:text-5xl xl:text-5xl md:text-md sm:text-md font-bold mb-5 ">
              {user.FirstName} {user.LastName}
            </div>
            {user.Headline && <div className="lg:text-2xl xl:text-2xl md:text-sm sm:text-sm">{user.Headline}</div>}
            <div className="lg:text-lg xl:text-lg md:text-sm sm:text-sm">{userCountry}</div>
            {user.HourlyRate && (
              <div className="lg:text-lg xl:text-lg md:text-sm sm:text-sm">
                {t("assignee.hourlyRate")}: {user.HourlyRate} {currency}
              </div>
            )}
          </div>
        </div>
      )}
    </Hero>
  );
}
