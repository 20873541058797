import { useEffect, useState } from "react";

import produce from "immer";
import { confirmDialog } from "primereact/confirmdialog";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { updateUserDetailsData, updateUserDetailsDataInit } from "state/actions/usersAction";
import { selectGetUserDetailsDataSuccess, selectUpdateUserDetailsDataSuccess } from "state/selectors/usersSelectors";

import Button from "components/Button";
import DataField from "components/DataField";
import Divider from "components/Divider";
import SectionTitle from "components/SectionTitle";

import { IEducation, IUserDetails } from "types/user";

import EducationModal, { IEducationToEdit } from "../EducationModal";

export default function Education() {
  const { t } = useTranslation();
  const { months } = useCommon();
  const dispatch = useAppDispatch();

  const userDetails = useAppSelector(selectGetUserDetailsDataSuccess) as IUserDetails;
  const updateUserDetailsDataSuccess = useAppSelector(selectUpdateUserDetailsDataSuccess) as boolean;

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [educationToEdit, setEducationToEdit] = useState<null | IEducationToEdit>(null);

  useEffect(() => {
    if (updateUserDetailsDataSuccess) {
      setShowEditModal(false);
      if (educationToEdit) {
        setEducationToEdit(null);
      }
    }
  }, [updateUserDetailsDataSuccess]);

  if (!userDetails) {
    return null;
  }

  const handleDeleteEducation = (educationIndex: number) => {
    const deletedData = produce(userDetails, (draft) => {
      draft.Education.splice(educationIndex, 1);
    });
    dispatch(updateUserDetailsData(deletedData)).then(() => dispatch(updateUserDetailsDataInit()));
  };

  const deleteEducation = (educationIndex: number) => {
    confirmDialog({
      acceptClassName: "p-button-danger",
      header: t("confirmation.areYouSure"),
      message: `${t("confirmation.doYouReallyWantToDeleteTheseRecords")} ${t(
        "confirmation.thisProcessCannotBeUndone"
      )}`,
      draggable: false,
      accept: () => handleDeleteEducation(educationIndex)
    });
  };

  return (
    <section>
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("profile.education")}
           <Button
              icon="pi pi-plus"
              text
              className="text-900"
              tooltip={t("profile.addEducationModalTitle")}
              type="button"
              tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
              onClick={() => setShowEditModal(true)}
            ><span className="text-900 p-button-label hidden md:flex ml-2"> {t("profile.addEducationModalTitle")}</span></Button>
        </div>
      </SectionTitle>
      <div className="w-full card pt-0">
        {userDetails?.Education?.length > 0 ? (
          userDetails?.Education.map((education: IEducation, index: number) => (
            <div key={index} className="relative pb-5">
              {index > 0 && <Divider />}
              <div className="absolute top-0 right-0 p-3 -mr-5">
                <Button
                  icon="pi pi-file-edit"
                  rounded
                  text
                  className="h-2rem w-2rem text-900 mr-1"
                  tooltip="Edit education"
                  tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
                  onClick={() => {
                    setShowEditModal(true);
                    setEducationToEdit({ index, ...education });
                  }}
                />
                <Button
                  icon="pi pi-trash"
                  rounded
                  text
                  className="h-2rem w-2rem text-900"
                  tooltip="Delete education"
                  tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
                  onClick={() => {
                    deleteEducation(index);
                  }}
                />
              </div>
              <div className="flex">
                <div className="pt-6 w-full flex flex-column gap-4">
                  <DataField label={t("education.school")} value={education.School} />
                  <DataField label={t("education.degree")} value={education.Degree} />
                  <DataField label={t("education.fieldOfStudy")} value={education.FieldOfStudy} />
                </div>
                <div className="pt-6 w-full flex flex-column gap-4">
                  <div className="w-full flex-column xl:flex-row lg:flex-row flex  row-gap-4 xl:row-gap-0 lg:row-gap-0">
                    <DataField
                      label={t("common.startDate")}
                      value={`${months?.find((month) => month.value === education.StartMonth)?.label}, ${
                        education.StartYear
                      }`}
                    />
                    <DataField
                      label={t("common.endDate")}
                      value={`${months?.find((month) => month.value === education.EndMonth)?.label}, ${
                        education.EndYear
                      }`}
                    />
                  </div>
                  <div className="flex w-full ">
                    <DataField label={t("education.grade")} value={education.Grade} />
                  </div>
                </div>
              </div>
              <div className="flex justify-between lg:flex-row xl:flex-row flex-column">
                <div className="pt-8 w-full">
                  <DataField textField label={t("education.activities")} value={education.Activities} />
                </div>
                <div className="pt-8 w-full">
                  <DataField textField label={t("common.description")} value={education.Description} />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>{t("profile.noEducation")}</div>
        )}
      </div>
      <EducationModal
        show={showEditModal}
        closeDialog={() => {
          setShowEditModal(false);
          if (educationToEdit) {
            setEducationToEdit(null);
          }
        }}
        education={educationToEdit}
      />
    </section>
  );
}
