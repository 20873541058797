import { forwardRef } from "react";

import { MultiSelect as PrimeMiltiSelect, MultiSelectProps as PrimeMultiSelectProps } from "primereact/multiselect";

import Label from "components/Label";

import { FieldError } from "types/FieldError";

interface MultiSelectProps extends PrimeMultiSelectProps {
  error?: FieldError;
  className?: string;
  label?: string;
  name: string;
}

const MultiSelect = forwardRef<PrimeMiltiSelect, MultiSelectProps>((props, ref) => {
  const { className = "", error, name } = props;

  return (
    <div className={`input relative flex flex-column text-sm mt-4 ${className}`}>
      {props.label && <Label>{props.label}</Label>}
      <PrimeMiltiSelect className={`${error ? "p-invalid" : ""}`} ref={ref} {...props} />
      {error && (
        <small className="absolute p-error" id={name}>
          {error?.message}
        </small>
      )}
    </div>
  );
});

MultiSelect.displayName = "MultiSelect";

MultiSelect.defaultProps = {
  placeholder: "Select",
  name: "select"
};

export default MultiSelect;
