import { forwardRef } from "react";

import { InputTextProps, InputText as PrimeInputText } from "primereact/inputtext";

import Label from "components/Label";

import { FieldError } from "types/FieldError";

import "./InputText.css";

interface InputProps extends InputTextProps {
  error?: FieldError;
  label: string;
}

const InputText = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <div className="input relative flex flex-column text-sm mt-4">
    <Label error={!!props.error}>{props.label}</Label>
    <PrimeInputText
      ref={ref}
      className={`${props.error ? "p-invalid" : ""}`}
      aria-describedby={props.name}
      {...props}
    />

    {props?.error && (
      <small className="absolute p-error" id={props.name}>
        {props?.error?.message}
      </small>
    )}
  </div>
));

InputText.displayName = "InputText";

export default InputText;
