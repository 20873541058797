import { createReducer } from "@reduxjs/toolkit";

import { STATUS } from "utils/constants";

interface DataState {
  [key: string]: unknown;
}

const initialState: DataState = {
  data: null,
  status: STATUS.INIT,
  error: null
};

export default function reducer(name: string, customActionHandlers = {}) {
  return createReducer(initialState, {
    [`${name}/init`]: (state: { status: number; data: null; error: null }) => ({
      ...state,
      status: STATUS.INIT,
      data: null,
      error: null
    }),
    [`${name}/pending`]: (state: { status: number; data: null; error: null }) => ({
      ...state,
      status: STATUS.PENDING,
      data: state.data,
      error: null
    }),
    [`${name}/rejected`]: (
      state: { status: number; data: null; error: object },
      action: { payload: Record<string, unknown> }
    ) => ({
      ...state,
      status: STATUS.REJECTED,
      data: null,
      error: action.payload
    }),
    [`${name}/fulfilled`]: (
      state: { status: number; data: Record<string, unknown>; error: null },
      action: { payload: { data: Record<string, unknown> } }
    ) => ({
      ...state,
      status: STATUS.FULFILLED,
      data: action.payload.data,
      error: null
    }),
    ...customActionHandlers
  });
}
