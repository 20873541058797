export const STATUS = {
  INIT: 0,
  PENDING: 1,
  REJECTED: 2,
  FULFILLED: 3
};

export const ROUTE_TYPE = {
  PRIVATE: 0,
  PUBLIC: 1,
  PUBLIC_LOGOUT: 2,
  CLIENT: 3
};

export const PROJECT_STATUS = {
  ALL: -1,
  INACTIVE: 0,
  ACTIVE: 1
};

export const MYTASKS_STATUS = {
  ALL: -1,
  ACTIVE: 0,
  INREVIEW: 1,
  APPROVED: 2
};

export const queryParams: Record<string, number | string> = {
  page: 1,
  perPage: 5
};
