import * as yup from "yup";

import { requiredValidator, requiredValidatorSelect, emailValidator } from "utils/validators";

export const schema = yup.object().shape({
  Name: requiredValidator,
  Description: requiredValidator,
  IndustryType: requiredValidator,
  CompanyType: requiredValidator,
  CompanySize: requiredValidator,
  Web: requiredValidator,
  Contact: requiredValidator,
  Email: emailValidator,
  IdCountry: requiredValidatorSelect
});
