import { useEffect, useState } from "react";

import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { formatDate } from "utils/formatDate";

import { getAllJobApplications } from "state/actions/jobsActions";
import { selectGetAllJobsApplicationsSuccess } from "state/selectors/jobsSelectors";

import DataField from "components/DataField";
import Divider from "components/Divider";
import Loading from "components/Loading";

import { IMyJob, IMyJobs } from "types/job";

const MyJobsList = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [first, setFirst] = useState<number>(0);

  const myJobs = useAppSelector(selectGetAllJobsApplicationsSuccess) as IMyJobs;

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };
    dispatch(getAllJobApplications({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    getData({ page: page });
  };

  useEffect(() => {
    getData({});
    setFirst(0);
  }, []);

  const myJobsToDisplay = myJobs?.jobs;

  if (!myJobsToDisplay) {
    return null;
  }

  /* const applyToJob = (jobId: number) => {
    const jobApplicationsCreatorData = {
      IdJob: parseInt(jobId as unknown as string)

    };
    dispatch(jobApplicationsCreator(jobApplicationsCreatorData)).then(() => dispatch(jobApplicationsInit()));
  };*/

  return (
    <>
      {!myJobsToDisplay ? (
        <Loading />
      ) : (
        <div>
          {myJobsToDisplay.map((job: IMyJob, index: number) => (
            <div key={index} className="relative">
              {index > 0 && <Divider className="mb-4 mt-4" />}
              <div className="w-full">
                <div className="flex gap-4 mb-6 flex-column md:flex-row">
                  <div className="pt-4 md:w-6 flex md:flex-column md:gap-4">
                    <DataField label={t("job.title")} value={job.JobName} />
                    <DataField textField label={t("common.description")} value={job.JobDescription} />
                  </div>
                  <div className="pt-4 md:w-6 flex flex-column gap-4">
                    <div className="flex w-full gap-2 md:gap-0 flex-column md:flex-row">
                      <DataField label={t("job.organizationName")} value={job.OrganizationName} />
                      <DataField textField label={t("common.applicationType")} value={job.ApplicationType} />
                    </div>
                    <div className="flex w-full">
                      <DataField className="break-text" label={t("job.organizationWeb")} value={job.OrganizationWeb} />
                    </div>
                    <div className="flex w-full">
                      <DataField label={t("common.startDate")} value={formatDate(job.DateTime)} />
                      <DataField
                        status={job.Status === 1 ? "active" : "inactive"}
                        label={t("common.status")}
                        value={job.Status === 1 ? t("common.active") : t("common.notActive")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {myJobs?.total > 5 && (
        <Paginator first={first} rows={5} totalRecords={myJobs.total} onPageChange={handlePageChange} />
      )}
    </>
  );
};

export default MyJobsList;
