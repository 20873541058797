import { useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";

import { activateUser } from "state/actions/authActions";
import { selectActivationUserRequest, selectActivationUserSuccess } from "state/selectors/authSelectors";

import Loading from "components/Loading";

export default function Activate() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const activationRequest = useAppSelector(selectActivationUserRequest) as boolean;
  const activationSuccess = useAppSelector(selectActivationUserSuccess) as boolean;

  useEffect(() => {
    const action = searchParams.get("action");
    if (action) {
      dispatch(activateUser({ ActionToken: action }));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    navigate("/login");
  }, [activationSuccess]);

  if (activationRequest) {
    return <Loading />;
  }

  return null;
}
