import * as yup from "yup";

import { requiredValidator, requiredValidatorSelect, requiredValidatorSelectMulti } from "utils/validators";

export const schema = yup.object().shape({
  Title: requiredValidator,
  EmploymentType: requiredValidator,
  CompanyName: requiredValidator,
  Location: requiredValidator,
  Industry: requiredValidator,
  Description: requiredValidator,
  StartMonth: requiredValidatorSelect,
  StartYear: requiredValidatorSelect,
  Skills: requiredValidatorSelectMulti,
  ICurrentlyWorkHere: yup.boolean().notRequired(),
  EndMonth: yup
    .number()
    .when("ICurrentlyWorkHere", (ICurrentlyWorkHere, schema) =>
      ICurrentlyWorkHere === true ? schema.notRequired() : requiredValidatorSelect
    ),
  EndYear: yup
    .number()
    .when("ICurrentlyWorkHere", (ICurrentlyWorkHere, schema) =>
      ICurrentlyWorkHere === true ? schema.notRequired() : requiredValidatorSelect
    )
});
