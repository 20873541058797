import { useState } from "react";

import { Sidebar } from "primereact/sidebar";
import { Link, useNavigate } from "react-router-dom";

import { useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { selectGetUserDataSuccess } from "state/selectors/usersSelectors";

import Button from "components/Button";

import { ReactComponent as Logo } from "images/svgs/logo.svg";
import { ReactComponent as Name } from "images/svgs/name2.svg";

import { IUserData } from "types/user";

import AdminReport from "./components/AdminReports";
import CommunityDropdown from "./components/CommunityDropdown";
import MyPersonalDropdown from "./components/MyPersonalDropdown";
import OrganizationDropdown from "./components/OrganizationDropdown";
import ProfileDropdown from "./components/ProfileDropdown";


import "./components/MyPersonalDropdown/MyPersonalDropdown.css";

export default function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useAppSelector(selectGetUserDataSuccess) as IUserData;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const customIcons = (

    <Link to="/" className="absolute flex align-items-center  justify-content-between  top-50% left-0 f ml-4">
      <Logo className="fill-white mr-2" width={40} />
      <Name className="fill-white mt-1" />
    </Link>

  );

  function getHeaderLinks() {
    return [{ id: 1, label: "menu.jobsBoard", link: "/jobs-board" }];
  }
  return (
    <header className="bg-primary-900">
      <div className="container mx-auto h-5rem flex justify-content-between align-items-center ">
        <div className="flex flex-wrap align-items-center  gap-4 lg:gap-1">
          <Link to="/" className="flex flex-order-1  lg:flex-order-0 xl:flex-order-0 align-items-center mr-14">
            <Logo className="fill-white mr-2" width={40} />
            <Name className="fill-white mt-1" />
          </Link>
          <nav >
            <ul className="lg:flex xl:flex flex-order-1 hidden text-white align-items-center justify-content-center ml-4">
              {user &&
                getHeaderLinks().map((links) => (
                  <li key={links.id}>
                    <Button
                      type="button"
                      label={t(links.label)}
                      link
                      className="text-white  justify-content-left"
                      onClick={() => navigate(links.link)}
                    />
                  </li>
                ))}
              <li>
                <MyPersonalDropdown toggleSidebar={() => {
                  setIsSidebarOpen(false);
                }} />
              </li>
              <li>
                <OrganizationDropdown toggleSidebar={() => {
                  setIsSidebarOpen(false);
                }} />
              </li>
              <li>
                <CommunityDropdown toggleSidebar={() => {
                  setIsSidebarOpen(false);
                }} />
              </li>
              {user?.IdRole === 1 && (
                <li>
                  <AdminReport toggleSidebar={() => {
                    setIsSidebarOpen(false);
                  }} />
                </li>
              )}
            </ul>
            <div className="lg:hidden md:flex-order-0 flex">
              <Button
                icon="pi pi-bars"
                rounded
                text
                className="p-mr-2 d-md-none text-white text-md"
                onClick={toggleSidebar}
              />
              <Sidebar visible={isSidebarOpen} pt={{
                header: { className: "bg-primary-900 h-5rem" },
                content: { className: "fill-white pt-4 w-full" }
              }} onHide={toggleSidebar} position="left" className="lg:hidden xl:hidden" style={{ background: "#f8f9fa" }} icons={customIcons}>

                {user &&
                  getHeaderLinks().map((links) => (
                    <div className="p-component p-panelmenu w-full" key={links.id}>
                      <div className="p-panelmenu-panel">
                        <div className="p-component p-panelmenu-header">
                          <a className="p-panelmenu-header-link" href="#" onClick={() => navigate(links.link)}>
                            <span className="p-menuitem-text" onClick={toggleSidebar}>{t(links.label)}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                <MyPersonalDropdown toggleSidebar={() => {
                  setIsSidebarOpen(false);
                }} />
                <OrganizationDropdown toggleSidebar={() => {
                  setIsSidebarOpen(false);
                }} />
                <CommunityDropdown toggleSidebar={() => {
                  setIsSidebarOpen(false);
                }} />
                {user?.IdRole === 1 && (
                  <AdminReport toggleSidebar={() => {
                    setIsSidebarOpen(false);
                  }} />
                )}
              </Sidebar>
            </div>
          </nav>
        </div>
        <div className="flex justify-content-center align-items-center">
          <ProfileDropdown />
        </div>
      </div>
    </header>
  );
}
