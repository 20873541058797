import { useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { exportReport } from "utils/exportFile";

import { getOrganizationReport, getOrganizationReportActivities } from "state/actions/dashboardActions";
import {
  selectGetOrganizationReportActivitiesRequest,
  selectGetOrganizationReportActivitiesSucces,
  selectGetOrganizationReportRequest,
  selectGetOrganizationReportSucces
} from "state/selectors/dashboardSelectors";
import { selectUpdateOrganizationReportSucces } from "state/selectors/dashboardSelectors";

import SubmitReportModal from "views/Organization/components/SubmitReportModal";
import PrintReport from "views/Reports/components/PrintReport";
import ReportActivityTable from "views/Reports/components/ReportActivityTable";

import Button from "components/Button";
import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

import { IActivityReports, IMReports } from "types/report";

import ReportDetails from "../ReportDetails";

export default function Report() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const reportId = params.id as string;
  const { t } = useTranslation();
  const { toast } = useToast();

  const componentRef = useRef<any>();
  const [showSubmitReport, setShowSubmitReport] = useState(false);

  const reportsRequest = useAppSelector(selectGetOrganizationReportRequest) as boolean;
  const report = useAppSelector(selectGetOrganizationReportSucces) as IMReports;

  const reportActivityRequest = useAppSelector(selectGetOrganizationReportActivitiesRequest) as boolean;
  const reportActivitySuccess = useAppSelector(selectGetOrganizationReportActivitiesSucces) as IActivityReports;

  const updateOrganizationReport = useAppSelector(selectUpdateOrganizationReportSucces) as boolean;

  const reportData = useMemo(() => {
    return reportActivitySuccess?.report_activities?.map((item) => ({
      "Project Name": item.ProjectName,
      "Task Name": item.TaskName,
      Hours: parseInt(item.Hours),
      Date: item.ActivityDate
    }));
  }, [reportActivitySuccess?.report_activities]);

  const handleExportPdf = useReactToPrint({
    content: () => componentRef?.current
  });

  const handleExportExcel = () => {
    return exportReport(reportData, "Report_", t);
  };

  useEffect(() => {
    dispatch(getOrganizationReportActivities({ reportId }));
    dispatch(getOrganizationReport({ reportId }));
  }, [reportId]);

  useEffect(() => {
    if (updateOrganizationReport) {
      dispatch(getOrganizationReport({ reportId }));
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("myreports.updated"),
        life: 3000
      });
    }
  }, [updateOrganizationReport]);

  if (reportsRequest) {
    return (
      <div className="h-full">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <section className="container mx-auto px-2 md:px-0">
        <SectionTitle>
          <div className="relative flex justify-content-between align-items-center w-full">
            {t("myreports.reportDetails")}
            <div>
              <Button
                icon="pi pi-file-pdf"
                text
                className="text-900 mr-1"
                tooltip="Export to pdf"
                tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
                onClick={handleExportPdf}
              >
                <span className="text-900 p-button-label hidden md:flex ml-2">Export to pdf</span>
              </Button>
              <Button
                icon="pi pi-file-excel"
                text
                className="text-900 mr-0 md:mr-1"
                tooltip="Export to excel"
                tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
                onClick={handleExportExcel}
              >
                <span className="text-900 p-button-label hidden md:flex ml-2">Export to excel</span>
              </Button>
            </div>
          </div>
        </SectionTitle>

        <div className="card pt-0">{<ReportDetails myReport={report} setShowSubmitReport={setShowSubmitReport} />}</div>
      </section>
      <section className="container mx-auto px-2 md:px-0">
        <SectionTitle>
          <div className="relative flex justify-content-between align-items-center w-full">
            {t("myreports.reportActivity")}
          </div>
        </SectionTitle>
        <div className="card">
          <ReportActivityTable
            reportActivityRequest={reportActivityRequest}
            reportActivitySuccess={reportActivitySuccess}
          />
        </div>
        <div style={{ display: "none" }}>
          <div className="card" ref={componentRef}>
            <PrintReport report={report} reportActivities={reportActivitySuccess} />
          </div>
        </div>
      </section>
      <SubmitReportModal
        show={showSubmitReport}
        closeModal={() => setShowSubmitReport(false)}
        report={report?.myreport[0]}
      />
    </>
  );
}
