import { useEffect } from "react";

import { useAppDispatch } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { getMyReports } from "state/actions/dashboardActions";

import SectionTitle from "components/SectionTitle";

import MyReportsTable from "./MyReportsTable";

interface IMyReportsProps {
  showTitle?: boolean;
}

export default function MyReports({ showTitle = true }: IMyReportsProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMyReports({ params: { page: 1, perPage: 5 } }));
  }, []);

  return (
    <section>
      {showTitle && <SectionTitle>{t("myreports.myReports")}</SectionTitle>}
      <div className="card">
        <MyReportsTable />
      </div>
    </section>
  );
}
