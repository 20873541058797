export const GET_PROJECTS = "getProjects";
export const CREATE_PROJECT = "createProject";
export const UPDATE_PROJECT = "updateProject";
export const GET_PROJECT_INFO = "getProjectInfo";
export const GET_PROJECT_TASKS = "getProjectTasks";
export const GET_PROJECT_TASK_INFO = "getProjectTaskInfo";
export const DELETE_PROJECT_TASK = "deleteProjectTask";
export const CREATE_PROJECT_TASK = "createProjectTask";
export const UPDATE_PROJECT_TASK = "updateProjectTask";
export const GET_PROJECT_POSITIONS = "getProjectPositions";
export const GET_PROJECT_CREATORS = "getProjectCreators";
export const GET_MY_TASKS = "getMyTasks";
export const GET_MY_TASK_INFO = "getMyTaskInfo";
export const GET_MY_PROJECTS = "getMyProjects";
