import { Dialog as PrimeDialog } from "primereact/dialog";

export default function Dialog({ children, footerContent, show, closeModal, title }: any) {
  return (
    <PrimeDialog
      header={title}
      visible={show}
      style={{ width: "50vw" }}
      onHide={closeModal}
      footer={footerContent}
      blockScroll
      breakpoints={{ "960px": "100vw", "641px": "75vw" }}
      draggable={false}
    >
      {children}
    </PrimeDialog>
  );
}
