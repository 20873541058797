import { useEffect, useState } from "react";

import { PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { getBountiesHistoryRequests } from "state/actions/dashboardActions";
import {
  selectClaimBountyDataFail,
  selectClaimBountyDataSuccess,
  selectGetBountiesHistoryRequestsDataSuccess
} from "state/selectors/dashboardSelectors";

import Button from "components/Button";
import SectionTitle from "components/SectionTitle";

import { IBounties } from "types/bounty";

import ClaimBountyModal from "./components/ClaimBountyModal";
import MyBountiesList from "./components/MyBountiesList";

export default function Bounties() {
  const { t } = useTranslation();
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const [first, setFirst] = useState<number>(0);

  const claimBountySuccess = useAppSelector(selectClaimBountyDataSuccess);
  const claimBountyFail = useAppSelector(selectClaimBountyDataFail);
  const getMyBountiesDataSuccess = useAppSelector(selectGetBountiesHistoryRequestsDataSuccess) as IBounties;

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  useEffect(() => {
    if (claimBountySuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("bounty.created"),
        life: 3000
      });
      getData({});
    }
  }, [claimBountySuccess]);

  useEffect(() => {
    if (claimBountyFail) {
      toast?.current?.show({
        severity: "error",
        summary: t("common.error"),
        detail: t("bounty.failedToCreateBountyRequest"),
        sticky: true
      });
    }
  }, [claimBountyFail]);

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };

    dispatch(getBountiesHistoryRequests({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    getData({ page: page });
  };

  useEffect(() => {
    getData({});
  }, []);

  return (
    <section className="container mx-auto">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("mybounties.mybountiesRequests")}
          <div className="flex">
          <Button
            icon="pi pi-plus"
            text
            className="text-900"
            tooltip={t("mybounties.claimnewbounty")}
            type="button"
            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => setShowEditModal(true)}
          ><span className="text-900 p-button-label hidden md:flex ml-2"> {t("mybounties.claimnewbounty")}</span></Button>
          </div>
        </div>
      </SectionTitle>
      <MyBountiesList bounties={getMyBountiesDataSuccess} handlePageChange={handlePageChange} first={first} />
      <ClaimBountyModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
      />
    </section>
  );
}
