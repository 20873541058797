import { useEffect, useState } from "react";

import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { createJobInit, getJobs, updateJobInit } from "state/actions/jobsActions";
import {
  selectCreateJobSuccess,
  selectGetJobsDataRequest,
  selectGetJobsDataSuccess,
  selectUpdateJobSuccess
} from "state/selectors/jobsSelectors";

import Button from "components/Button";
import Divider from "components/Divider";
import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

import { IJob } from "types/job";

import JobDetails from "../JobDetails";
import JobModal from "../JobModal";

export default function OrganizationJobs() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const [first, setFirst] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const jobs = useAppSelector(selectGetJobsDataSuccess) as any;
  const jobsRequest = useAppSelector(selectGetJobsDataRequest) as boolean;

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };
    dispatch(getJobs({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setPage(event.page + 1);
    getData({ page: event.page + 1 });
  };

  useEffect(() => {
    getData({});
    setFirst(0);
  }, []);

  const jobsToDisplay = jobs?.jobs;

  const updateJobsDataSuccess = useAppSelector(selectUpdateJobSuccess);
  const createJobSuccess = useAppSelector(selectCreateJobSuccess);

  useEffect(() => {
    if (updateJobsDataSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("job.updated"),
        life: 3000
      });
      dispatch(updateJobInit());
      getData({ page });
    }
    if (createJobSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("job.created"),
        life: 3000
      });
      dispatch(createJobInit());
      getData({ page });
    }
  }, [updateJobsDataSuccess, createJobSuccess]);

  if (jobsRequest) {
    return (
      <div className="h-full">
        <Loading />
      </div>
    );
  }

  return (
    <section className="container mx-auto px-2 md:px-0">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("menu.jobs")}
          <Button
            icon="pi pi-plus"
            text
            className="text-900"
            tooltip="Add new job"
            type="button"
            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <span className="text-900 p-button-label hidden md:flex ml-2">Add new job</span>
          </Button>
        </div>
      </SectionTitle>

      <div className="card">
        {jobsToDisplay?.map((job: IJob, index: number) => (
          <div key={index} className="pb-5">
            {index > 0 && <Divider className="mb-4" />}
            <JobDetails {...job} />
          </div>
        ))}
        {!jobsToDisplay && (
          <div className="w-full">
            <div className="relative flex align-items-center justify-between">
              <span className="text-purple">{t("job.noJob")}</span>
            </div>
          </div>
        )}
        {jobs?.total > 5 && (
          <Paginator first={first} rows={5} totalRecords={jobs.total} onPageChange={handlePageChange} />
        )}
      </div>
      <JobModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
        job={null}
      />
    </section>
  );
}
