import { useRef } from "react";

import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { useNavigate } from "react-router-dom";

import useTranslation from "hooks/useTranslation";

import Button from "components/Button";

interface IAdminReportProps {
  toggleSidebar: () => void;
}


export default function AdminRepor({ toggleSidebar }: IAdminReportProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const menu = useRef<Menu>(null);

  const navFunc = (link: string) => {
    toggleSidebar();
    navigate(link);
  };
  const items = [
    {
      label: t("menu.reports"),
      command: () => {
        navFunc("/admin-reports");
      }
    }
  ];

  return (
    <>
    <div className="lg:flex xl:flex justify-content-center relative hidden">
      <Menu model={items} popup ref={menu} id="menu" popupAlignment="left" />
      <Button
        type="button"
        label={t("menu.admin")}
        link
        className="text-white"
        onClick={(event: React.MouseEvent<HTMLElement>) => menu?.current?.toggle(event)}
      />
    </div>
      <div className="md:flex sm:flex justify-content-between lg:hidden xl:hidden">
      <PanelMenu model={items} className="w-full"/>
    </div>
    </>
  );
}
