import { combineReducers } from "@reduxjs/toolkit";

import {
  CREATE_REPORT,
  CREATE_REPORT_ACTIVITY,
  DELETE_REPORT,
  DELETE_REPORT_ACTIVITY,
  GET_MY_BOUNTIES,
  GET_MY_CONTRACTS,
  GET_MY_REPORT,
  GET_MY_REPORTS,
  GET_MY_REPORT_ACTIVITIES,
  GET_MY_STAKES,
  GET_ORGANIZATION_REPORT,
  GET_ORGANIZATION_REPORTS,
  GET_ORGANIZATION_REPORT_ACTIVITIES,
  GET_REPORT_ACTIVITIES,
  GET_REPORT_ACTIVITY,
  UPDATE_ORGANIZATION_REPORT,
  UPDATE_REPORT,
  UPDATE_REPORT_ACTIVITY,
  GET_VDM,
  GET_BOUNTIES_REQUESTS,
  GET_BOUNTIES_HISTORY_REQUESTS,
  CLAIM_BOUNTY,
  GET_BOUNTY_STATUSES,
  GET_BOUNTY_TYPES,
  GET_MEMBERS_LIST,
  UPDATE_BOUNTY_STATUS,
  GET_ADMIN_REPORTS,
  GET_ADMIN_REPORT,
  ADD_REPORT_PAYMENT
} from "state/constants/dashboardConstants";
import reducer from "state/utils/reducer";

export default combineReducers({
  getMyStakes: reducer(`dashboard/${GET_MY_STAKES}`),
  getMyBounties: reducer(`dashboard/${GET_MY_BOUNTIES}`),
  getMyReports: reducer(`dashboard/${GET_MY_REPORTS}`),
  getMyReport: reducer(`dashboard/${GET_MY_REPORT}`),
  createReport: reducer(`dashboard/${CREATE_REPORT}`),
  updateReport: reducer(`dashboard/${UPDATE_REPORT}`),
  getMyContracts: reducer(`dashboard/${GET_MY_CONTRACTS}`),
  createReportActivity: reducer(`dashboard/${CREATE_REPORT_ACTIVITY}`),
  updateReportActivity: reducer(`dashboard/${UPDATE_REPORT_ACTIVITY}`),
  getReportActivities: reducer(`dashboard/${GET_REPORT_ACTIVITIES}`),
  getMyReportActivities: reducer(`dashboard/${GET_MY_REPORT_ACTIVITIES}`),
  getReportActivity: reducer(`dashboard/${GET_REPORT_ACTIVITY}`),
  deleteReport: reducer(`dashboard/${DELETE_REPORT}`),
  deleteReportActivity: reducer(`dashboard/${DELETE_REPORT_ACTIVITY}`),
  getOrganizationReports: reducer(`dashboard/${GET_ORGANIZATION_REPORTS}`),
  getOrganizationReport: reducer(`dashboard/${GET_ORGANIZATION_REPORT}`),
  updateOrganizationReport: reducer(`dashboard/${UPDATE_ORGANIZATION_REPORT}`),
  getOrganizationReportActivities: reducer(`dashboard/${GET_ORGANIZATION_REPORT_ACTIVITIES}`),
  getVdm: reducer(`dashboard/${GET_VDM}`),
  getBountiesRequests: reducer(`dashboard/${GET_BOUNTIES_REQUESTS}`),
  getBountiesHistoryRequests: reducer(`dashboard/${GET_BOUNTIES_HISTORY_REQUESTS}`),
  claimBounty: reducer(`dashboard/${CLAIM_BOUNTY}`),
  getBountyStatuses: reducer(`dashboard/${GET_BOUNTY_STATUSES}`),
  getBountyTypes: reducer(`dashboard/${GET_BOUNTY_TYPES}`),
  getMembersList: reducer(`dashboard/${GET_MEMBERS_LIST}`),
  updateBountyStatus: reducer(`dashboard/${UPDATE_BOUNTY_STATUS}`),
  getAdminReports: reducer(`dashboard/${GET_ADMIN_REPORTS}`),
  getAdminReport: reducer(`dashboard/${GET_ADMIN_REPORT}`),
  addReportPayment: reducer(`dashboard/${ADD_REPORT_PAYMENT}`)
});
