import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

export const init = (prefix: string) => createAction(prefix)();

export const action = (
  prefix: string,
  service: (params: any) => Promise<AxiosResponse<any, any> | undefined>,
  params = {},
  other: any = {}
) => {
  return createAsyncThunk(prefix, async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await service(params);

      if (other.customAction) {
        other.customAction(response);
      }
      if (other.customDispatch) {
        dispatch(other.customDispatch());
      }
      return response?.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err?.response?.data);
    }
  })();
};
