import { KeyboardEvent, ReactNode, useEffect, useRef } from "react";

import "./Modal.css";

/* ********** MODAL WRAPPER ********** */
interface ModalProps {
  children: ReactNode;
  show: boolean;
  closeModal: () => void;
  overflow?: boolean;
}

export function Modal({ children, show, closeModal, overflow = false }: ModalProps) {
  const refModal = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      document.body.classList.add("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [show]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (refModal.current !== null) {
        refModal.current.focus();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [show]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  return (
    <div
      id="modal"
      className={`modal-wrapper ${show ? "modal-wrapper-opened" : ""}`}
      role="presentation"
      data-testid="modal"
    >
      <div
        ref={refModal}
        tabIndex={0}
        role="button"
        onKeyDown={handleKeyDown}
        className={`modal ${show ? "modal-opened" : ""}`}
      >
        <div className={`content ${overflow ? "overflow" : ""}`}>{children}</div>
      </div>
    </div>
  );
}

/* ********** MODAL WRAPPER ********** */

/* ********** MODAL HEADER ********** */

interface ModalHeaderProps {
  title: string;
}

export function ModalHeader({ title }: ModalHeaderProps) {
  return (
    <>
      <div className="modal-header font-semibold">
        {title}
        <div className="absolute w-full -bottom-6 z-10 bg-gradient-to-b from-white to-transparent h-6"></div>
      </div>
    </>
  );
}

/* ********** MODAL HEADER ********** */

/* *********;* MODAL BODY ********** */

interface ModalBodyProps {
  children: ReactNode;
  className?: string;
  show: boolean;
  overflow?: boolean;
}

export function ModalBody({ children, className = "", show, overflow = false }: ModalBodyProps) {
  const refModalBody = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (refModalBody.current !== null) {
        refModalBody.current.scrollTop = 0;
      }
    }, 250);
  }, [show]);

  return (
    <div className={`modal-body ${className} ${overflow ? "overflow" : ""}`} ref={refModalBody}>
      {children}
    </div>
  );
}

/* ********** MODAL BODY ********** */

/* ********** MODAL FOOTER ********** */

interface ModalFooterProps {
  children: ReactNode;
  overflow?: boolean;
}

export function ModalFooter({ children, overflow = false }: ModalFooterProps) {
  return (
    <div className="modal-footer">
      {!overflow && <div className="absolute w-full -top-6 z-10 bg-gradient-to-t from-white to-transparent h-6" />}
      {children}
    </div>
  );
}
