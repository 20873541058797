/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";

import { useIntl } from "react-intl";

import { TranslationKey } from "langs";

export interface TranslateFunction {
  (translationId: TranslationKey | string, options?: Record<string, any>): string;
}
interface PluralFunction {
  (value: number, plural: string, singular: string): string;
}
interface TranslationHook {
  t: TranslateFunction;
  fp: PluralFunction;
}

const languageCase = ({
  count,
  language,
  singular,
  plural
}: {
  count: number;
  language: string;
  singular: string;
  plural: string;
}): string => {
  switch (language) {
    case "bs":
      return count !== 11 && +(count + "").slice(-1) === 1 ? singular : plural;
    default:
      return plural;
  }
};

const useTranslation = (): TranslationHook => {
  const intl = useIntl();

  const t: TranslateFunction = useCallback(
    (translationId, options) => {
      return intl.formatMessage(
        {
          id: translationId || "fallback.default"
        },
        {
          ...options
        }
      ) as string;
    },
    [intl]
  );

  const fp: PluralFunction = (count, singular, plural) => {
    const pluralCategory = intl.formatPlural(count);
    const formattingOptions = {
      one: singular,
      other: languageCase({ count, language: intl.locale, singular, plural })
    };
    return formattingOptions[pluralCategory as "one"] || formattingOptions.other;
  };

  return { t, fp };
};

export default useTranslation;
