import { useState } from "react";

import useCommon from "hooks/useCommon";
import { useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { selectGetOrganizationSuccess } from "state/selectors/organizationsSelectors";

import Button from "components/Button";
import DataField from "components/DataField";

import { IOrganization } from "types/organization";

import OrganizationModal from "../OrganizationModal";

export default function OrganizationInfo() {
  const { t } = useTranslation();
  const { countries } = useCommon();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const organization = useAppSelector(selectGetOrganizationSuccess) as IOrganization;

  const country =
    organization && (countries?.find((country) => country?.value === organization?.IdCountry)?.label as string);

  return (
    <div className="flex w-full relative flex-column md:flex-row">
      <div className="absolute top-0 right-0 p-2 md:p-3 -mr-5">
        <Button
          icon="pi pi-file-edit"
          rounded
          text
          className="h-2rem w-2rem text-900 mr-1 pt-0"
          tooltip="Edit organization"
          tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
          onClick={() => {
            setShowEditModal(true);
          }}
        />
      </div>
      <div className="w-full mt-3 gap-4 flex md:flex-column">
        <DataField label={t("common.name")} value={organization.Name} />
        <DataField label={t("common.description")} value={organization.Description} textField />
      </div>
      <div className="w-full gap-4 mt-3 flex flex-column">
        <DataField label={t("common.web")} value={organization.Web} className="break-text" />
        <div className="flex w-full flex-column md:flex-row gap-4 md:gap-0">
          <DataField label={t("organization.companyType")} value={organization.CompanyType} />
          <DataField label={t("organization.industry")} value={organization.IndustryType} />
        </div>
        <div className="flex w-full gap-4 md:gap-0">
          <DataField label={t("organization.companySize")} value={organization.CompanySize} />
          <DataField label={t("form.country")} value={country} />
        </div>
        <DataField label={t("organization.contact")} value={organization.Contact} />
        <DataField label={t("organization.email")} value={organization.Email} />
      </div>
      <OrganizationModal show={showEditModal} closeModal={() => setShowEditModal(false)} />
    </div>
  );
}
