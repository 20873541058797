import { useRef } from "react";

import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { loginUserInit, logoutUser } from "state/actions/authActions";
import { selectGetUserDataSuccess } from "state/selectors/usersSelectors";

import Avatar from "components/Avatar";

import { IUserData } from "types/user";

export default function ProfileDropdown() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const user = useAppSelector(selectGetUserDataSuccess) as IUserData;
  const menu = useRef<Menu>(null);

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(loginUserInit());
  };

  const items = [
    {
      label: t("menu.profile"),
      icon: "pi pi-fw pi-user",
      command: () => {
        navigate("/profile");
      }
    },
    {
      label: t("menu.changePassword"),
      icon: "pi pi-fw pi-sync",
      command: () => {
        navigate("/change-password");
      }
    },
    {
      label: t("menu.logout"),
      icon: "pi pi-fw pi-sign-out",
      command: () => {
        handleLogout();
      }
    }
  ];

  return (
    <>
    <div className="flex justify-content-center  mr-3">
      <Menu model={items} popup ref={menu} id="menu" popupAlignment="right" className="md:w-max sm:w-max w-full mt-3 border-noround md:border-round"/>
      <Avatar
        onClick={(event: React.MouseEvent<HTMLElement>) => menu?.current?.toggle(event)}
        label={user?.FirstName.charAt(0) + user?.LastName.charAt(0)}
        shape="circle"
        size="medium"
        style={{ backgroundColor: "var(--primary-700)", color: "var(--surface-0)" }}
      >
        {/* <PrimeBadge value="4" /> */}
      </Avatar>
    </div>

    </>
  );
}
