import { combineReducers } from "@reduxjs/toolkit";
import { decodeToken } from "react-jwt";

import { STATUS } from "utils/constants";
import { getToken, removeToken } from "utils/token";

import {
  ACTIVATE_USER,
  CHANGE_USER_PASSWORD,
  DEACTIVATE_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  RESET_PASSWORD,
  RESET_USER_PASSWORD,
  LIST_INVITED_MEMBERS,
  INVITE_MEMBER,
  DELETE_INVITE,
  GET_INVITE_DETAILS,
  REGISTER_INVITE
} from "state/constants/authConstants";
import reducer from "state/utils/reducer";

export default combineReducers({
  login: reducer(`auth/${LOGIN_USER}`, {
    [`auth/${LOGOUT_USER}`]: (state: Record<string, unknown>) => {
      removeToken();
      return { ...state, data: null };
    },
    [`auth/${LOGIN_USER}/fulfilled`]: () => {
      const token = getToken();
      const user = decodeToken(token as string) as object;
      return { data: { token: token, ...user }, status: STATUS.FULFILLED, error: null };
    }
  }),
  register: reducer(`auth/${REGISTER_USER}`),
  activate: reducer(`auth/${ACTIVATE_USER}`),
  deactivate: reducer(`auth/${DEACTIVATE_USER}`),
  resetPassword: reducer(`auth/${RESET_PASSWORD}`),
  resetUserPassword: reducer(`auth/${RESET_USER_PASSWORD}`),
  changeUserPassword: reducer(`auth/${CHANGE_USER_PASSWORD}`),
  listInvitedMembers: reducer(`auth/${LIST_INVITED_MEMBERS}`),
  inviteMember: reducer(`auth/${INVITE_MEMBER}`),
  deleteInvite: reducer(`auth/${DELETE_INVITE}`),
  getInviteDetails: reducer(`auth/${GET_INVITE_DETAILS}`),
  registerInvite: reducer(`auth/${REGISTER_INVITE}`)
});
