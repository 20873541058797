import { useEffect, useState } from "react";

import { PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { getBountiesRequests } from "state/actions/dashboardActions";
import {
  selectGetBountiesRequestsDataRequest,
  selectGetBountiesRequestsDataSuccess,
  selectUpdateBountyStatusRequest
} from "state/selectors/dashboardSelectors";

import SectionTitle from "components/SectionTitle";

import { IBounties } from "types/bounty";

import BountiesList from "./components/BountiesList";

export default function Bounties() {
  const { t } = useTranslation();
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const [first, setFirst] = useState<number>(0);

  const getMyBountiesRequestsDataSuccess = useAppSelector(selectGetBountiesRequestsDataSuccess) as IBounties;
  const getMyBountiesRequestsDataRequest = useAppSelector(selectGetBountiesRequestsDataRequest) as boolean;
  const updateBounty = useAppSelector(selectUpdateBountyStatusRequest) as boolean;

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };
    dispatch(getBountiesRequests({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    getData({ page: page });
  };

  useEffect(() => {
    getData({});
  }, []);

  useEffect(() => {
    if (updateBounty) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("bounty.claimBountyResponse"),
        life: 3000
      });
      getData({});
    }
  }, [updateBounty, getMyBountiesRequestsDataSuccess]);

  return (
    <section className="container mx-auto">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("mybounties.bountiesRequests")}
        </div>
      </SectionTitle>
      <BountiesList
        bounties={getMyBountiesRequestsDataSuccess}
        bountiesRequests={getMyBountiesRequestsDataRequest}
        handlePageChange={handlePageChange}
        first={first}
      />
    </section>
  );
}
