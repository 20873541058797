import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ROUTE_TYPE } from "utils/constants";

import AdminReports from "views/AdminReports";
import AdminReport from "views/AdminReports/components/AdminReport";
import Activate from "views/AuthViews/Activate";
import ChangePassword from "views/AuthViews/ChangePassword";
import Deactivate from "views/AuthViews/Deactivate";
import ForgotPassword from "views/AuthViews/ForgotPassword";
import Login from "views/AuthViews/Login";
import Register from "views/AuthViews/Register";
import ResetPassword from "views/AuthViews/ResetPassword";
import Community from "views/Community/Community";
import Invitation from "views/Community/components/Invitation";
import Invites from "views/Community/components/Invites";
import Contracts from "views/Contracts";
import Home from "views/Home";
import ImportTasks from "views/Home/components/ImportTasks";
import JobsBoard from "views/JobsBoard";
import Organization from "views/Organization";
import OrganizationContracts from "views/Organization/components/Contracts";
import JobApplications from "views/Organization/components/JobApplications";
import OrganizationJobs from "views/Organization/components/Jobs";
import OrganizationReport from "views/Organization/components/Report";
import OrganizationReports from "views/Organization/components/Reports";
import Profile from "views/Profile";
import Projects from "views/Projects";
import Project from "views/Projects/components/Project";
import Reports from "views/Reports";
import Report from "views/Reports/components/Report";

import AuthWrapper from "components/AuthWrapper";

import AuthLayout from "layouts/Auth";
import MainLayout from "layouts/MainLayout";

const routes = [
  {
    id: "login",
    path: "/login",
    Component: Login,
    Layout: AuthLayout,
    routeType: ROUTE_TYPE.PUBLIC_LOGOUT
  },
  {
    id: "register",
    path: "/register",
    Component: Register,
    Layout: AuthLayout,
    routeType: ROUTE_TYPE.PUBLIC_LOGOUT
  },
  {
    id: "forgot-password",
    path: "/forgot-password",
    Component: ForgotPassword,
    Layout: AuthLayout,
    routeType: ROUTE_TYPE.PUBLIC_LOGOUT
  },
  {
    id: "deactivate",
    path: "/deactivate",
    Component: Deactivate,
    Layout: AuthLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "activate",
    path: "/activate",
    Component: Activate,
    Layout: AuthLayout,
    routeType: ROUTE_TYPE.PUBLIC_LOGOUT
  },
  {
    id: "resetpassword",
    path: "/resetpassword",
    Component: ResetPassword,
    Layout: AuthLayout,
    routeType: ROUTE_TYPE.PUBLIC
  },
  {
    id: "change-password",
    path: "/change-password",
    Component: ChangePassword,
    Layout: AuthLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "home",
    path: "/",
    Component: Home,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "profile",
    path: "/profile",
    Component: Profile,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "projects",
    path: "/projects",
    Component: Projects,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "import-tasks",
    path: "/import-tasks",
    Component: ImportTasks,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "project",
    path: "/projects/:id",
    Component: Project,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "jobs-board",
    path: "/jobs-board",
    Component: JobsBoard,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "contracts",
    path: "/contracts",
    Component: Contracts,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "reports",
    path: "/reports",
    Component: Reports,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "Report",
    path: "/reports/:id",
    Component: Report,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "admin-reports",
    path: "/admin-reports",
    Component: AdminReports,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "Admin-Report",
    path: "/admin-reports/:id",
    Component: AdminReport,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "organization",
    path: "/organization",
    Component: Organization,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "organization-jobs",
    path: "/organization/jobs",
    Component: OrganizationJobs,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.CLIENT
  },
  {
    id: "organization-reports",
    path: "/organization/reports",
    Component: OrganizationReports,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.CLIENT
  },
  {
    id: "organization-report",
    path: "/organization/reports/:id",
    Component: OrganizationReport,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.CLIENT
  },
  {
    id: "organization-contracts",
    path: "/organization/contracts",
    Component: OrganizationContracts,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.CLIENT
  },
  {
    id: "organization-contract",
    path: "/organization/contracts/:id",
    Component: OrganizationContracts,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.CLIENT
  },
  {
    id: "organization-job-applications",
    path: "/organization/job-applications",
    Component: JobApplications,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.CLIENT
  },
  {
    id: "community-bounties-requests",
    path: "/community/bounties-requests/",
    Component: Community,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "community-invites",
    path: "/community/invites/",
    Component: Invites,
    Layout: MainLayout,
    routeType: ROUTE_TYPE.PRIVATE
  },
  {
    id: "invitation",
    path: "/invitation",
    Component: Invitation,
    Layout: AuthLayout,
    routeType: ROUTE_TYPE.PUBLIC
  }
];

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {routes?.map(({ id, path, Component, Layout, routeType }) => (
          <Route element={<AuthWrapper routeType={routeType} />} key={id}>
            <Route
              path={path}
              element={
                Layout ? (
                  <Layout>
                    <Component />
                  </Layout>
                ) : (
                  <Component />
                )
              }
            />
          </Route>
        ))}
        {<Route path="*" element={<div>not found</div>} />}
      </Routes>
    </BrowserRouter>
  );
}
