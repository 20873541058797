import * as yup from "yup";

import { translation } from "utils/translation";

// TRANSLATIONS
export const requiredField = translation("formValidators.requiredField");
export const validEmail = translation("formValidators.validEmail");

// VALIDATORS
export const requiredValidator = yup.string().nullable().required(requiredField);
export const requiredValidatorSelect = yup.number().nullable().required(requiredField);
export const requiredValidatorSelectMulti = yup.array().of(yup.string().nullable().required(requiredField));

export const emailValidator = yup.string().required(requiredField).email(validEmail);

export const requiredValidatorNumberPositive = yup.lazy((value) =>
  value === "" ? yup.string().required(requiredField) : yup.number().min(1).required(requiredField)
);

export const requiredValidatorChecked = yup.boolean().oneOf([true], "");
