import { useEffect, useState } from "react";

import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { formatDate } from "utils/formatDate";

import { getAllContracts } from "state/actions/jobsActions";
import { selectGetAllContractsSuccess } from "state/selectors/jobsSelectors";

import VdmModal from "views/Reports/components/VdmModal";

import Button from "components/Button";
import DataField from "components/DataField";
import Divider from "components/Divider";
import Loading from "components/Loading";

import { IMyContract, IMyContracts } from "types/contract";

export default function MyContracts() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [first, setFirst] = useState<number>(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [hourlyRate, setHourlyRate] = useState("");
  const [currencyShortName, setCurrencyShortName] = useState("");

  const myContracts = useAppSelector(selectGetAllContractsSuccess) as IMyContracts;

  const getData = ({ page, perPage }: { page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };
    dispatch(getAllContracts({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    getData({ page: page });
  };

  useEffect(() => {
    getData({});
    setFirst(0);
  }, []);

  const myContractsToDisplay = myContracts?.contracts;

  if (!myContractsToDisplay) {
    return null;
  }

  return (
    <>
      {!myContractsToDisplay ? (
        <Loading />
      ) : (
        <div>
          {myContractsToDisplay.map((contract: IMyContract, index: number) => (
            <div key={index} className="relative">
              {index > 0 && <Divider className="mb-4 mt-4" />}
              <div className="w-full flex gap-4 mb-6 flex-column md:flex-row">
                <div className="pt-4 w-full md:w-6 flex md:flex-column gap-4">
                  <DataField label={t("job.title")} value={contract.Title} />
                  <DataField textField label={t("common.description")} value={contract.Description} />
                </div>
                <div className="md:pt-4 w-full md:w-6 flex flex-column gap-4">
                  <div className="flex w-full">
                    <DataField label={t("contract.reporterEmails")} value={contract.ReporterEmails} />
                  </div>
                  <div className="flex w-full">
                    <div className="flex w-full">
                      <DataField
                        className="flex md:w-3"
                        label={t("assignee.hourlyRate")}
                        value={contract.HourlyRate + " " + contract.CurrencyShortName}
                      />
                      <Button
                        type="button"
                        style={{ width: "25px", height: "25px", marginTop: "10px" }}
                        icon="pi pi-info-circle"
                        className="mr-3 md:mr-0 mt-3 md:mt-0"
                        rounded
                        text
                        severity="info"
                        aria-label="Info"
                        label=""
                        onClick={() => {
                          setHourlyRate(contract.HourlyRate);
                          setCurrencyShortName(contract.CurrencyShortName);
                          setShowEditModal(true);
                        }}
                      />
                    </div>
                    <DataField
                      className="pl-3 md:pl-0"
                      status={contract.Status === 1 ? "active" : "inactive"}
                      label={t("common.status")}
                      value={contract.Status === 1 ? t("common.active") : t("common.notActive")}
                    />
                  </div>
                  <div className="flex w-full">
                    <DataField label={t("common.startDate")} value={formatDate(contract.StartDate)} />
                    <DataField
                      className="pl-3 md:pl-0"
                      label={t("common.endDate")}
                      value={formatDate(contract.EndDate)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {myContracts?.total > 5 && (
        <Paginator first={first} rows={5} totalRecords={myContracts.total} onPageChange={handlePageChange} />
      )}
      <VdmModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
        hourlyRate={hourlyRate}
        currencyShortname={currencyShortName}
      />
    </>
  );
}
