import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import useTranslation from "hooks/useTranslation";

import { formatDate2 } from "utils/formatDate";

import Button from "components/Button";
import { DatePicker } from "components/FormControls";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "components/Modal";

interface IRangeModalProps {
  show: boolean;
  closeModal: () => void;
  updateRange: (start: string, end: string) => void;
}

const RangeModal = ({ show, closeModal, updateRange }: IRangeModalProps) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors
  } = useForm({
    reValidateMode: "onChange"
  });

  const [endYearError, setEndYearError] = useState(false);
  const StartDate = watch("StartDate", new Date());
  const EndDate = watch("EndDate", new Date());

  useEffect(() => {
    if (StartDate > EndDate) {
      setEndYearError(true);
      setError("StartDateEndDate", { message: t("formValidators.startDateEndDateError") });
    } else {
      setEndYearError(false);
      clearErrors("StartDateEndDate");
    }
  }, [StartDate, EndDate, errors]);

  const handleClose = () => {
    closeModal();
    setEndYearError(false);
  };

  return (
    <Modal show={show} closeModal={handleClose}>
      <ModalHeader title={t("common.addRange")} />
      <ModalBody show={show}>
        <form className="px-6">
          <div className="flex justify-between">
            <div className="w-full mr-3">
              <Controller
                name="StartDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label={t("common.startDate")}
                    onChange={(date: Date) => field.onChange(date)}
                    selected={field.value}
                  />
                )}
                defaultValue={StartDate}
              />
            </div>
            <div className="w-full ml-3">
              <Controller
                name="EndDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label={t("common.endDate")}
                    onChange={(date: Date) => field.onChange(date)}
                    selected={field.value}
                  />
                )}
                defaultValue={EndDate}
              />
            </div>
          </div>
          {endYearError && (
            <div className="absolute right-7 text-xs mt-2 mb-4 text-error-alert">
              {t("formValidators.startDateEndDateError")}
            </div>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="flex w-full">
          <Button onClick={handleClose} className="mr-2.5" type="button">
            {t("confirmation.cancel")}
          </Button>
          <Button onClick={() => updateRange(formatDate2(StartDate), formatDate2(EndDate))} className="ml-2.5">
            {t("confirmation.ok")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RangeModal;
