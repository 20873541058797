import * as yum from "yup";

import { requiredValidator, requiredValidatorNumberPositive, requiredValidatorSelect } from "utils/validators";

export const schema = yum.object().shape({
  IdContract: requiredValidator,
  Name: requiredValidator,
  Description: requiredValidator,
  Status: requiredValidatorSelect,
  StartDate: requiredValidator,
  EndDate: requiredValidator,
  EstimatedBudget: requiredValidator,
  EstimatedTime: requiredValidatorNumberPositive,
  IdCurrency: requiredValidatorSelect
});
