import { useEffect, useState } from "react";

import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useToast } from "hooks/useToast";
import useTranslation from "hooks/useTranslation";

import { getProjects } from "state/actions/projectsActions";
import {
  selectCreateProjectFail,
  selectCreateProjectSuccess,
  selectGetProjectsDataSuccess,
  selectUpdateProjectFail,
  selectUpdateProjectSuccess
} from "state/selectors/projectsSelectors";

import Button from "components/Button";
import SectionTitle from "components/SectionTitle";
import { TabPanel, TabView } from "components/Tabs";

import { IProjects } from "types/project";

import ProjectModal from "./components/ProjectModal";
import ProjectsList from "./components/ProjectsList";

export default function Projects() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { toast } = useToast();
  const [activeIndex, setActiveIndex] = useState(0);
  const [first, setFirst] = useState<number>(0);

  const [showAddModal, setShowAddModal] = useState(false);

  const updateProjectSuccess = useAppSelector(selectUpdateProjectSuccess);
  const createProjectSuccess = useAppSelector(selectCreateProjectSuccess);
  const createProjectFail = useAppSelector(selectCreateProjectFail);
  const updateProjectFail = useAppSelector(selectUpdateProjectFail);

  const projects = useAppSelector(selectGetProjectsDataSuccess) as IProjects;

  const getData = ({ status, page, perPage }: { status?: string | number; page?: number; perPage?: number }) => {
    const queryParams: Record<string, number | string> = {
      page: page || 1,
      perPage: perPage || 5
    };
    if (status) {
      queryParams.status = status;
    }
    dispatch(getProjects({ params: queryParams }));
  };

  const handlePageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    const page = event.page + 1;
    switch (activeIndex) {
      case 0:
        getData({ page: page });
        break;
      case 1:
        getData({ page: page, status: "1" });
        break;
      case 2:
        getData({ page: page, status: "0" });
        break;
    }
  };

  useEffect(() => {
    if (createProjectSuccess) {
      toast?.current?.show({
        severity: "success",
        summary: t("common.success"),
        detail: t("project.created"),
        life: 3000
      });
    }
  }, [createProjectSuccess]);

  useEffect(() => {
    if (createProjectFail || updateProjectFail) {
      toast?.current?.show({
        severity: "error",
        summary: t("common.error"),
        detail: t("errorBoundary.title"),
        sticky: true
      });
    }
  }, [createProjectFail, updateProjectFail]);

  useEffect(() => {
    switch (activeIndex) {
      case 0:
        getData({});
        setFirst(0);
        break;
      case 1:
        getData({ status: "1" });
        setFirst(0);
        break;
      case 2:
        getData({ status: "0" });
        setFirst(0);
        break;
    }
  }, [activeIndex, updateProjectSuccess, createProjectSuccess]);

  useEffect(() => {
    getData({});
  }, []);

  return (
    <section className="container mx-auto px-2 md:px-0">
      <SectionTitle>
        <div className="relative flex justify-content-between align-items-center w-full">
          {t("project.projects")}
          <Button
            icon="pi pi-plus"
            text
            className="text-900"
            tooltip="Add new project"
            type="button"
            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            <span className="text-900 p-button-label hidden md:flex ml-2">Add new project</span>
          </Button>
        </div>
      </SectionTitle>
      <div className="card">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header={t("project.allProjects")}>
            <ProjectsList />
          </TabPanel>
          <TabPanel header={t("common.active")}>
            <ProjectsList />
          </TabPanel>
          <TabPanel header={t("common.inactive")}>
            <ProjectsList />
          </TabPanel>
        </TabView>
        {projects?.total > 5 && (
          <Paginator first={first} rows={5} totalRecords={projects.total} onPageChange={handlePageChange} />
        )}
      </div>
      <ProjectModal
        show={showAddModal}
        closeModal={() => {
          setShowAddModal(false);
        }}
        project={null}
      />
    </section>
  );
}
