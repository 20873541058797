import Hero from "components/Hero";
import RedirectToLocation from "components/RedirectToLocation";

import MyBounties from "./components/MyBounties";
import MyReports from "./components/MyReports";
import MyStakes from "./components/MyStakes";
import MyTasks from "./components/MyTasks";

export default function Home() {
  return (
    <>
      <Hero>
        <MyStakes />
      </Hero>
      <div className="container mx-auto py-0 px-2 md:px-0">
        <MyTasks />
        <MyReports />
        <MyBounties />
      </div>
      <RedirectToLocation />
    </>
  );
}
