import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import {
  createProjectTask,
  createProjectTaskInit,
  getProjects,
  updateProjectTask,
  updateProjectTaskInit
} from "state/actions/projectsActions";
import {
  selectGetProjectsDataSuccess,
  selectGetProjectInfoSuccess,
  selectUpdateProjectTaskRequest,
  selectUpdateProjectTaskSuccess
} from "state/selectors/projectsSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { Calendar, InputNumber, InputText, InputTextarea, Select } from "components/FormControls";

import { IProject, IProjects } from "types/project";
import { ITask } from "types/task";

import { schema } from "./schema";

interface IProjectTaskModalProps {
  show: boolean;
  closeModal: () => void;
  task: null | ITask;
}

export default function MyTaskModal({ show, closeModal, task }: IProjectTaskModalProps) {
  const dispatch = useAppDispatch();
  const updateProjectTaskRequest = useAppSelector(selectUpdateProjectTaskRequest);
  const updateProjectTaskSuccess = useAppSelector(selectUpdateProjectTaskSuccess);
  const myProjectsSelect = useAppSelector(selectGetProjectsDataSuccess) as IProjects;
  const myProjects = myProjectsSelect?.projects.map((pro) => ({ label: pro.Name, value: pro.Id }));
  const project = useAppSelector(selectGetProjectInfoSuccess) as IProject;

  useEffect(() => {
    dispatch(getProjects(""));
  }, []);

  const { t } = useTranslation();

  const { reset, handleSubmit, control } = useForm<ITask>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  useEffect(() => {
    if (task) {
      reset({
        ...task,
        StartDate: new Date(task.StartDate),
        EndDate: new Date(task.EndDate)
      });
    } else {
      resetForm();
    }
  }, [task]);

  useEffect(() => {
    if (updateProjectTaskSuccess) {
      resetForm();
    }
  }, [updateProjectTaskSuccess]);

  const resetForm = () => {
    reset({
      Name: "",
      Description: "",
      Duration: 0,
      StartDate: new Date(),
      EndDate: new Date()
    });
  };

  const handleClose = () => {
    closeModal();
    resetForm();
  };

  const onSubmit: SubmitHandler<ITask> = (data) => {
    const taskData = {
      ...task,
      IdProject: parseInt(data.IdProject as unknown as string),
      Id: data.Id ? parseInt(data.Id as unknown as string) : undefined,
      Name: data.Name,
      Description: data.Description,
      StartDate: data.StartDate || new Date(),
      EndDate: data.EndDate || new Date(),
      Duration: data.Duration as unknown as number
    };

    if (!data.Id) {
      dispatch(createProjectTask(taskData)).then(() => dispatch(createProjectTaskInit()));
    } else {
      dispatch(updateProjectTask(taskData)).then(() => dispatch(updateProjectTaskInit()));
    }
    handleClose();
  };

  const footerContent = (
    <div>
      <Button label={t("confirmation.cancel")} onClick={closeModal} outlined disabled={updateProjectTaskRequest} />
      <Button label={t("confirmation.save")} type="submit" form="task-modal-form" disabled={updateProjectTaskRequest} />
    </div>
  );

  return (
    <Dialog
      show={show}
      closeModal={closeModal}
      title={t(task !== null && task.Id !== undefined && task?.Id > -1 ? "task.editTask" : "task.addNewTask")}
      footerContent={footerContent}
    >
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="task-modal-form">
        {!task && !project && (
          <Controller
            name="IdProject"
            control={control}
            render={({ field, fieldState }) => (
              <Select placeholder={t("project.projectName")} {...field} options={myProjects} error={fieldState.error} />
            )}
          />
        )}

        <Controller
          name="Name"
          control={control}
          render={({ field, fieldState }) => <InputText label={t("common.name")} {...field} error={fieldState.error} />}
          defaultValue=""
        />
        {task?.Id && (
          <>
            <Controller
              name="Description"
              control={control}
              render={({ field, fieldState }) => (
                <InputTextarea {...field} label={t("common.description")} error={fieldState.error} />
              )}
            />
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label={`${t("task.duration")} (${t("task.inHours")})`}
                  error={fieldState.error}
                  id={field.name}
                  inputRef={field.ref}
                  value={field.value || 0}
                  onBlur={field.onBlur}
                  onValueChange={(e) => field.onChange(e)}
                  useGrouping={false}
                  min={0}
                  minFractionDigits={1}
                />
              )}
              name="Duration"
            />
            <div className="flex justify-between">
              <div className="w-full mr-3">
                <Controller
                  name="StartDate"
                  control={control}
                  render={({ field }) => <Calendar label={t("common.startDate")} {...field} />}
                />
              </div>
              <div className="w-full ml-3">
                <Controller
                  name="EndDate"
                  control={control}
                  render={({ field }) => <Calendar label={t("common.endDate")} {...field} />}
                />
              </div>
            </div>
          </>
        )}
      </form>
    </Dialog>
  );
}
