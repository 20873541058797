export interface IUserDetails {
  Work: IWork[];
  Education: IEducation[];
  Certifications?: {
    Name: string;
    IssuedBy: string;
    IssuedDate: number;
    ValidUntil: number;
    CredentialID: string;
    CredentialUrl: string;
  }[];
  Tools?: string[];
  Skills: string[];
}

export interface IUserData {
  Id: number;
  ProfilePhoto: string;
  Headline: string;
  Email: string;
  FirstName: string;
  LastName: string;
  IdCountry: number;
  IdPlace: 0;
  IsPrivate: number;
  IdCurrency: number;
  IdPaymentType: number;
  UserType: number;
  IdRole: number;
  HourlyRate: string;
}

export interface IEducation {
  School: string;
  Degree: string;
  FieldOfStudy: string;
  StartMonth: number;
  StartYear: number;
  EndMonth: number;
  EndYear: number;
  Grade: number;
  Activities: string;
  Description: string;
}

export interface IWork {
  Title: string;
  EmploymentType: string;
  CompanyName: string;
  Location: string;
  Industry: string;
  Description: string;
  StartMonth: number;
  StartYear: number;
  EndMonth?: number;
  EndYear?: number;
  Skills: string[];
}

export enum UserType {
  Creator = 0,
  Client = 1
}
