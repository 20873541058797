import { STATUS } from "utils/constants";

import { RootState } from "state/store";

export const selectGetProjectsDataSuccess = (state: RootState) => state.projects.getProjects.data;
export const selectGetProjectsDataRequest = (state: RootState) => state.projects.getProjects.status === STATUS.PENDING;

export const selectUpdateProjectSuccess = (state: RootState) => state.projects.updateProject.data;
export const selectUpdateProjectRequest = (state: RootState) => state.projects.updateProject.status === STATUS.PENDING;
export const selectUpdateProjectFail = (state: RootState) => state.projects.updateProject.status === STATUS.REJECTED;

export const selectCreateProjectSuccess = (state: RootState) => state.projects.createProject.data;
export const selectCreateProjectRequest = (state: RootState) => state.projects.createProject.status === STATUS.PENDING;
export const selectCreateProjectFail = (state: RootState) => state.projects.createProject.status === STATUS.REJECTED;

export const selectGetProjectInfoSuccess = (state: RootState) => state.projects.getProjectInfo.data;
export const selectGetProjectInfoRequest = (state: RootState) =>
  state.projects.getProjectInfo.status === STATUS.PENDING;

export const selectGetProjectTasksDataSuccess = (state: RootState) => state.projects.getProjectTasks.data;
export const selectGetProjectTasksDataRequest = (state: RootState) =>
  state.projects.getProjectTasks.status === STATUS.PENDING;

export const selectUpdateProjectTaskSuccess = (state: RootState) => state.projects.updateProjectTask.data;
export const selectUpdateProjectTaskRequest = (state: RootState) =>
  state.projects.updateProjectTask.status === STATUS.PENDING;
export const selectUpdateProjectTaskFail = (state: RootState) =>
  state.projects.updateProjectTask.status === STATUS.REJECTED;

export const selectCreateProjectTaskSuccess = (state: RootState) => state.projects.createProjectTask.data;
export const selectCreateProjectTaskRequest = (state: RootState) =>
  state.projects.createProjectTask.status === STATUS.PENDING;
export const selectCreateProjectTaskFail = (state: RootState) =>
  state.projects.createProjectTask.status === STATUS.REJECTED;

export const selectDeleteProjectTaskSuccess = (state: RootState) => state.projects.deleteProjectTask.data;
export const selectDeleteProjectTaskRequest = (state: RootState) =>
  state.projects.deleteProjectTask.status === STATUS.PENDING;
export const selectDeleteProjectTaskFail = (state: RootState) =>
  state.projects.deleteProjectTask.status === STATUS.REJECTED;

export const selectGetProjectTaskInfoSuccess = (state: RootState) => state.projects.getProjectTaskInfo.data;
export const selectGetProjectTaskInfoRequest = (state: RootState) =>
  state.projects.getProjectTaskInfo.status === STATUS.PENDING;

export const selectProjectPositionsSuccess = (state: RootState) => state.projects.getProjectPositions.data;
export const selectGetProjectCreatorsSuccess = (state: RootState) => state.projects.getProjectCreators.data;

export const selectGetMyTasksDataSuccess = (state: RootState) => state.projects.getMyTasks.data;
export const selectGetMyTasksDataRequest = (state: RootState) => state.projects.getMyTasks.status === STATUS.PENDING;

export const selectGetMyTaskInfoSuccess = (state: RootState) => state.projects.getMyTaskInfo.data;

export const selectGetMyProjectsDataSuccess = (state: RootState) => state.projects.getMyProjects.data;
