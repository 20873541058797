import { api } from "utils/api";

import { IUserDetails } from "types/user";

const baseUsersUrl = `https://${process.env.REACT_APP_ENV}users.${process.env.REACT_APP_API_URL}/users`;

export const getUserData = () => api.get(`${baseUsersUrl}`);

export const updateUserData = (params: Record<string, unknown>) => api.put(`${baseUsersUrl}/`, params);

export const getUserDetailsData = () => api.get(`${baseUsersUrl}/details`);

export const updateUserDetailsData = (params: IUserDetails) => api.put(`${baseUsersUrl}/details`, params);
