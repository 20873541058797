import { useState } from "react";

import useCommon from "hooks/useCommon";
import useTranslation from "hooks/useTranslation";

import { getDayMonthYear } from "utils/formatDate";

import Button from "components/Button";
import DataField from "components/DataField";

import { IJob } from "types/job";

import JobModal from "../JobModal";

export default function JobDetails({ ...job }: IJob) {
  const { t } = useTranslation();
  const { jobTypes } = useCommon();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  return (
    <div className="relative">
      <div className="absolute -mt-3 right-0 p-3 -mr-5">
        <Button
          icon="pi pi-file-edit"
          rounded
          text
          className="h-2rem w-2rem text-900 mr-1"
          tooltip={t("job.editJob")}
          tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
          onClick={() => {
            setShowEditModal(true);
          }}
        />
      </div>
      <div className="flex gap-4 pt-6 flex-column md:flex-row">
        <div className="md:w-6 flex md:flex-column gap-4">
          <DataField label={t("job.title")} value={job.Name} />
          <DataField textField label={t("common.description")} value={job.Description} />
        </div>
        <div className="md:w-6 flex flex-column gap-4">
          <div className="flex w-full">
            <DataField className="break-text" textField label={t("common.web")} value={job.Web} />
          </div>
          <div className="flex w-full gap-4 md:gap-8">
            <DataField
              label={t("project.isStartup")}
              value={job.IsStartup === 1 ? t("confirmation.yes") : t("confirmation.no")}
            />
            <DataField label={t("job.type")} value={`${jobTypes?.find((type) => type.value === job.IdType)?.label}`} />
          </div>
          <div className="flex w-full gap-2 md:gap-0">
            <DataField
              className="text-sm md:text-base"
              label={t("common.startDate")}
              value={getDayMonthYear(job.StartDate)}
            />
            <DataField
              label={t("common.endDate")}
              value={getDayMonthYear(job.EndDate)}
              className="ml-3 md:ml-8 text-sm md:text-base"
            />
          </div>
          <div className="flex w-full">
            <DataField
              status={job.Status === 1 ? "active" : "inactive"}
              label={t("common.status")}
              value={job.Status === 1 ? t("common.active") : t("common.notActive")}
            />
          </div>
        </div>
      </div>
      <JobModal
        show={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
        job={job}
      />
    </div>
  );
}
