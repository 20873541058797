import { Paginator } from "primereact/paginator";

import ReportsTable from "../ReportsTable";

export default function ReportsList({ reports, reportsRequest, handlePageChange, first, isOrganization }: any) {
  return (
    <section>
      <div className="card">
        <ReportsTable reports={reports} reportsRequest={reportsRequest} isOrganization={isOrganization} />
        {(reports?.total || 1) > 10 && (
          <Paginator first={first} rows={5} totalRecords={reports?.total} onPageChange={handlePageChange} />
        )}
      </div>
    </section>
  );
}
