import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import { preloadedState } from "./preloadedState";
import rootReducer from "./rootReducer";

export const store = configureStore({
  reducer: rootReducer,
  preloadedState
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
