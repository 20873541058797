import * as yup from "yup";

import { requiredValidator, requiredValidatorChecked } from "utils/validators";

export const schema = yup.object().shape({
  password: requiredValidator,
  confirmPassword: requiredValidator,
  agreePolicy: requiredValidatorChecked,
  agreeMemberBounty: requiredValidatorChecked
});
