import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import {
  createContract,
  createContractInit,
  updateJobApplicationClient,
  updateJobApplicationClientInit
} from "state/actions/jobsActions";
import { selectGetUserDataSuccess } from "state/selectors/usersSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { Calendar, InputText, InputTextarea, Select } from "components/FormControls";

import { IMyJob } from "types/job";
import { IUserData } from "types/user";

import { schema } from "./schema";

interface IJobModalProps {
  show: boolean;
  closeModal: () => void;
  jobApplication: IMyJob | undefined;
}

const ContractModal = ({ show, closeModal, jobApplication }: IJobModalProps) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectGetUserDataSuccess) as IUserData;
  const { currencies } = useCommon();

  const { t } = useTranslation();
  const { currenciesShortNames } = useCommon();

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  const [endYearError, setEndYearError] = useState(false);
  const StartDate = watch("StartDate", new Date());
  const EndDate = watch("EndDate", new Date());

  useEffect(() => {
    if (jobApplication) {
      reset({
        Title: jobApplication?.JobName,
        Description: jobApplication?.JobDescription,
        StartDate: new Date(),
        EndDate: new Date()
      });
    }
  }, [jobApplication]);

  useEffect(() => {
    if (StartDate > EndDate) {
      setEndYearError(true);
      setError("StartDateEndDate", { message: t("formValidators.startDateEndDateError") });
    } else {
      setEndYearError(false);
      clearErrors("StartDateEndDate");
    }
  }, [StartDate, EndDate, errors]);

  const handleClose = () => {
    closeModal();
    setEndYearError(false);
  };

  const onSubmit = (data: Record<string, unknown>) => {
    if (errors.StartDateEndDate) {
      return;
    }

    const contractData = {
      Title: jobApplication?.JobName,
      Description: jobApplication?.JobDescription,
      IdCurrency: parseInt(data.IdCurrency as unknown as string),
      HourlyRate: parseFloat(data?.HourlyRate as unknown as string) || "0",
      StartDate: StartDate.toJSON(),
      EndDate: EndDate.toJSON(),
      IdJobApllication: parseInt(jobApplication?.Id as unknown as string),
      ReporterEmails: userData?.Email,
      CurrencyShortName: currenciesShortNames?.find(
        (currency) => currency?.value.toString() === (data.IdCurrency as unknown as string)
      )?.label
    };

    const joAppToUpdate = {
      Id: parseInt(jobApplication?.Id as unknown as string),
      IdJob: parseInt(jobApplication?.IdJob as unknown as string),
      IdUser: jobApplication?.IdUser,
      IdOwner: jobApplication?.IdOwner,
      Status: 3
    };

    dispatch(createContract(contractData)).then(() => dispatch(createContractInit()));

    dispatch(updateJobApplicationClient(joAppToUpdate)).then(() => dispatch(updateJobApplicationClientInit()));

    handleClose();
  };

  const footerContent = (
    <div>
      <Button label={t("confirmation.cancel")} onClick={handleClose} type="button" outlined />
      <Button label={t("confirmation.save")} className="ml-2.5" type="submit" form="job-details-form" />
    </div>
  );

  return (
    <Dialog show={show} closeModal={handleClose} title={t("contract.createContract")} footerContent={footerContent}>
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="job-details-form">
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("work.title")} {...field} error={fieldState.error} readOnly />
          )}
          name="Title"
        />
        <Controller
          name="Description"
          control={control}
          render={({ field, fieldState }) => (
            <InputTextarea rows={5} {...field} label={t("common.description")} error={fieldState.error} readOnly />
          )}
        />

        <Controller
          name="IdCurrency"
          render={({ field, fieldState }) => (
            <Select
              label={t("form.selectCurrency")}
              placeholder={t("form.selectCurrency")}
              options={currencies}
              error={fieldState.error}
              {...field}
            />
          )}
          control={control}
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("assignee.hourlyRate")} {...field} error={fieldState.error} type="number" step="0.01" />
          )}
          name="HourlyRate"
        />

        <div className="flex flex-column md:flex-row">
          <div className="w-full mr-3">
            <Controller
              name="StartDate"
              control={control}
              render={({ field }) => <Calendar label={t("common.startDate")} {...field} />}
              defaultValue={StartDate}
            />
          </div>
          <div className="w-full pt-3 md:pt-0 md:ml-3">
            <Controller
              name="EndDate"
              control={control}
              render={({ field }) => <Calendar label={t("common.endDate")} {...field} />}
              defaultValue={EndDate}
            />
          </div>
        </div>

        {endYearError && (
          <div className="absolute right-7 text-xs mt-2 mb-4 text-error-alert">
            {t("formValidators.startDateEndDateError")}
          </div>
        )}
      </form>
    </Dialog>
  );
};

export default ContractModal;
