import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { updateProjectTask, updateProjectTaskInit } from "state/actions/projectsActions";
import { selectUpdateProjectTaskRequest, selectUpdateProjectTaskSuccess } from "state/selectors/projectsSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { InputNumber } from "components/FormControls";

import { ITask } from "types/task";

import { schema } from "./schema";

interface IProjectTaskModalProps {
  show: boolean;
  closeModal: () => void;
  task: null | ITask;
}

interface IDuration {
  Duration: number;
}

export default function HoursModal({ show, closeModal, task }: IProjectTaskModalProps) {
  const dispatch = useAppDispatch();
  const updateProjectTaskRequest = useAppSelector(selectUpdateProjectTaskRequest);
  const updateProjectTaskSuccess = useAppSelector(selectUpdateProjectTaskSuccess);

  const { t } = useTranslation();

  const { reset, handleSubmit, control } = useForm<IDuration>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  useEffect(() => {
    if (task) {
      reset({
        ...task
      });
    } else {
      resetForm();
    }
  }, [task]);

  useEffect(() => {
    if (updateProjectTaskSuccess) {
      resetForm();
    }
  }, [updateProjectTaskSuccess]);

  const resetForm = () => {
    reset({
      Duration: 0
    });
  };

  const handleClose = () => {
    closeModal();
    resetForm();
  };

  const onSubmit: SubmitHandler<IDuration> = (data) => {
    let Duration = data.Duration;
    if (task?.Duration) {
      Duration = Duration + parseInt(task?.Duration as unknown as string);
    }
    const taskData = {
      ...task,
      Id: parseInt(task?.Id as unknown as string),
      Duration: Duration
    };

    dispatch(updateProjectTask(taskData)).then(() => dispatch(updateProjectTaskInit()));
    handleClose();
  };

  const footerContent = (
    <div>
      <Button label={t("confirmation.cancel")} onClick={closeModal} outlined disabled={updateProjectTaskRequest} />
      <Button
        label={t("confirmation.save")}
        type="submit"
        form="hours-modal-form"
        disabled={updateProjectTaskRequest}
      />
    </div>
  );

  return (
    <Dialog show={show} closeModal={closeModal} title={t("task.addHours")} footerContent={footerContent}>
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="hours-modal-form">
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputNumber
              label={`${t("task.duration")} (${t("task.inHours")})`}
              error={fieldState.error}
              id={field.name}
              inputRef={field.ref}
              value={0}
              onBlur={field.onBlur}
              onValueChange={(e) => field.onChange(e)}
              useGrouping={false}
              min={0}
              minFractionDigits={1}
            />
          )}
          name="Duration"
        />
      </form>
    </Dialog>
  );
}
