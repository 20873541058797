import { STATUS } from "utils/constants";

import { RootState } from "state/store";

export const selectLoginUserInit = (state: RootState) => state.auth.login.status === STATUS.INIT;
export const selectLoginUserSuccess = (state: RootState) => state.auth.login.status === STATUS.FULFILLED;
export const selectLoginUserRequest = (state: RootState) => state.auth.login.status === STATUS.PENDING;
export const selectLoginUserFail = (state: RootState) =>
  state.auth.login.status === STATUS.REJECTED && state.auth.login.error;

export const selectIsUserAuthenticated = (state: RootState) =>
  state.auth.login.data && state.auth.login.status === STATUS.FULFILLED;

export const selectUserAuthentication = (state: RootState) => state.auth.login.data;

export const selectRegisterUserSuccess = (state: RootState) => state.auth.register.status === STATUS.FULFILLED;
export const selectRegisterUserRequest = (state: RootState) => state.auth.register.status === STATUS.PENDING;
export const selectRegisterUserFail = (state: RootState) =>
  state.auth.register.status === STATUS.REJECTED && state.auth.register.error;

export const selectDeactivationUserRequest = (state: RootState) => state.auth.deactivate.status === STATUS.PENDING;
export const selectDeactivationUserSuccess = (state: RootState) => state.auth.deactivate.status === STATUS.FULFILLED;

export const selectActivationUserRequest = (state: RootState) => state.auth.activate.status === STATUS.PENDING;
export const selectActivationUserSuccess = (state: RootState) => state.auth.activate.status === STATUS.FULFILLED;

export const selectResetPasswordUserRequest = (state: RootState) => state.auth.resetPassword.status === STATUS.PENDING;
export const selectResetPasswordUserSuccess = (state: RootState) =>
  state.auth.resetPassword.status === STATUS.FULFILLED;
export const selectResetPasswordUserFail = (state: RootState) => state.auth.resetPassword.status === STATUS.REJECTED;

export const selectResetUserPasswordRequest = (state: RootState) =>
  state.auth.resetUserPassword.status === STATUS.PENDING;
export const selectResetUserPasswordSuccess = (state: RootState) =>
  state.auth.resetUserPassword.status === STATUS.FULFILLED;
export const selectResetUserPasswordFail = (state: RootState) =>
  state.auth.resetUserPassword.status === STATUS.REJECTED;

export const selectChangeUserPasswordRequest = (state: RootState) =>
  state.auth.changeUserPassword.status === STATUS.PENDING;
export const selectChangeUserPasswordSuccess = (state: RootState) =>
  state.auth.changeUserPassword.status === STATUS.FULFILLED;
export const selectChangeUserPassowrdFail = (state: RootState) =>
  state.auth.changeUserPassword.status === STATUS.REJECTED;

export const selectListInvitedMembersSuccess = (state: RootState) => state.auth.listInvitedMembers.data;
export const selectListInvitedMembersRequest = (state: RootState) =>
  state.auth.listInvitedMembers.state === STATUS.PENDING;

export const selectInviteMemberSuccess = (state: RootState) => state.auth.inviteMember.data;
export const selectInviteMemberRequest = (state: RootState) => state.auth.inviteMember.status === STATUS.PENDING;
export const selectInviteMemberFail = (state: RootState) => state.auth.inviteMember.status === STATUS.REJECTED;

export const selectDeleteInviteSuccess = (state: RootState) => state.auth.deleteInvite.data;
export const selectDeleteInviteRequest = (state: RootState) => state.auth.deleteInvite.status === STATUS.PENDING;
export const selectDeleteInviteFail = (state: RootState) => state.auth.deleteInvite.status === STATUS.REJECTED;

export const selectGetInviteDetailsRequest = (state: RootState) =>
  state.auth.getInviteDetails.status === STATUS.PENDING;
export const selectGetInviteDetails = (state: RootState) => state.auth.getInviteDetails.data;
export const selectGetInviteDetailsSuccess = (state: RootState) =>
  state.auth.getInviteDetails.status === STATUS.FULFILLED;

export const selectRegisterInviteRequest = (state: RootState) => state.auth.registerInvite.status === STATUS.PENDING;
export const selectRegisterInviteSuccess = (state: RootState) => state.auth.registerInvite.status === STATUS.FULFILLED;
export const selectRegisterInviteFail = (state: RootState) =>
  state.auth.registerInvite.status === STATUS.REJECTED && state.auth.registerInvite.error;
