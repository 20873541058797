import { STATUS } from "utils/constants";

import { RootState } from "state/store";

export const selectGetJobsDataSuccess = (state: RootState) => state.jobs.getJobs.data;
export const selectGetJobsDataRequest = (state: RootState) => state.jobs.getJobs.data === STATUS.PENDING;

export const selectUpdateJobSuccess = (state: RootState) => state.jobs.updateJob.data;
export const selectUpdateJobRequest = (state: RootState) => state.jobs.updateJob.data === STATUS.PENDING;
export const selectUpdateJobFail = (state: RootState) => state.jobs.updateJob.data === STATUS.REJECTED;

export const selectCreateJobSuccess = (state: RootState) => state.jobs.createJob.data;
export const selectCreateJobRequest = (state: RootState) => state.jobs.createJob.data === STATUS.PENDING;
export const selectCreateJobFail = (state: RootState) => state.jobs.createJob.data === STATUS.REJECTED;

export const selectGetJobInfoSuccess = (state: RootState) => state.jobs.getJobInfo.data;
export const selectGetJobInfoRequest = (state: RootState) => state.jobs.getJobInfo.data === STATUS.PENDING;

export const selectSearchJobsDataSuccess = (state: RootState) => state.jobs.searchJobs.data;
export const selectSearchJobsDataRequest = (state: RootState) => state.jobs.searchJobs.data === STATUS.PENDING;

export const selectApplyToJobSuccess = (state: RootState) => state.jobs.applyToJob.data;
export const selectApplyToJobRequest = (state: RootState) => state.jobs.applyToJob.data === STATUS.PENDING;
export const selectApplyToJobFail = (state: RootState) => state.jobs.applyToJob.data === STATUS.REJECTED;

export const selectGetAllJobsApplicationsSuccess = (state: RootState) => state.jobs.getAllJobApplications.data;
export const selectGetAllJobsApplicationsRequest = (state: RootState) =>
  state.jobs.getAllJobApplications.data === STATUS.PENDING;

export const selectGetAllContractsSuccess = (state: RootState) => state.jobs.getAllContracts.data;
export const selectGetAllContractsRequest = (state: RootState) => state.jobs.getAllContracts.data === STATUS.PENDING;

export const selectGetJobApplicationsClientSuccess = (state: RootState) => state.jobs.getJobApplicationsClient.data;
export const selectGetJobApplicationsClientRequest = (state: RootState) =>
  state.jobs.getJobApplicationsClient.status === STATUS.PENDING;
export const selectGetJobApplicationsClientFail = (state: RootState) =>
  state.jobs.getJobApplicationsClient.status === STATUS.REJECTED;

export const selectGetAllContractsClientSuccess = (state: RootState) => state.jobs.getAllContractsClient.data;
export const selectGetAllContractsClientRequest = (state: RootState) =>
  state.jobs.getAllContractsClient.data === STATUS.PENDING;

export const selectUpdateJobApplicationClientSuccess = (state: RootState) => state.jobs.updateJobApplicationClient.data;
export const selectUpdateJobApplicationClientRequest = (state: RootState) =>
  state.jobs.updateJobApplicationClient.status === STATUS.FULFILLED;
export const selectUpdateJobApplicationClientFailed = (state: RootState) =>
  state.jobs.updateJobApplicationClient.status === STATUS.REJECTED;

export const selectCreateContractSuccess = (state: RootState) => state.jobs.createContract.data;
export const selectCreateContractRequest = (state: RootState) => state.jobs.createContract.data === STATUS.PENDING;
export const selectCreateContractFail = (state: RootState) => state.jobs.createContract.data === STATUS.REJECTED;
