import { CREATE_ORGANIZATION, GET_ORGANIZATION, UPDATE_ORGANIZATION } from "state/constants/organizationsConstants";
import { action, init } from "state/utils/action";

import {
  createOrganization as createOrganizationService,
  getOrganization as getOrganizationService,
  updateOrganization as updateOrganizationService
} from "services/OrganizationsService";

export const getOrganization = () => action(`organizations/${GET_ORGANIZATION}`, getOrganizationService);

export const createOrganization = (params: Record<string, unknown>) =>
  action(`organizations/${CREATE_ORGANIZATION}`, createOrganizationService, params);
export const createOrganizationInit = () => init(`organizations/${CREATE_ORGANIZATION}/init`);

export const updateOrganization = (params: Record<string, unknown>) =>
  action(`organizations/${UPDATE_ORGANIZATION}`, updateOrganizationService, params);
export const updateOrganizationInit = () => init(`organizations/${UPDATE_ORGANIZATION}/init`);
