import useCommon from "hooks/useCommon";
import useTranslation from "hooks/useTranslation";

import Button from "components/Button";
import DataField from "components/DataField";

export default function ReportDetails({ myReport, setShowSubmitReport }: any) {
  const { t } = useTranslation();
  const { reportStatus } = useCommon();

  if (!myReport?.myreport) {
    return null;
  }

  const report = myReport?.myreport[0];

  return (
    <div className="relative">
      <div className="flex gap-2 md:gap-4 pt-6">
        <div className="w-6 flex flex-column gap-4">
          <DataField label={t("myreports.reportSummary")} value={report.ReportName} />
          <DataField textField label={t("mytasks.partnerName")} value={report.PartnerName} />
          <DataField
            textField
            label={t("myreports.reportForMonthYear")}
            value={`${report.Month.toString().length === 1 ? "0" + report.Month : report.Month}/${report.Year}`}
          />
          <DataField label={t("myreports.contract")} value={report.ContractTitle} />
        </div>
        <div className="md:w-6 flex flex-column gap-4">
          <div className="flex w-full flex-column md:flex-row gap-4 md:gap-0">
            <DataField label={t("assignee.hourlyRate")} value={report.HourlyRate + " " + report.CurrencyShortName} />
            <DataField
              status={report.Status === 1 ? "active" : "inactive"}
              label={t("common.status")}
              value={reportStatus.find((s) => s.value.toString() === report.Status.toString())?.label || ""}
            />
          </div>

          <div className="flex w-full gap-4 md:gap-0 flex-column md:flex-row">
            <DataField textField label={t("mytasks.totalHoursReported")} value={report.Duration} />
            <DataField label={t("myreports.reportedTo")} value={report.ReporterEmails} />
          </div>
          <div className="flex w-full">
            <DataField
              label={t("mytasks.totalAmountToCharge")}
              value={report.Earnings + " " + report.CurrencyShortName}
            />
            {report.Comment && <DataField label={t("common.comment")} value={`${report.Comment}`} />}
          </div>
          <div className="hidden md:flex w-full">
            {report?.Status === 2 && (
              <div className="flex w-full">
                <Button
                  label="Accept"
                  type="button"
                  icon="pi pi-check"
                  className="mr-2 w-full"
                  onClick={() => setShowSubmitReport(true)}
                />
                <Button label="Decline" severity="danger" type="button" icon="pi pi-times" className="ml-2 w-full" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="md:hidden flex w-full pt-4">
        {report?.Status === 2 && (
          <div className="flex w-full">
            <Button
              label="Accept"
              type="button"
              icon="pi pi-check"
              className="mr-2 w-full"
              onClick={() => setShowSubmitReport(true)}
            />
            <Button label="Decline" severity="danger" type="button" icon="pi pi-times" className="ml-2 w-full" />
          </div>
        )}
      </div>
    </div>
  );
}
