import { useEffect, useRef } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Messages as PrimeMessages } from "primereact/messages";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { changeUserPassword, loginUserInit, logoutUser } from "state/actions/authActions";
import {
  selectChangeUserPassowrdFail,
  selectChangeUserPasswordRequest,
  selectChangeUserPasswordSuccess
} from "state/selectors/authSelectors";

import Button from "components/Button";
import { InputPassword } from "components/FormControls";
import Loading from "components/Loading";

import { schema } from "./schema";

export default function ChangePassword() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const msgs = useRef<PrimeMessages>(null);
  const navigate = useNavigate();

  const changeUserPaswordRequest = useAppSelector(selectChangeUserPasswordRequest);
  const changeUserPasswordSuccess = useAppSelector(selectChangeUserPasswordSuccess);
  const changeUserPasswordFail = useAppSelector(selectChangeUserPassowrdFail);

  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const onSubmit = (data: Record<string, string>) => {
    msgs.current?.clear();

    if (data.newPassword !== data.confirmNewPassword) {
      msgs.current?.show({
        sticky: true,
        severity: "error",
        detail: t("auth.passwordsDidntMatch")
      });
      setError("Password", { message: t("auth.passwordsDidntMatch") });
      setError("ConfirmPassword", { message: t("auth.passwordsDidntMatch") });
    } else {
      const userData = {
        OldPassword: data.currentPassword,
        NewPassword: data.newPassword
      };
      dispatch(changeUserPassword(userData));
    }
  };

  useEffect(() => {
    if (changeUserPasswordSuccess) {
      dispatch(logoutUser());
      dispatch(loginUserInit());
    }
    if (changeUserPasswordFail) {
      msgs.current?.show({
        sticky: true,
        severity: "error",
        detail: t("auth.changePasswordFail")
      });
    }
  }, [changeUserPasswordSuccess, changeUserPasswordFail]);

  if (changeUserPaswordRequest) {
    return <Loading />;
  }

  return (
    <>
      <div className="text-4xl line-height-2 text-center font-light pre">{t("menu.changePassword")}</div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className="w-full md:w-24rem mx-auto flex flex-column mt-6 gap-6"
      >
        <div className="flex flex-column gap-6">
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <InputPassword label={t("form.currentPassword")} {...field} error={fieldState.error} />
            )}
            name="currentPassword"
            defaultValue=""
          />
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <InputPassword label={t("form.newPassword")} {...field} error={fieldState.error} />
            )}
            name="newPassword"
            defaultValue=""
          />
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <InputPassword label={t("form.confirmNewPassword")} {...field} error={fieldState.error} />
            )}
            name="confirmNewPassword"
            defaultValue=""
          />
        </div>
        <div className="flex flex-column gap-3">
          <Button label="Reset password" />
          <Button label="Cancel" onClick={() => navigate("/")} outlined />
        </div>
      </form>
    </>
  );
}
