import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import useCommon from "hooks/useCommon";
import { useAppDispatch } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { addReportPayment, addReportPaymentInit } from "state/actions/dashboardActions";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { Calendar, Select } from "components/FormControls";

import { IModal } from "types/modal";

import { schema } from "./schema";

interface IAddReportPaymentModalProps extends IModal {
  report: any;
}

export default function AddReportPaymentModal({ show, closeModal, report }: IAddReportPaymentModalProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { paymentTypes } = useCommon();

  const { reset, watch, handleSubmit, control } = useForm<any>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  const PaymentDate = watch("PaymentDate", new Date());

  const resetForm = () => {
    reset({
      IdReport: -1,
      PaymentMethod: "",
      PaymentDate: ""
    });
  };

  useEffect(() => {
    resetForm();
  }, []);

  const onSubmit = (data: any) => {
    const reportToUpdate = {
      IdReport: parseInt(report.IdReport),
      PaymentMethod: data.IdPaymentType,
      PaymentDate: data.PaymentDate
    };

    dispatch(addReportPayment(reportToUpdate)).then(() => dispatch(addReportPaymentInit()));

    closeModal();
  };

  const footerContent = (
    <div>
      <Button onClick={closeModal} label={t("confirmation.cancel")} type="button" outlined />
      <Button type="submit" label={t("menu.addPayment")} form="submit-report-form" />
    </div>
  );

  return (
    <Dialog show={show} closeModal={closeModal} title={t("myreports.addReportPayment")} footerContent={footerContent}>
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="submit-report-form">
        <Controller
          name="IdPaymentType"
          render={({ field, fieldState }) => (
            <Select
              label={t("common.paymentType")}
              placeholder={t("form.selectPaymentType")}
              options={paymentTypes}
              error={fieldState.error}
              {...field}
            />
          )}
          control={control}
        />
        <Controller
          name="PaymentDate"
          control={control}
          render={({ field }) => <Calendar label={t("common.paymentDate")} {...field} />}
          defaultValue={PaymentDate}
        />
      </form>
    </Dialog>
  );
}
