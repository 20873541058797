export const GET_MY_STAKES = "getMyStakes";
export const GET_MY_BOUNTIES = "getMyBounties";
export const GET_MY_REPORTS = "getMyReports";
export const GET_MY_REPORT = "getMyReport";
export const CREATE_REPORT = "createReport";
export const UPDATE_REPORT = "updateReport";
export const GET_MY_CONTRACTS = "getMyContracts";
export const CREATE_REPORT_ACTIVITY = "createReportActivity";
export const UPDATE_REPORT_ACTIVITY = "updateReportActivity";
export const GET_REPORT_ACTIVITIES = "getReportActivities";
export const GET_MY_REPORT_ACTIVITIES = "getMyReportActivities";
export const GET_REPORT_ACTIVITY = "getReportActivity";
export const DELETE_REPORT = "deleteReport";
export const DELETE_REPORT_ACTIVITY = "deleteReportActivity";

export const GET_ORGANIZATION_REPORTS = "getOrganizationReports";
export const GET_ORGANIZATION_REPORT = "getOrganizationReport";
export const UPDATE_ORGANIZATION_REPORT = "updateOrganizationReport";
export const GET_ORGANIZATION_REPORT_ACTIVITIES = "getOrganizationReportActivities";
export const GET_VDM = "getVdm";

export const GET_BOUNTIES_REQUESTS = "getBountiesRequests";
export const GET_BOUNTIES_HISTORY_REQUESTS = "getBountiesHistoryRequests";
export const CLAIM_BOUNTY = "claimBounty";
export const UPDATE_BOUNTY_STATUS = "updateBountyStatus";

export const GET_BOUNTY_STATUSES = "getBountyStatuses";
export const GET_BOUNTY_TYPES = "getBountyTypes";
export const GET_MEMBERS_LIST = "getMembersList";

export const GET_ADMIN_REPORTS = "getAdminReports";
export const GET_ADMIN_REPORT = "getAdminReport";
export const ADD_REPORT_PAYMENT = "addReportPayment";
