import { STATUS } from "utils/constants";

import { RootState } from "state/store";

export const selectGetMyStakesDataSuccess = (state: RootState) => state.dashboard.getMyStakes.data;
export const selectGetMyBountiesDataSuccess = (state: RootState) => state.dashboard.getMyBounties.data;
export const selectGetMyBountiesDataRequest = (state: RootState) =>
  state.dashboard.getMyBounties.state === STATUS.PENDING;
export const selectGetMyReportsDataSuccess = (state: RootState) => state.dashboard.getMyReports.data;
export const selectGetMyReportsRequest = (state: RootState) => state.dashboard.getMyReports.state === STATUS.PENDING;
export const selectGetMyReportDataSuccess = (state: RootState) => state.dashboard.getMyReport.data;
export const selectGetMyReportRequest = (state: RootState) => state.dashboard.getMyReport.state === STATUS.PENDING;

export const selectCreateReportDataSuccess = (state: RootState) => state.dashboard.createReport.data;
export const selectCreateReportDataRequest = (state: RootState) =>
  state.dashboard.createReport.state === STATUS.PENDING;
export const selectCreateReportDataFail = (state: RootState) => state.dashboard.createReport.status === STATUS.REJECTED;

export const selectUpdateReportDataSuccess = (state: RootState) => state.dashboard.updateReport.data;
export const selectUpdateReportDataRequest = (state: RootState) =>
  state.dashboard.updateReport.state === STATUS.PENDING;
export const selectUpdateReportDataFail = (state: RootState) => state.dashboard.updateReport.state === STATUS.REJECTED;

export const selectGetMyContractsDataSuccess = (state: RootState) => state.dashboard.getMyContracts.data;
export const selectGetMyContractsDataRequest = (state: RootState) =>
  state.dashboard.getMyContracts.status === STATUS.PENDING;

export const selectCreateReportActivitySuccess = (state: RootState) => state.dashboard.createReportActivity.data;
export const selectCreateReportActivityRequest = (state: RootState) =>
  state.dashboard.createReportActivity.state === STATUS.PENDING;
export const selectCreateReportActivityFail = (state: RootState) =>
  state.dashboard.createReportActivity.state === STATUS.REJECTED;

export const selectUpdateReportActivitySuccess = (state: RootState) => state.dashboard.updateReportActivity.data;
export const selectUpdateReportActivityRequest = (state: RootState) =>
  state.dashboard.updateReportActivity.state === STATUS.PENDING;
export const selectUpdateReportActivityFail = (state: RootState) =>
  state.dashboard.updateReportActivity.state === STATUS.REJECTED;

export const selectGetReportActivitiesSuccess = (state: RootState) => state.dashboard.getReportActivities.data;
export const selectGetReportActivitiesRequest = (state: RootState) =>
  state.dashboard.getReportActivities.status === STATUS.PENDING;

export const selectMyGetReportActivitiesSuccess = (state: RootState) => state.dashboard.getMyReportActivities.data;
export const selectMyGetReportActivitiesRequest = (state: RootState) =>
  state.dashboard.getMyReportActivities.status === STATUS.PENDING;

export const selectGetReportActivitySuccess = (state: RootState) => state.dashboard.getReportActivity.data;
export const selectGetReportActivityRequest = (state: RootState) =>
  state.dashboard.getReportActivity.status === STATUS.PENDING;

export const selectDeleteReportSuccess = (state: RootState) => state.dashboard.deleteReport.data;
export const selectDeleteReportRequest = (state: RootState) => state.dashboard.deleteReport.status === STATUS.PENDING;

export const selectDeleteReportActivitySuccess = (state: RootState) => state.dashboard.deleteReportActivity.data;
export const selectDeleteReportActivityRequest = (state: RootState) =>
  state.dashboard.deleteReportActivity.status === STATUS.PENDING;

//Organization reports
export const selectGetOrganizationReportsSucces = (state: RootState) => state.dashboard.getOrganizationReports.data;
export const selectGetOrganizationReportsRequest = (state: RootState) =>
  state.dashboard.getOrganizationReports.status === STATUS.PENDING;

export const selectGetOrganizationReportSucces = (state: RootState) => state.dashboard.getOrganizationReport.data;
export const selectGetOrganizationReportRequest = (state: RootState) =>
  state.dashboard.getOrganizationReport.status === STATUS.PENDING;

export const selectUpdateOrganizationReportSucces = (state: RootState) => state.dashboard.updateOrganizationReport.data;
export const selectUpdateOrganizationReportRequest = (state: RootState) =>
  state.dashboard.updateOrganizationReport.status === STATUS.PENDING;

export const selectGetOrganizationReportActivitiesSucces = (state: RootState) =>
  state.dashboard.getOrganizationReportActivities.data;
export const selectGetOrganizationReportActivitiesRequest = (state: RootState) =>
  state.dashboard.getOrganizationReportActivities.status === STATUS.PENDING;

export const selectGetVdmSuccess = (state: RootState) => state.dashboard.getVdm.data;
export const selectGetVdmRequest = (state: RootState) => state.dashboard.getVdm.status === STATUS.PENDING;

export const selectGetBountiesRequestsDataSuccess = (state: RootState) => state.dashboard.getBountiesRequests.data;
export const selectGetBountiesRequestsDataRequest = (state: RootState) =>
  state.dashboard.getBountiesRequests.state === STATUS.PENDING;

export const selectGetBountiesHistoryRequestsDataSuccess = (state: RootState) =>
  state.dashboard.getBountiesHistoryRequests.data;
export const selectGetBountiesHistoryRequestsDataRequest = (state: RootState) =>
  state.dashboard.getBountiesHistoryRequests.state === STATUS.PENDING;

export const selectClaimBountyDataSuccess = (state: RootState) => state.dashboard.claimBounty.data;
export const selectClaimBountyDataRequest = (state: RootState) => state.dashboard.claimBounty.state === STATUS.PENDING;
export const selectClaimBountyDataFail = (state: RootState) => state.dashboard.claimBounty.state === STATUS.REJECTED;

export const selectUpdateBountyStatusSuccess = (state: RootState) =>
  state.dashboard.updateBountyStatus.status == STATUS.FULFILLED;
export const selectUpdateBountyStatusRequest = (state: RootState) =>
  state.dashboard.updateBountyStatus.status === STATUS.PENDING;
export const selectUpdateBountyStatusFail = (state: RootState) =>
  state.dashboard.updateBountyStatus.status === STATUS.REJECTED;

export const selectBountyStatusesSuccess = (state: RootState) => state.dashboard.getBountyStatuses.data;
export const selectBountyTypesSuccess = (state: RootState) => state.dashboard.getBountyTypes.data;
export const selectMembersListSuccess = (state: RootState) => state.dashboard.getMembersList.data;

export const selectGetAdminReportsDataSuccess = (state: RootState) => state.dashboard.getAdminReports.data;
export const selectGetAdminReportsRequest = (state: RootState) =>
  state.dashboard.getAdminReports.state === STATUS.PENDING;
export const selectGetAdminReportDataSuccess = (state: RootState) => state.dashboard.getAdminReport.data;
export const selectGetAdminReportRequest = (state: RootState) =>
  state.dashboard.getAdminReport.state === STATUS.PENDING;

export const selectAddReportPaymentDataSuccess = (state: RootState) => state.dashboard.addReportPayment.data;
export const selectAddReportPaymentDataRequest = (state: RootState) =>
  state.dashboard.addReportPayment.state === STATUS.PENDING;
export const selectAddReportPaymentDataFail = (state: RootState) =>
  state.dashboard.addReportPayment.state === STATUS.REJECTED;
