import { useEffect, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Messages as PrimeMessages } from "primereact/messages";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { getInviteDetails, registerInvite } from "state/actions/authActions";
import {
  selectGetInviteDetails,
  selectRegisterInviteRequest,
  selectRegisterInviteSuccess
} from "state/selectors/authSelectors";

import Button from "components/Button";
import { Checkbox, InputPassword, InputText } from "components/FormControls";
import Messages from "components/Messages/Messages";
import SectionTitle from "components/SectionTitle";

import { IInviteMember } from "types/invite";

import { schema } from "./schema";

export default function Invitation() {
  const inviteCode = useSearchParams()[0].get("code");
  const inviteDetails = useAppSelector(selectGetInviteDetails) as IInviteMember;
  const { t } = useTranslation();
  const msgs = useRef<PrimeMessages>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectRegisterInviteReq = useAppSelector(selectRegisterInviteRequest);
  const registerInviteUserSuccess = useAppSelector(selectRegisterInviteSuccess);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (inviteDetails) {
      setEmail(inviteDetails.Email);
      setFirstName(inviteDetails.FirstName);
      setLastName(inviteDetails.LastName);
    }
  }, [inviteDetails]);

  useEffect(() => {
    if (inviteCode) {
      dispatch(getInviteDetails(inviteCode));
    }
  }, []);

  useEffect(() => {
    if (registerInviteUserSuccess) {
      navigate("/login");
    }
  }, [registerInviteUserSuccess]);

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onChange"
  });

  const onSubmit = (data: Record<string, string>) => {
    msgs.current?.clear();

    if (!firstName || !lastName) {
      msgs.current?.show({
        sticky: true,
        severity: "error",
        detail: t("formValidators.allFieldsRequired")
      });
    } else {
      const passwordNotEqual = data.password !== data.confirmPassword;

      if (!passwordNotEqual) {
        const userData = {
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          Password: data.password,
          PrivacyCompliance: 1,
          InviteCode: inviteCode
        };
        dispatch(registerInvite(userData));
      } else {
        msgs.current?.show({
          sticky: true,
          severity: "error",
          detail: t("auth.passwordsDidntMatch")
        });
      }
    }
  };

  return (
    <>
      <SectionTitle>
        <div className="relative flex justify-content-center align-items-center w-full">
          {t("register.registerOnInvitation")}
        </div>
      </SectionTitle>
      <Messages msgs={msgs} />
      <div className="card justify-content-center">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full md:w-24rem flex flex-column gap-4">
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <InputText
                label={t("form.firstName")}
                {...field}
                error={fieldState.error}
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            )}
            name="firstName"
            rules={{ required: "firstName is required." }}
            defaultValue=""
          />
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <InputText
                label={t("form.lastName")}
                {...field}
                error={fieldState.error}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            )}
            name="lastName"
            rules={{ required: "lastName is required." }}
            defaultValue=""
          />
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <InputText label={t("form.email")} {...field} error={fieldState.error} readOnly value={email} />
            )}
            name="email"
            rules={{ required: "Email is required." }}
            defaultValue=""
          />

          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <InputPassword label={t("form.password")} {...field} error={fieldState.error} feedback={false} />
            )}
            name="password"
            rules={{ required: "Password is required." }}
            defaultValue=""
          />
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <InputPassword label={t("form.confirmPassword")} {...field} error={fieldState.error} feedback={false} />
            )}
            name="confirmPassword"
            rules={{ required: "Password is required." }}
            defaultValue=""
          />
          <Controller
            control={control}
            render={({ field }) => {
              return <Checkbox {...field} checked={field.value} label={t("register.privacyPolicy")} />;
            }}
            name="agreePolicy"
            defaultValue={true}
          />
          <Controller
            control={control}
            render={({ field }) => {
              return <Checkbox {...field} checked={field.value} label={t("register.agreeMemberBounty")} />;
            }}
            name="agreeMemberBounty"
            defaultValue={true}
          />

          <div className="flex flex-column justify-content-center">
            <Button label={t("form.register")} disabled={selectRegisterInviteReq} className="w-full" />
          </div>
        </form>
      </div>
    </>
  );
}
