import { api } from "utils/api";

const baseCommonUrl = `https://${process.env.REACT_APP_ENV}common.${process.env.REACT_APP_API_URL}/common`;

export const getCountries = () => api.get(`${baseCommonUrl}/countries`);
export const getCurrencies = () => api.get(`${baseCommonUrl}/currencies`);
export const getIndustryTypes = () => api.get(`${baseCommonUrl}/industrytypes`);
export const getPaymentTypes = () => api.get(`${baseCommonUrl}/paymenttypes`);
export const getRoles = () => api.get(`${baseCommonUrl}/roles`);
export const getSkills = () => api.get(`${baseCommonUrl}/skills`);
export const getEmploymentTypes = () => api.get(`${baseCommonUrl}/employmenttypes`);
export const getCollegeDegrees = () => api.get(`${baseCommonUrl}/college-degrees`);
export const getJobTypes = () => api.get(`${baseCommonUrl}/jobtypes`);
