export const saveToken = ({ token }: Record<string, string>) => {
  sessionStorage.setItem("token", token);
};

export const removeToken = () => {
  sessionStorage.removeItem("token");
};

export const getToken = () => {
  const token = sessionStorage.getItem("token");
  return token || undefined;
};
