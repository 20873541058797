import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import useCommon from "hooks/useCommon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import {
  createOrganization,
  createOrganizationInit,
  updateOrganization,
  updateOrganizationInit
} from "state/actions/organizationsActions";
import {
  selectGetOrganizationSuccess,
  selectUpdateOrganizationRequest,
  selectUpdateOrganizationSuccess
} from "state/selectors/organizationsSelectors";

import Button from "components/Button";
import Dialog from "components/Dialog";
import { InputText, InputTextarea, Select } from "components/FormControls";

import { IModal } from "types/modal";
import { IOrganization } from "types/organization";

import { schema } from "./schema";

export default function OrganizationModal({ show, closeModal }: IModal) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { industryTypes, countries } = useCommon();

  const organization = useAppSelector(selectGetOrganizationSuccess) as IOrganization;
  const updateOrganizationRequest = useAppSelector(selectUpdateOrganizationRequest) as boolean;
  const updateOrganizationSuccess = useAppSelector(selectUpdateOrganizationSuccess) as boolean;

  const { reset, handleSubmit, control } = useForm<IOrganization>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange"
  });

  useEffect(() => {
    if (organization) {
      reset({
        ...organization
      });
    } else resetForm();
  }, [organization]);

  const resetForm = () => {
    reset({
      ...organization
    });
  };

  const onSubmit: SubmitHandler<IOrganization> = (data) => {
    const organizationData = {
      Name: data.Name,
      Description: data.Description,
      IndustryType: data.IndustryType,
      CompanyType: data.CompanyType,
      CompanySize: data.CompanySize,
      Web: data.Web,
      Email: data.Email,
      Contact: data.Contact,
      IdCountry: data.IdCountry,
      IdPlace: 0
    };

    if (!organization?.Name) {
      dispatch(createOrganization(organizationData)).then(() => dispatch(createOrganizationInit()));
    } else {
      dispatch(updateOrganization(organizationData)).then(() => dispatch(updateOrganizationInit()));
    }
    handleClose();
  };

  const handleClose = () => {
    closeModal();
    resetForm();
  };

  useEffect(() => {
    if (updateOrganizationSuccess) {
      resetForm();
    }
  }, [updateOrganizationSuccess]);

  const footerContent = (
    <div>
      <Button
        onClick={handleClose}
        label={t("confirmation.cancel")}
        disabled={updateOrganizationRequest}
        type="button"
        outlined
      />
      <Button
        type="submit"
        label={t("confirmation.save")}
        disabled={updateOrganizationRequest}
        form="organization-details-form"
      />
    </div>
  );

  return (
    <Dialog
      show={show}
      closeModal={handleClose}
      title={t(organization?.Name ? "organization.editOrganizationTitle" : "organization.addNewOrganizationTitle")}
      footerContent={footerContent}
    >
      <form className="flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)} id="organization-details-form">
        <Controller
          control={control}
          render={({ field, fieldState }) => <InputText label={t("common.name")} {...field} error={fieldState.error} />}
          name="Name"
        />
        <Controller
          name="Description"
          control={control}
          render={({ field, fieldState }) => (
            <InputTextarea rows={5} {...field} label={t("common.description")} error={fieldState.error} />
          )}
        />
        <Controller
          name="IndustryType"
          render={({ field, fieldState }) => (
            <Select
              label={t("organization.industry")}
              placeholder={t("organization.industry")}
              {...field}
              options={industryTypes}
              error={fieldState.error}
            />
          )}
          control={control}
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("organization.companyType")} {...field} error={fieldState.error} />
          )}
          name="CompanyType"
        />

        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("organization.companySize")} {...field} error={fieldState.error} type="number" />
          )}
          name="CompanySize"
        />

        <Controller
          control={control}
          render={({ field, fieldState }) => <InputText label={t("common.web")} {...field} error={fieldState.error} />}
          name="Web"
        />

        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("organization.contact")} {...field} error={fieldState.error} />
          )}
          name="Contact"
        />

        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <InputText label={t("organization.email")} {...field} error={fieldState.error} type="email" />
          )}
          name="Email"
        />

        <Controller
          name="IdCountry"
          render={({ field, fieldState }) => (
            <Select
              label={t("form.selectCounty")}
              placeholder={t("form.selectCounty")}
              {...field}
              options={countries}
              error={fieldState.error}
            />
          )}
          control={control}
          defaultValue={1}
        />
      </form>
    </Dialog>
  );
}
