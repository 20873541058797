import { combineReducers } from "@reduxjs/toolkit";

import authReducers from "state/reducers/authReducers";
import commonReducers from "state/reducers/commonReducers";
import dashboardReducers from "state/reducers/dashboardReducers";
import jobsReducers from "state/reducers/jobsReducers";
import organizationsReducers from "state/reducers/organizationsReducers";
import projectsReducers from "state/reducers/projectsReducers";
import usersReducers from "state/reducers/usersReducers";

export default combineReducers({
  auth: authReducers,
  common: commonReducers,
  users: usersReducers,
  projects: projectsReducers,
  organizations: organizationsReducers,
  jobs: jobsReducers,
  dashboard: dashboardReducers
});
