import { combineReducers } from "@reduxjs/toolkit";

import {
  APPLY_TO_JOB,
  CREATE_JOB,
  GET_ALL_CONTRACTS,
  GET_ALL_CONTRACTS_CLIENT,
  GET_ALL_JOB_APPLICATIONS,
  GET_JOBS,
  GET_JOB_APPLICATIONS_CLIENT,
  GET_JOB_INFO,
  GET_SEARCH_JOBS,
  UPDATE_JOB,
  UPDATE_JOB_APPLICATIONS_CLIENT,
  CREATE_CONTRACT
} from "state/constants/jobsConstants";
import reducer from "state/utils/reducer";

export default combineReducers({
  getJobs: reducer(`jobs/${GET_JOBS}`),
  updateJob: reducer(`jobs/${UPDATE_JOB}`),
  createJob: reducer(`jobs/${CREATE_JOB}`),
  getJobInfo: reducer(`jobs/${GET_JOB_INFO}`),
  searchJobs: reducer(`jobs/${GET_SEARCH_JOBS}`),
  applyToJob: reducer(`jobs/${APPLY_TO_JOB}`),
  getAllJobApplications: reducer(`jobs/${GET_ALL_JOB_APPLICATIONS}`),
  getAllContracts: reducer(`jobs/${GET_ALL_CONTRACTS}`),
  getJobApplicationsClient: reducer(`jobs/${GET_JOB_APPLICATIONS_CLIENT}`),
  getAllContractsClient: reducer(`jobs/${GET_ALL_CONTRACTS_CLIENT}`),
  updateJobApplicationClient: reducer(`jobs/${UPDATE_JOB_APPLICATIONS_CLIENT}`),
  createContract: reducer(`jobs/${CREATE_CONTRACT}`)
});
