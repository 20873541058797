import { useState } from "react";

export default function useArray<T>(initialList: T[] = []) {
  const [value, setValue] = useState(initialList);

  const push = (element: T) => {
    setValue((oldValue) => [...oldValue, element]);
  };

  const remove = (index: number) => {
    setValue((oldValue) => oldValue.filter((_, i) => i !== index));
  };

  const isEmpty = () => value.length === 0;

  const uniqBySetWithArrayFrom = <T>(list: T[]) => {
    return Array.from(new Set(list));
  };

  return { value, setValue, push, remove, isEmpty, uniqBySetWithArrayFrom };
}
