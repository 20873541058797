import Button from "components/Button";

import { getDayMonthYear } from "../../utils/formatDate";

export const taskActionsBodyTemplate = (
  { item, setShowEditModal, setTaskToEdit, setShowHoursModal, deleteTask }: any,
  showHours = true
) => {
  return (
    <div>
      <Button
        icon="pi pi-file-edit"
        rounded
        text
        className="h-2rem w-2rem text-900 mr-1"
        tooltip="Edit task"
        tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
        type="button"
        onClick={() => {
          setShowEditModal(true);
          setTaskToEdit(item);
        }}
      />
      <Button
        icon="pi pi-trash"
        rounded
        text
        className="h-2rem w-2rem text-900 mr-1"
        tooltip="Delete task"
        tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
        type="button"
        onClick={() => {
          deleteTask(item.Id);
        }}
      />
      {showHours && (
        <Button
          icon="pi pi-calendar-plus"
          rounded
          text
          className="h-2rem w-2rem text-900 mr-1"
          tooltip="Add hours manualy"
          tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }}
          type="button"
          onClick={() => {
            setShowHoursModal(true);
            setTaskToEdit(item);
          }}
        />
      )}
    </div>
  );
};

export const dateBodyTemplate = (startDate: Date) => {
  if (startDate) {
    return getDayMonthYear(startDate);
  } else return "n/a";
};
