// 07/06/2023
export const formatDate = (date: string) => {
  const fDate = new Date(Date.parse(date));
  return `${fDate.getDate() < 10 ? "0" + fDate.getDate() : fDate.getDate()}/${
    fDate.getMonth() + 1 < 10 ? "0" + (fDate.getMonth() + 1) : fDate.getMonth() + 1
  }/${fDate.getFullYear()}`;
};

// 2023-06-07
export const formatDate2 = (date: string) => {
  const fDate = new Date(Date.parse(date));
  return `${fDate.getFullYear()}-${fDate.getMonth() + 1 < 10 ? "0" + (fDate.getMonth() + 1) : fDate.getMonth() + 1}-${
    fDate.getDate() < 10 ? "0" + fDate.getDate() : fDate.getDate()
  }`;
};

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const weekDaysOneLetter = ["S", "M", "T", "W", "T", "F", "S"];
const weekDaysFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
const monthsFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

// 1 Jan
export const getDateMonth = (date: string) => {
  const timestamp = Date.parse(date);
  const newDate = new Date(timestamp);
  return `${newDate.getDate()} ${months[newDate.getMonth()]}`;
};

// 1 Jan 23
export const getDateMonthYear = (date: string) => {
  const timestamp = Date.parse(date);
  const newDate = new Date(timestamp);
  const day = newDate.getDate();
  const month = months[newDate.getMonth()];
  const year = newDate.getFullYear().toString().slice(-2);
  return `${day} ${month} ${year}`;
};

// 28/05/2023
export const getDayMonthYear = (date: string | Date) => {
  const newDate = typeof date === "string" ? new Date(Date.parse(date)) : new Date(date);
  // Get the year, month, and day from the Date object
  const year = newDate.getFullYear();
  // Month value is zero-based, so we add 1 to get the actual month
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");
  return `${day}/${month}/${year}`;
};

// 07.06.2023 11:08
export const getDayMonthYearAndTime = (date: string | Date) => {
  const newDate = typeof date === "string" ? new Date(Date.parse(date)) : new Date(date);
  // Get the year, month, and day from the Date object
  const year = newDate.getFullYear();
  // Month value is zero-based, so we add 1 to get the actual month
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");
  const hours = newDate.getHours().toString().length === 1 ? `0${newDate.getHours()}` : newDate.getHours();
  const minutes = newDate.getMinutes().toString().length === 1 ? `0${newDate.getMinutes()}` : newDate.getMinutes();
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

// 07.06.2023_11_08_06
export const getDateForExport = () => {
  const newDate = new Date();
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");
  const hours = newDate.getHours().toString().length === 1 ? `0${newDate.getHours()}` : newDate.getHours();
  const minutes = newDate.getMinutes().toString().length === 1 ? `0${newDate.getMinutes()}` : newDate.getMinutes();
  const seconds = newDate.getSeconds().toString().length === 1 ? `0${newDate.getSeconds()}` : newDate.getSeconds();
  return `${day}.${month}.${year}_${hours}_${minutes}_${seconds}`;
};

export const startDateOfCurrentMonth = () => {
  return new Date().toISOString().slice(0, 8) + "01";
};

export const endDateOfCurrentMonth = () => {
  const date = new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0).getDate();
  return new Date().toISOString().slice(0, 8) + date;
};

export const startDateOfNextMonth = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const startDate = new Date(currentYear, currentMonth + 2, 1);
  return startDate.toISOString().substring(0, 10).slice(0, 8) + "01";
};

export const startDateOfPreviousMonth = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const startDate = new Date(currentYear, currentMonth - 1, 1);
  return startDate.toISOString().substring(0, 10);
};
