import { ReactNode } from "react";

import "./Hero.css";

interface HeroProps {
  children: ReactNode;
  className?: string;
}

export default function Hero({ children, className = "" }: HeroProps) {
  return <div className={`h-18rem bg-hero-gradient ${className}`}>{children}</div>;
}
