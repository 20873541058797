import { combineReducers } from "@reduxjs/toolkit";

import { CREATE_ORGANIZATION, GET_ORGANIZATION, UPDATE_ORGANIZATION } from "state/constants/organizationsConstants";
import reducer from "state/utils/reducer";

export default combineReducers({
  getOrganization: reducer(`organizations/${GET_ORGANIZATION}`),
  createOrganization: reducer(`organizations/${CREATE_ORGANIZATION}`),
  updateOrganization: reducer(`organizations/${UPDATE_ORGANIZATION}`)
});
