import { forwardRef } from "react";

import { Checkbox as PrimeCheckbox, CheckboxProps as PrimeCheckboxProps } from "primereact/checkbox";

interface CheckboxProps extends PrimeCheckboxProps {
  label?: string;
}

const Checkbox = forwardRef<PrimeCheckbox, CheckboxProps>((props, ref) => (
  <div className="flex align-items-center">
    <PrimeCheckbox {...props} inputId={props.name} ref={ref} />
    <label htmlFor={props.name} className="ml-2">
      {props.label}
    </label>
  </div>
));

Checkbox.displayName = "Checkbox";

export default Checkbox;
