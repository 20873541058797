import { confirmDialog } from "primereact/confirmdialog";

import useCommon from "hooks/useCommon";
import { useAppDispatch } from "hooks/useRedux";
import useTranslation from "hooks/useTranslation";

import { getDayMonthYear } from "utils/formatDate";

import { updateBountyStatus, updateBountyStatusInit } from "state/actions/dashboardActions";

import Button from "components/Button";
import { Column, DataTable } from "components/DataTable";
import Loading from "components/Loading";

import { IBounty } from "types/bounty";
import { IColumn } from "types/column";

export default function BountiesTable({ bounties, bountiesRequests }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { bountyStatuses } = useCommon();

  const filteredBounties = bounties?.mybounties?.map((bountyItem: any) => ({
    Id: bountyItem.Id,
    Name: bountyItem.Name,
    CreatorFullName: bountyItem.CreatorFullName,
    StartDate: getDayMonthYear(bountyItem.StartDate),
    Status: bountyStatuses.find((s) => s.value.toString() === bountyItem.Status.toString())?.label,
    Created: getDayMonthYear(bountyItem.Created),
    Updated: getDayMonthYear(bountyItem.Updated)
  }));

  const headers: IColumn[] = [
    { field: "Name", header: t("common.name"), sortable: true },
    { field: "CreatorFullName", header: t("mybounties.person"), sortable: true },
    { field: "StartDate", header: t("common.startDate"), sortable: true },
    { field: "Status", header: t("common.status"), sortable: true },
    { field: "Created", header: t("common.created"), sortable: true },
    { field: "Updated", header: t("common.updated"), sortable: true }
  ];

  const handleAcceptBounty = (bounty: IBounty) => {
    const bountyToAccept = {
      Id: parseInt(bounty.Id as unknown as string),
      Status: 2
    };

    dispatch(updateBountyStatus(bountyToAccept)).then(() => dispatch(updateBountyStatusInit()));
  };

  const acceptBountyConfirmDialog = (bounty: IBounty) => {
    confirmDialog({
      header: t("confirmation.areYouSure"),
      message: `${t("bounty.acceptBounty")
        .replace("[name]", bounty.Name.toLowerCase())
        .replace("[user]", bounty.CreatorFullName)}`,
      draggable: false,
      accept: () => handleAcceptBounty(bounty)
    });
  };

  const handleDeclineBounty = (bounty: IBounty) => {
    const bountyToAccept = {
      Id: parseInt(bounty.Id as unknown as string),
      Status: 3
    };

    dispatch(updateBountyStatus(bountyToAccept)).then(() => dispatch(updateBountyStatusInit()));
  };

  const declineBountyConfirmDialog = (bounty: IBounty) => {
    confirmDialog({
      header: t("confirmation.areYouSure"),
      message: `${t("bounty.declineBounty")
        .replace("[name]", bounty.Name.toLowerCase())
        .replace("[user]", bounty.CreatorFullName)}`,
      draggable: false,
      accept: () => handleDeclineBounty(bounty)
    });
  };

  const viewBountyBodyTemplate = (rowData: IBounty) => {
    return (
      <div className="w-full">
        {bountyStatuses.find((s) => s.label.toString() === rowData.Status.toString())?.value === 1 && (
          <div className="flex w-full">
            <Button
              label={t("common.accept")}
              type="button"
              icon="pi pi-check"
              className="mr-2 w-full"
              onClick={() => {
                acceptBountyConfirmDialog(rowData);
              }}
            />
            <Button
              label={t("common.decline")}
              severity="danger"
              type="button"
              icon="pi pi-times"
              className="ml-2 w-full"
              onClick={() => {
                declineBountyConfirmDialog(rowData);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  if (bountiesRequests) {
    return <Loading />;
  }

  return (
    <DataTable value={filteredBounties}>
      {headers.map((col) => (
        <Column key={col.field} {...col} />
      ))}
      <Column body={viewBountyBodyTemplate} />
    </DataTable>
  );
}
